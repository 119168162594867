import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  DEFAULT_TAX,
  DEFAULT_TAX_VALUE,
  DEFAULT_UNIT_TAX,
} from '../../../../../constants/defaultValues';
import { SettingService } from '../../../../../services/SettingService';
import Button from '../../../../atoms/buttons/Button';
import Input from '../../../../atoms/inputs/Input';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import Span from '../../../../atoms/spans/Span';
import TextArea from '../../../../atoms/textareas/TextArea';
import RequestHandelingAlert from '../../../../organisms/alerts/RequestHandelingAlert';
import SuccessModal from '../../../feedback/modal/SuccessModal';
import { GlobalsHelpers } from '../../../helper/globals';
import { buildOptionsFromCollection } from '../../../util/api/adapterHelper';
import { ItemsLayoutHelper } from '../helper';

const EditItensLayout = (props) => {
  const history = useHistory();
  const urlParams = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    unit_price: 0,
    rrp: 0,
    retention: 0,
    tax_value: { key: DEFAULT_TAX, value: DEFAULT_TAX_VALUE },
    unit: DEFAULT_UNIT_TAX,
    description: '',
  });
  const [errors, setRequestErrors] = useState('');
  const [taxList, setTaxList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  /**
   * Handle fields state
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  const handleFormFields = async (key, value) => {
    const updatedForm = {
      ...formFields,
      [key]: value,
    };
    setFormFields(updatedForm);
  };

  const handleUnitPrice = async (key, value) => {
    const updatedForm = {
      ...formFields,
      [key]: value,
      rrp: ItemsLayoutHelper.calculatePVP(value, formFields?.tax_value.value),
    };
    setFormFields(updatedForm);
  };

  const handleUnitRRP = async (key, value) => {
    const updatedForm = {
      ...formFields,
      [key]: value,
      unit_price: ItemsLayoutHelper.calculateUnitPrice(
        value,
        formFields?.tax_value.value
      ),
    };
    setFormFields(updatedForm);
  };

  const handleTax = async (key, value) => {
    const parsedValue = JSON.parse(value);
    const updatedForm = {
      ...formFields,
      [key]: parsedValue,
      rrp: ItemsLayoutHelper.calculatePVP(
        formFields?.unit_price,
        parsedValue.value
      ),
    };
    setFormFields(updatedForm);
  };

  const isRequiredFieldsFilled = () => {
    return formFields.code && formFields.unit_price;
  };

  const handleSubmit = async () => {
    if (isRequiredFieldsFilled()) {
      setIsLoading(true);
      setIsSubmitted(true);

      const { tax_value, ...form } = formFields;

      const data = {
        ...form,
        unit_price: Number(formFields.unit_price),
        tax: {
          name: tax_value.key,
          value: tax_value.value,
        },
      };
      const response = urlParams?.id
        ? await SettingService.updateItem({
            ...data,
            id: urlParams.id,
          })
        : await SettingService.createItem(data);

      if (!response.error) {
        setIsLoading(false);
        return;
      }
      setIsSubmitted(false);
      setRequestErrors(response.error);
    }
  };

  useEffect(() => {
    /**
     * Fetch tax settings
     */
    const fetchTax = async () => {
      const response = await SettingService.getTaxList();
      if (!response.error) {
        setTaxList(buildOptionsFromCollection('name', 'value', response.taxes));
      }
    };

    /**
     * Fetch item
     */
    const fetchItem = async () => {
      const response = await SettingService.getItemById(urlParams.id);
      if (!response.error) {
        setFormFields({
          ...response.item,
          tax_value: {
            key: response.item.tax.name,
            value: parseInt(response.item.tax.value),
          },
        });
      }
    };

    if (urlParams?.id) {
      fetchItem();
    }

    fetchTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='main-content' className='container --billing-alerts'>
      <div className='row justify-content-center'>
        <div className='col-11 col-lg-10'>
          <div className='container'>
            <div className='row content-block advanced-search-block'>
              <div className='row title-block'>
                <div className='col-12'>
                  <Paragraph className='text-header h2'>
                    {props.intl.messages[`itemsTitle${props.documentsTab}`]}
                  </Paragraph>

                  <Paragraph className='text-paragraph mt-2 mb-2'>
                    <Span className='d-block'></Span>
                  </Paragraph>

                  {errors.length > 0 && (
                    <RequestHandelingAlert error={errors} />
                  )}
                </div>
              </div>
            </div>

            <div className='form-container'>
              <div className='row'>
                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['code']}*
                  </label>
                  <Input
                    id='code'
                    type='text'
                    value={formFields?.code || ''}
                    onChange={(e) => handleFormFields('code', e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['itemsUnitPrice']}*
                  </label>
                  <Input
                    id='unit_price'
                    type='number'
                    minValue='0'
                    value={String(formFields?.unit_price)}
                    onChange={(e) =>
                      handleUnitPrice('unit_price', e.target.value)
                    }
                  />
                </div>

                <div className='col-12'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['description']}
                  </label>

                  <TextArea
                    id='description'
                    limit={'200'}
                    className='--medium'
                    value={formFields?.description || ''}
                    placeholder='observationsPlaceholder'
                    onChange={(e) =>
                      handleFormFields('description', e.target.value)
                    }
                  />
                </div>

                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['itemsUnit']}
                  </label>
                  <SelectWithValues
                    key='unit'
                    id='unit'
                    value={formFields?.unit}
                    options={GlobalsHelpers.getItemsUnitsList(props.intl)}
                    onChange={(e) => {
                      handleFormFields('unit', e.target.value);
                    }}
                  />
                </div>

                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['itemsTaxTitle']}
                  </label>
                  <SelectWithValues
                    key='iva'
                    id='iva'
                    value={JSON.stringify(formFields?.tax_value)}
                    options={taxList}
                    isObject
                    onChange={(e) => {
                      handleTax('tax_value', e.target.value);
                    }}
                  />
                </div>

                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['itemsPVP']}
                  </label>
                  <Input
                    id='rrp'
                    type='number'
                    value={String(formFields?.rrp)}
                    onChange={(e) => handleUnitRRP('rrp', e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <label className='text-label mt-3'>
                    {props.intl.messages['itemsRetention']} (%)
                  </label>
                  <Input
                    id='retention'
                    type='number'
                    value={String(formFields?.retention)}
                    onChange={(e) =>
                      handleFormFields('retention', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className='row justify-content-end'>
                <div className='col-lg-3 col-xs-12 mt-4 text-align-center'>
                  <Button
                    id='goBackButton'
                    className='button button-outline d-block'
                    label={'goBack'}
                    onClick={() => history.goBack()}
                  />
                </div>

                <div className='col-lg-3 col-xs-12 mt-4'>
                  <Button
                    id='confirmButton'
                    className='button button-primary d-block'
                    label={'save'}
                    onClick={handleSubmit}
                    disabled={!isRequiredFieldsFilled()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isSubmitted && (
          <SuccessModal
            isLoading={isLoading}
            messageKey={'contactUpdated'}
            onClose={() => history.goBack()}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(EditItensLayout);
