/**
 * Functions that receives a collections and transforms it
 * into a collection of [{key: key, value: value}]
 *
 * @param {string} key - name of the key of the objects in the collection
 * @param {*} value - name of the value of the objects in the collection
 * @param {*} collection - collection to be transformed
 * @returns - transformed collection to be suited to use in options
 */
export const buildOptionsFromCollection = (
  key,
  value,
  collection,
  extra_param
) => {
  if (collection.length === 0) {
    return [];
  }

  if (extra_param) {
    return collection.map((elem) => {
      let obj = {};

      obj['key'] = elem[key];
      obj['value'] = elem[value];
      obj['code'] = elem[extra_param];

      return obj;
    });
  }

  return collection.map((elem) => {
    let obj = {};

    obj['key'] = elem[key];
    obj['value'] = elem[value];

    return obj;
  });
};
