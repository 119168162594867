import React from 'react';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';

const Head = (props) => {
  const { name, result, reference, showBody } = props;
  return (
    <div
      data-testid='dropdown_header'
      className='header'
      ref={reference}
      onClick={showBody}
    >
      <Span className='d-block name' label={name}>
        {props.children}
      </Span>
      {result && <Span className='d-block result' label={result} />}
    </div>
  );
};

Head.propTypes = {
  name: PropTypes.string.isRequired,
  reference: PropTypes.object.isRequired,
  showBody: PropTypes.func,
};

export default Head;
