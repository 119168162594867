import React, { useContext, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import Button from '../../../atoms/buttons/Button';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import { buildChildren } from '../util/buttonsHelper';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import {
  CONTACT_NEW_DOCUMENT_PATHS,
  CREATE_CONTACT_PATHS,
  CREATE_REPORTS_PATHS,
  CONTACT_PATH,
} from '../../../../constants/index';
import { buildPath } from '../util/pathHelper';
import PaymentRefundModal from '../../modals/contacts/PaymentRefundModal';
import InitialBalanceModal from '../../modals/contacts/InitialBalanceModal';
import SendByEmailModal from '../../modals/email/GlobalSendEmailModal';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { AppContext } from '../../../../contexts/AppContext';
import UpgradePlanModal from '../../../templates/feedback/modal/UpgradePlanModal';

const RightMenu = (props) => {
  const { appState } = useContext(AppContext);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const [isInitialBalanceModalVisible, setIsInitialBalanceModalVisible] =
    useState(false);
  const [isRegularizeBalanceModalVisible, setIsRegularizeBalanceModalVisible] =
    useState(false);
  const [isSendByEmailModalVisible, setIsSendByEmailModalVisible] =
    useState(false);
  /**
   * TODO: this function should be inside the Informative Dropdown Component
   * All RightMenus will need to me changed too
   */
  const addHelpInfo = (paths) => {
    return Object.keys(paths).map((pathKey) => (
      <div key={pathKey}>
        {props.isEditMode && (
          <Paragraph
            id={paths[pathKey]}
            key={paths[pathKey]}
            className='bold color-gray-700 m-0'
          >
            {props.intl.messages[paths[pathKey]]}
          </Paragraph>
        )}
        <FormattedMessage
          id={pathKey}
          key={pathKey}
          values={{
            a: (chunks) => (
              <Anchor
                className='d-block bold color-brand-primary'
                href={buildPath(paths[pathKey])}
                key={pathKey}
                pathKey={pathKey}
              >
                {chunks}
              </Anchor>
            ),
          }}
        />
      </div>
    ));
  };

  const infoMobileDisplay = openMobileSideContent(
    'mobileSummary',
    props.mobileSideContent
  );

  const buttonsMobileDisplay = openMobileSideContent(
    'mobileActions',
    props.mobileSideContent
  );

  const createDocumentsMobileDisplay = openMobileSideContent(
    'mobileButtons',
    props.mobileSideContent
  );

  if (
    infoMobileDisplay === 'open' ||
    buttonsMobileDisplay === 'open' ||
    createDocumentsMobileDisplay === 'open'
  ) {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='container px-0'>
      {!props.isEditMode && (
        <>
          <div
            className={`buttons-block mobile-side-content p-0 pt-2 pt-lg-0 ${buttonsMobileDisplay}`}
          >
            <div className='row mx-0 content-block multiple-buttons-block text-align-center mb-0'>
              {false && props.isListMode && (
                <Anchor
                  id={`${props.id}_newContact`}
                  className='button button-primary d-block px-0'
                  href={buildPath(CONTACT_PATH(props.clientId).NEW_CONTACT)}
                >
                  {'createContact'}
                </Anchor>
              )}
              {!props.isListMode && (
                <>
                  <Anchor
                    className='button button-primary d-block'
                    href={buildPath(CONTACT_PATH(props.clientId).EDIT_CONTACT)}
                  >
                    {'editContact'}
                  </Anchor>
                  <Button
                    id='sendOpenAccount'
                    className='button button-secondary d-block'
                    label='sendOpenAccount'
                    onClick={() =>
                      setIsSendByEmailModalVisible((prevState) => !prevState)
                    }
                  />
                  <Button
                    id='registerInitialBalance'
                    className='button button-secondary d-block'
                    label='setInitialBalance'
                    onClick={() =>
                      setIsInitialBalanceModalVisible((prevState) => !prevState)
                    }
                  />
                  <Button
                    id='regularizeMigratedBalance'
                    className='button button-secondary d-block'
                    label='regularizeBalance'
                    onClick={() =>
                      setIsRegularizeBalanceModalVisible(
                        (prevState) => !prevState
                      )
                    }
                  />
                  <Button
                    id='registerPayment'
                    className='button button-secondary d-block'
                    label='registerPayment'
                    onClick={() =>
                      setIsPaymentModalVisible((prevState) => !prevState)
                    }
                  />
                  <Button
                    id='registerRefund'
                    className='button button-secondary d-block'
                    label='registerRefund'
                    onClick={() =>
                      setIsRefundModalVisible((prevState) => !prevState)
                    }
                  />
                  <Button
                    id='deleteContact'
                    className='button button-plain-text d-block'
                    label='deleteContact'
                    onClick={props.handleDelete}
                  />
                </>
              )}
            </div>
          </div>
          {!props.isListMode && (
            <div
              className={`row mx-0 p-0 content-block dropdown-button-block mobile-side-content ${createDocumentsMobileDisplay}`}
            >
              <ButtonDropdown
                id={`${props.id}_otherDocuments`}
                label='createOtherDocuments'
                dropdownType='button-secondary'
              >
                {buildChildren(CONTACT_NEW_DOCUMENT_PATHS(props.clientId))}
              </ButtonDropdown>
            </div>
          )}
        </>
      )}
      <div
        className={`row mx-0 p-0 content-block information-block mobile-side-content ${infoMobileDisplay}`}
      >
        <InformativeDropdown
          id={`${props.id}_informativeDropdown`}
          label={props.isEditMode ? 'help' : 'reports'}
          dropdownType='help-info-panel mt-0'
          iconClassName={`icon fas ${
            props.isEditMode ? 'fa-info-circle' : 'fa-folder'
          }`}
          isVisible={props.open}
        >
          {addHelpInfo(
            props.isEditMode
              ? CREATE_CONTACT_PATHS
              : CREATE_REPORTS_PATHS(props.clientId)
          )}
        </InformativeDropdown>
      </div>

      {!props.isEditMode && !props.isListMode && (
        <>
          {isPaymentModalVisible && (
            <PaymentRefundModal
              userEmail={appState.userEmail}
              clientId={props.clientId}
              title='registerPayment'
              accountSettings={props.accountSettings}
              createDocumentLabel={'createReceipt'}
              changeModalVisibility={() => setIsPaymentModalVisible(false)}
              refresh={props.refreshBalance}
            />
          )}
          {isRefundModalVisible && (
            <PaymentRefundModal
              userEmail={appState.userEmail}
              clientId={props.clientId}
              title='registerRefund'
              accountSettings={props.accountSettings}
              createDocumentLabel='createCreditNote'
              changeModalVisibility={() => setIsRefundModalVisible(false)}
              refresh={props.refreshBalance}
            />
          )}
          {isInitialBalanceModalVisible && (
            <InitialBalanceModal
              name='setInitialBalance'
              clientId={props.clientId}
              accountSettings={props.accountSettings}
              changeModalVisibility={() =>
                setIsInitialBalanceModalVisible((prevState) => !prevState)
              }
              refresh={props.refreshBalance}
            />
          )}
          {isRegularizeBalanceModalVisible && (
            <InitialBalanceModal
              name='regularizeBalance'
              clientId={props.clientId}
              accountSettings={props.accountSettings}
              changeModalVisibility={() =>
                setIsRegularizeBalanceModalVisible((prevState) => !prevState)
              }
              refresh={props.refreshBalance}
              showBalance={props.remainingBalance}
              refreshRegularization={props.refreshRegularization}
            />
          )}
          {appState.accountTrial === 'false' && isSendByEmailModalVisible && (
            <SendByEmailModal
              title='sendOpenAccount'
              description='sendOpenAccountDescription'
              accountSettings={props.accountSettings}
              changeModalVisibility={() =>
                setIsSendByEmailModalVisible((prevState) => !prevState)
              }
              userEmail={appState.userEmail}
              companyName={appState.companyName}
              contactName={props.contactName}
              contactEmail={props.contactEmail}
              contactFiscalID={props.contactFiscalID}
              contactPrefName={props.contactPrefName}
              contactPrefEmail={props.contactPrefEmail}
              contactPrefPhone={props.contactPrefPhone}
              openAccountLink={props.openAccountLink}
              clientId={props.clientId}
              refresh={props.refreshClient}
            />
          )}
        </>
      )}

      {appState.accountTrial === 'true' && isSendByEmailModalVisible && (
        <UpgradePlanModal />
      )}
    </div>
  );
};

export default injectIntl(RightMenu);
