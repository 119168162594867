import React from 'react';
import Button from '../../../atoms/buttons/Button';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { useHistory } from 'react-router-dom';
import Image from '../../../atoms/images/Image';
import Span from '../../../atoms/spans/Span';
import { MANAGE_PLAN_PATH } from '../../../../constants';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import Anchor from '../../../atoms/anchors/Anchor';
import { FormattedMessage, injectIntl } from 'react-intl';

const NotAllowedLayout = (props) => {
  const { title, description, message } = props;
  const history = useHistory();
  return (
    <div id='main-content' className='d-flex container --billing-alerts'>
      <div className='d-flex container px-0'>
        <div className='d-flex flex-column justify-content-center col-12 col-md-6 px-0 mx-auto'>
          <Paragraph
            id={title}
            className='text-header h2 text-align-center mb-5'
          >
            {props.intl.messages[title]}
          </Paragraph>
          <div className='d-flex w-100'>
            <Image
              className='col-12 col-md-8 px-0 mx-auto'
              src={
                'https://invoicexpress-assets.s3.eu-west-1.amazonaws.com/billing-alerts-update-plan.png'
              }
            />
          </div>
          <Span
            className='color-gray-500 text-align-center mt-5'
            style={{ fontSize: '18px' }}
          >
            <FormattedMessage
              id={description}
              values={{
                strong: (chunks) => <Span className='bold'>{chunks}</Span>,
              }}
            ></FormattedMessage>
          </Span>
          {message && (
            <Span
              className='color-gray-500 w-100 text-align-center mt-4'
              style={{ fontSize: '18px' }}
            >
              {props.intl.messages[message]}
            </Span>
          )}
          <div className='d-flex flex-row flex-wrap justify-content-center mt-5'>
            <div className='col-12 col-md-4 text-align-center me-md-3'>
              <Button
                id='not-allowed-go-back'
                className='button button-outline d-block'
                label={props.intl.messages['goBack']}
                onClick={() => history.goBack()}
              />
            </div>
            <div className='row mx-0 col-12 col-md-4 text-align-center mt-4 mt-md-0 ms-md-3'>
              <Anchor
                className='button button-primary d-block'
                href={buildPath(MANAGE_PLAN_PATH)}
              >
                {props.intl.messages['upgradeButton']}
              </Anchor>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(NotAllowedLayout);
