import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../atoms/buttons/Button';
import Input from '../../../atoms/inputs/Input';

import { ReactComponent as IXLogo } from '../../../../assets/imgs/ix-logo-horiz.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Anchor from '../../../atoms/anchors/Anchor';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import { REGISTER_PATH } from '../../../../constants';

import { validateEmailFormat } from '../../../../helpers/validateFormatHelper';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import StorageService from '../../../../services/StorageService';
import Alert from '../../../molecules/alerts/Alert';
import { STORAGE_KEYS } from '../../../../constants/storage';
import InputPassword from '../../../molecules/inputs/InputPassword';

const LoginLayout = ({ form, setForm, logged, intl }) => {
  const [hasError, setHasError] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleInput = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * Verify if login is valid
   * @param {string} login
   */
  const verifyEmail = (login) => {
    setIsEmailInvalid(!validateEmailFormat(login));
  };

  const isRequiredFieldsFilled = () => {
    return form.login !== '' && !isEmailInvalid;
  };

  const onSubmit = async () => {
    if (isRequiredFieldsFilled()) {
      const response = await AuthenticationService.login(form);

      if (response.success) {
        const Storage = new StorageService();
        Storage.setItem(
          STORAGE_KEYS.TOKEN,
          JSON.stringify({
            token: response.token,
            redirectTo: response.redirect,
            _a: AuthenticationService.encryptWithAES(form.login), // email
            _b: AuthenticationService.encryptWithAES(form.password), // password
          })
        );
        logged();
        return;
      }

      setHasError(true);
    }
  };

  // TODO: Remove this in the future - Login by React
  useEffect(() => {
    const isLocalhost = window.location.host.includes('localhost');

    if (!isLocalhost) {
      const path = window.location.hostname.split('.');

      if (path.length < 4) {
        window.location.href = `https://www.${window.location.host}/login`;
      }
    }
  }, []);

  useEffect(() => {
    async function submitInfo() {
      if (enterPressed) {
        await onSubmit();

        setEnterPressed(false);
      }
    }
    submitInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed]);

  useEffect(() => {
    const listener = async (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        setEnterPressed(true);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='row --login-wrapper'>
      <div className='col-lg-6 col-xs-12 --login-wrapper-image'>
        <div className='row'>
          <div className='col-12 m-2'>
            <IXLogo className='--logo' />
            <Paragraph className='text-header h2 mt-2'>
              <FormattedMessage
                id='loginDescription'
                values={{
                  r: (...chunks) => (
                    <Span className='font-regular'>
                      {chunks}
                      <br />
                    </Span>
                  ),
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </Paragraph>
          </div>

          <div className='col-12 mt-4 --login-wrapper-links'>
            <Anchor href={buildPath(REGISTER_PATH().CREATE_ACCOUNT)}>
              {intl.messages['createAccountLink']} |
            </Anchor>
            <Anchor href={buildPath(REGISTER_PATH().FORGOT_PASSWORD)}>
              {' '}
              {intl.messages['forgotPasswordLink']}
            </Anchor>
          </div>
        </div>
      </div>

      <div className='col-lg-6 col-xs-12 --login-form'>
        <div className='column form-container'>
          <div className='col-10 mt-4'>
            <Paragraph className='text-header h2 mb-2'>
              {intl.messages['loginTitle']}
            </Paragraph>
            {hasError && (
              <Alert
                id='request_alert_error'
                alertType={`alert-error --icon`}
                iconClassName='fas fa-circle-exclamation'
              >
                {intl.messages['loginError']}
              </Alert>
            )}
            <label className='text-label mt-3'>{intl.messages['email']}:</label>
            <Input
              id='email_input'
              type='text'
              value={form.login}
              onChange={(e) => handleInput('login', e.target.value)}
              onBlur={(e) => verifyEmail(e.target.value)}
              hasError={isEmailInvalid}
              placeholder='exemplo@login.com'
            />
          </div>
          <div className='col-10 mt-4'>
            <label className='text-label'>{intl.messages['password']}:</label>
            <InputPassword
              id='password_input'
              type='password'
              value={form.password}
              onChange={(e) => handleInput('password', e.target.value)}
              placeholder='*********'
              className='input-icon --right'
              inputClassName='d-block'
            />
          </div>
          <div className='col-10 mt-4'>
            <Button
              id='submit'
              className='button button-primary d-block'
              label='loginTitle'
              onClick={onSubmit}
            />
          </div>

          <div className='col-8 mt-4 mb-4 text-align-center'>
            <Paragraph className='text-header h4'>
              {intl.messages['doesntHaveAnAccountTitle']}
            </Paragraph>

            <Paragraph className='text-paragraph'>
              {intl.messages['doesntHaveAnAccountSubtitle']}
            </Paragraph>

            <Anchor
              className='button bg-orange-100 color-ix-white'
              href={buildPath(REGISTER_PATH().CREATE_ACCOUNT)}
            >
              {intl.messages['createAccountTitle']}
            </Anchor>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(LoginLayout);
