import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowUser = (props) => {
  const addUserEmail = (document) => {
    return (
      <div className='col-8 px-0'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['email']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description text-break pe-4'
        >
          {document.email}
        </div>
      </div>
    );
  };

  const addUserRole = (document) => {
    return (
      <div className='col-4 px-0'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['profile']}:`}
        />
        <div className='text-description text-break pe-4'>
          {props.intl.messages[document.role]}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        data-testid={`row-user-${props.id}`}
        className={`d-flex w-100 card --simple --manage-user-cell align-items-center ${props.className} py-4 ${props.disabled ? 'disabled' : ''}`}
        onClick={(e) => {
          !props.disabled && props.setSelectedLines(props.document.id);
          e.preventDefault();
        }}
      >
        <div className='ps-4 pe-5'>
          <CheckBoxLabel
            className='checkbox'
            checked={props.selectedLines.indexOf(props.document.id) !== -1}
            onChange={() =>
              !props.disabled && props.setSelectedLines(props.document.id)
            }
            disabled={props.disabled}
          />
        </div>
        <div className='d-flex flex-grow-1 align-items-center'>
          {addUserEmail(props.document)}
          {addUserRole(props.document)}
        </div>
      </div>
    </>
  );
};

RowUser.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
};

export default injectIntl(RowUser);
