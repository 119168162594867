import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '../../../../atoms/icons/Icon';
import Span from '../../../../atoms/spans/Span';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../../atoms/buttons/Button';
import Anchor from '../../../../atoms/anchors/Anchor';

const RandomInputField = ({
  field,
  handleField,
  initCredentials,
  defaultValue,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [uuid, setId] = useState('');

  useEffect(() => {
    const handleLoadingTimer = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    return () => {
      clearTimeout(handleLoadingTimer);
    };
  }, [isCopied]);

  const generateRandomId = (initCredential = false) => {
    const random = uuidv4();

    setId(random);
    initCredential
      ? initCredentials(field.key, random)
      : handleField(field.key, random);
  };

  useEffect(() => {
    generateRandomId(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      key={field.key}
      className='--credentials-copy-url col-md-6 col-sm-12 mt-3 mb-3 px-0'
    >
      <label className='text-label'>{field.field}*</label>
      <div className='copy-random-number'>
        <div className='col-6'>
          <Span className='copy-url--input-text'>{defaultValue || uuid}</Span>
        </div>
        <div className='copy-url--input-action'>
          <Span className='divider' />
          <Anchor
            id={`copy-random_${field.key}`}
            onClick={() => {
              navigator.clipboard.writeText(uuid);
              setIsCopied(true);
            }}
          >
            <Icon className='fa fa-copy' />
          </Anchor>
          <Button
            id={`generate-random_${field.key}`}
            className='button button-primary'
            onClick={() => generateRandomId()}
          >
            <Icon className={'icon fa fa-sync-alt'} />
          </Button>
        </div>
      </div>
      {isCopied && (
        <Span className='text-paragraph bold --small'>
          <FormattedMessage id='textCopied' />
        </Span>
      )}
    </div>
  );
};

export default injectIntl(RandomInputField);
