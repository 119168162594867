import { injectIntl } from 'react-intl';
import React, { useState } from 'react';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import FeatureNotAvailableBanner from '../../../molecules/banners/FeatureNotAvailableBanner';
import { isXSPlan } from '../../../templates/helper/planHelper';
import Input from '../../../atoms/inputs/Input';
import { isLogoValid } from '../util/imageHelper';
import Alert from '../../../molecules/alerts/Alert';
import Button from '../../../atoms/buttons/Button';

const Logo = (props) => {
  const [error, setError] = useState(false);

  const onChooseFile = (event) => {
    const file = event.target.files[0];

    // Size in bytes
    const size = file.size;

    // File type.
    // -1 points to the last position of an array
    const type = file.name.split('.').at(-1);

    const image = new Image();
    const url = URL.createObjectURL(file);
    image.src = url;

    // Handle validations when the image is loaded
    image.onload = () => {
      const isValid = isLogoValid(size, type, image.width, image.height);

      if (!isValid) {
        setError(true);
        return;
      }

      setError(false);

      props.handler(file, url);
      props.setUserChangedInput(true);
    };
  };

  const hasLogo = props.logo.url;

  return (
    <div className='row px-0 mx-0 content-block upload-file-block'>
      <div className='text-header h3 section-title'>
        {props.intl.messages['logotype']}
      </div>
      {isXSPlan(props.plan) ? (
        <FeatureNotAvailableBanner />
      ) : (
        <>
          <Paragraph className='text-paragraph px-0'>
            {props.intl.messages['logotypeDescription']}
          </Paragraph>
          <label htmlFor='upload_logo' className='button button-secondary'>
            {props.intl.messages['uploadImage']}
          </label>
          <Input
            id='upload_logo'
            type='file'
            onChange={onChooseFile}
            className='custom-file-input'
          />
          {hasLogo && (
            <Button
              id='remove_image'
              className='button button-plain-text button-icon'
              label='removeImage'
              iconClassName='icon fas fa-xmark'
              onClick={() => {
                props.handleRemoveLogo();
              }}
            />
          )}
        </>
      )}
      {error && (
        <Alert
          id={'logo_error_alert'}
          iconClassName='fas fa-circle-exclamation'
          alertType='alert-error --icon --small'
        >
          {props.intl.messages['imgUploadError']}
        </Alert>
      )}
    </div>
  );
};

export default injectIntl(Logo);
