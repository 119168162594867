import React from 'react';
import { injectIntl } from 'react-intl';

const TableContainer = (props) => {
  const {
    className,
    message,
    searchBox,
    table,
    rightMenu,
    mobileMenu,
    toolbar,
  } = props;

  return (
    <div id='main-content' className={`container ${className}`}>
      <div className='row mx-0'>
        <div className='d-flex w-100 mb-4 px-0'>
          <div className='text-header h2'>{message || <></>}</div>
        </div>
        <div className='col-lg-9 ps-0'>
          <div className='container px-0'>
            <div className='content-block advanced-search-block'>
              {searchBox || <></>}
            </div>
            {table || <></>}
          </div>
        </div>
        {(rightMenu && (
          <div className='col-lg-3 pe-0'>{rightMenu || <></>}</div>
        )) || <></>}

        {mobileMenu || <></>}

        {toolbar || <></>}
      </div>
    </div>
  );
};

export default injectIntl(TableContainer);
