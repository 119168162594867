import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import LabelWithToggle from '../../../molecules/labels/LabelWithToggle';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import { UserService } from '../../../../services/UserService';
import RowUser from '../../../organisms/tables/RowUser';
import { StateHelper } from '../../helper/state';
import { AppContext } from '../../../../contexts/AppContext';
import Input from '../../../atoms/inputs/Input';
import { DEFAULT_PHONE_CONTRY } from '../../../../constants/defaultValues';
import Button from '../../../atoms/buttons/Button';
import Modal from '../../../organisms/modals/Modal';
import { SAFTService } from '../../../../services/SAFTService';
import HandleCallResultModal from '../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import NotAllowedLayout from '../../feedback/not-allowed/NotAllowedLayout';

const ScheduleSaftLayout = (props) => {
  const notAllowedPlans = ['XS', 'S'];
  const { appState } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    total_entries: 1,
    current_page: 1,
    page: 1,
    total_pages: 0,
    per_page: 10,
  });
  const ordenation = {
    sort: 'id',
    sort_order: 'asc',
    text: '',
  };
  const [usersIdsSelected, setUsersIdsSelected] = useState([]);
  const [fields, setFields] = useState({
    id: null,
    accountant_name: '',
    accountant_company: '',
    accountant_email: '',
    accountant_phone: '',
    accountant_phone_country_id: DEFAULT_PHONE_CONTRY,
    accountant_not_active: true,
    days: 1,
    active: false,
    users: [],
  });
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const daysAvailable = Array.from({ length: 20 }, (_, i) => i + 1).map(
    (i) => ({
      key: i,
      value: i,
    })
  );
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);

  const fetchAllUsers = async () => {
    const apiFilters = {
      page: filters.current_page,
      items_per_page: filters.per_page,
      sort: ordenation.sort,
      sort_order: ordenation.sort_order,
    };

    const params = new URLSearchParams(apiFilters).toString();

    return await UserService.getAll(params);
  };

  const handleSelectedLines = (id) => {
    let filteredLines = usersIdsSelected.filter((item) => item !== id);

    if (filteredLines.length === usersIdsSelected.length) {
      filteredLines.push(id);
    }

    setUsersIdsSelected(filteredLines);
    onChangeField(
      'users',
      filteredLines.map((userId) => {
        return {
          id: userId,
          email: users.find((user) => user.id === userId).email,
        };
      })
    );
  };

  const onChangeField = (key, value) => {
    setFields({
      ...fields,
      [key]: value,
    });
  };

  const canSubmit = () => {
    if (fields.active) {
      if (fields.users.length === 0 && fields.accountant_not_active) {
        return false;
      }

      if (!fields.accountant_not_active) {
        if (
          fields.accountant_name === '' ||
          fields.accountant_name === null ||
          fields.accountant_email === '' ||
          fields.accountant_email === null
        ) {
          return false;
        }
      }
    } else if (fields.id === null) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    setShowModal(false);
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('exportSAFTProcessing');

    const callFields = {
      accountant_name:
        fields.accountant_name !== '' ? fields.accountant_name : null,
      accountant_company:
        fields.accountant_company !== '' ? fields.accountant_company : null,
      accountant_email:
        fields.accountant_email !== '' ? fields.accountant_email : null,
      accountant_not_active: fields.accountant_not_active,
      days: fields.days,
      active: fields.active,
      users: fields.users.map((user) => {
        return {
          id: user.id,
        };
      }),
    };

    const phoneCode = phoneCountryCodes.find(
      (country) =>
        country.value === parseInt(fields.accountant_phone_country_id)
    )?.key;

    if (phoneCode !== undefined && phoneCode !== 'defaultCountry') {
      const filterPhoneCode = phoneCode.split(' ')[1];
      callFields.accountant_phone = `${filterPhoneCode} ${fields.accountant_phone}`;
    } else {
      callFields.accountant_phone = null;
    }

    const response =
      fields.id === null
        ? await SAFTService.submitScheduleSAFT(callFields)
        : await SAFTService.updateScheduleSAFT(callFields);

    setSubmitting(false);

    if (!response.error) {
      // Handle api response
      if (response.message) {
        const sanitizedResponse = DOMPurify.sanitize(response.message);
        const parsedResponse = parse(sanitizedResponse);
        setCallResponse(parsedResponse);
      } else {
        fields.active
          ? setCallResponse(props.intl.messages['scheduleSAFTSuccess'])
          : setCallResponse(props.intl.messages['scheduleSAFTDisableSuccess']);
      }
      setCallSucceeded(true);
    } else {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.error);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(false);
    }
  };

  useEffect(() => {
    async function fetchScheduleAndUsers() {
      const response = await SAFTService.getScheduleSAFT();

      if (!response.error) {
        if (response.saft_schedule.id !== null) {
          response.saft_schedule.users = response.saft_schedule.users.filter(
            (user) => user.send_email === true
          );

          setFields({ ...response.saft_schedule });

          const usersSelected = [];
          response.saft_schedule.users.forEach((user) =>
            usersSelected.push(user.id)
          );
          setUsersIdsSelected(usersSelected);

          if (response.saft_schedule.accountant_phone !== null) {
            const phoneSplit =
              response.saft_schedule.accountant_phone.split(' ');

            const phoneCode = phoneSplit[0];

            if (phoneCode !== response.saft_schedule.accountant_phone) {
              const phone = phoneSplit[1];

              setFields({
                ...response.saft_schedule,
                accountant_phone_country_id: phoneCountryCodes.find((country) =>
                  country.key.includes(phoneCode)
                )?.value,
                accountant_phone: phone,
              });
            } else {
              setFields({
                ...response.saft_schedule,
                accountant_phone_country_id: DEFAULT_PHONE_CONTRY,
              });
            }
          } else {
            setFields({
              ...response.saft_schedule,
              accountant_phone_country_id: DEFAULT_PHONE_CONTRY,
            });
          }
        } else {
          setFields({
            ...fields,
          });
        }

        const usersResponse = await fetchAllUsers();
        if (!usersResponse.error) {
          const filteredResponse = [];

          usersResponse?.users.forEach((user) => {
            if (
              filteredResponse.find((el) => el.id === user.id) === undefined
            ) {
              filteredResponse.push(user);
            }
          });

          setUsers(filteredResponse);
          setFilters({
            ...usersResponse?.pagination,
            page: usersResponse?.pagination?.current_page,
          });
        }
      }
    }

    fetchScheduleAndUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCountryCodes]);

  useMemo(async () => {
    setPhoneCountryCodes(
      await StateHelper.fetchPhoneCountries(appState.accountId)
    );
  }, [appState.accountId]);

  const canView = () => {
    if (appState.plan && !notAllowedPlans.includes(appState.plan)) {
      return true;
    } else {
      return false;
    }
  };

  return canView() ? (
    <div id='main-content' className='container --schedule-saft'>
      <div className='d-flex flex-wrap row mx-0 w-100'>
        <div
          data-testid='schedule-saft-layout'
          className='col-12 col-lg-9 ps-md-0'
        >
          <div className='d-flex flex-column w-100'>
            <div className='col-lg-10 px-0'>
              <Paragraph id='schedule-saft-title' className='text-header h2'>
                {props.intl.messages['scheduleSAFTTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {props.intl.messages['scheduleSAFTDescription']}
                </Span>
              </Paragraph>
            </div>
            <div className='sep-light mt-2' />
          </div>

          <div className='form-container d-flex flex-column w-100 mt-5'>
            <LabelWithToggle
              className='mb-3'
              label='scheduleSAFTActive'
              toggleID='schedule-saft-active-toggle'
              isChecked={fields?.active}
              onChange={() => onChangeField('active', !fields?.active)}
            />

            <div className='col-12 col-lg-4 px-0 day-selector'>
              <label className='text-label mb-3'>
                {props.intl.messages['scheduleSAFTDay']}
              </label>
              <SelectWithValues
                id='scheduleSAFTDay'
                key='scheduleSAFTDay'
                options={daysAvailable}
                value={fields?.days}
                onChange={(e) => onChangeField('days', e.target.value)}
                disabled={!fields?.active}
              />
            </div>

            <div className='d-flex flex-column w-100 mt-4'>
              <Paragraph className='text-header h4 mb-1'>
                {props.intl.messages['scheduleSAFTUsersTitle']}
              </Paragraph>
              <Span className='color-gray-400'>
                {props.intl.messages['scheduleSAFTUsersDescription']}
              </Span>
              <div className='sep-light mt-2' />
              <div className='users-wrapper d-flex flex-row flex-wrap w-100 mt-2'>
                {users.map((user, index) => (
                  <div
                    key={index}
                    className='users-container d-flex flex-column col-12 col-md-6 px-0 mb-2'
                  >
                    <div className='users-row d-flex flex-grow-1'>
                      <RowUser
                        id={user.id}
                        data-testid={`user-${index}`}
                        document={user}
                        index={index}
                        key={index}
                        documentsTab={props.documentsTab}
                        userId={user.id}
                        setSelectedLines={handleSelectedLines}
                        selectedLines={usersIdsSelected}
                        disabled={!fields?.active}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='d-flex flex-column w-100 mt-5'>
              <LabelWithToggle
                className='mb-3'
                label='scheduleSAFTActivateAccountant'
                toggleID='schedule-saft-accountant-toggle'
                isChecked={!fields?.accountant_not_active}
                onChange={() =>
                  onChangeField(
                    'accountant_not_active',
                    !fields?.accountant_not_active
                  )
                }
              />

              <div>
                <Paragraph className='text-header h4 mb-1'>
                  {props.intl.messages['scheduleSAFTAccountantTitle']}
                </Paragraph>
                <Span className='color-gray-400'>
                  {props.intl.messages['scheduleSAFTAccountantDescription']}
                </Span>
                <div className='sep-light mt-2' />
              </div>

              <div className='accountant-container d-flex flex-column w-100 mt-2'>
                <div className='row mx-0 d-flex w-100 mb-2'>
                  <div className='col-12 col-md-6 ps-md-0'>
                    <label className='text-label mt-3'>
                      {props.intl.messages['scheduleSAFTAccountantName']}*:
                    </label>
                    <Input
                      id='accountant_name'
                      type='text'
                      controlled
                      value={fields?.accountant_name || ''}
                      onChange={(e) =>
                        onChangeField('accountant_name', e.target.value)
                      }
                      disabled={fields?.accountant_not_active}
                    />
                  </div>

                  <div className='col-12 col-md-6 pe-md-0'>
                    <label className='text-label mt-3'>
                      {props.intl.messages['scheduleSAFTAccountantContact']}:
                    </label>
                    <div className='row mx-0'>
                      <div className='col-5 ps-0'>
                        <SelectWithValues
                          id='accountant_phone_code'
                          keyName='phone_country_id'
                          valueName='phone_code'
                          options={phoneCountryCodes}
                          value={fields?.accountant_phone_country_id}
                          onChange={(e) =>
                            onChangeField(
                              'accountant_phone_country_id',
                              e.target.value
                            )
                          }
                          disabled={fields?.accountant_not_active}
                        />
                      </div>
                      <div className='col-7 pe-0'>
                        <Input
                          id='accountant_phone'
                          type='number'
                          placeholder='999999999'
                          controlled
                          value={fields?.accountant_phone || ''}
                          onChange={(e) =>
                            onChangeField('accountant_phone', e.target.value)
                          }
                          disabled={fields?.accountant_not_active}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mx-0 d-flex w-100 mb-2'>
                  <div className='col-12 col-md-6 ps-md-0'>
                    <label className='text-label mt-3'>
                      {props.intl.messages['scheduleSAFTAccountantCompany']}:
                    </label>
                    <Input
                      id='accountant_company'
                      type='text'
                      controlled
                      value={fields?.accountant_company || ''}
                      onChange={(e) =>
                        onChangeField('accountant_company', e.target.value)
                      }
                      disabled={fields?.accountant_not_active}
                    />
                  </div>

                  <div className='col-12 col-md-6 pe-md-0'>
                    <label className='text-label mt-3'>
                      {props.intl.messages['scheduleSAFTAccountantEmail']}*:
                    </label>
                    <Input
                      id='accountant_email'
                      type='text'
                      controlled
                      value={fields?.accountant_email || ''}
                      onChange={(e) =>
                        onChangeField('accountant_email', e.target.value)
                      }
                      disabled={fields?.accountant_not_active}
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-end w-100 mt-5'>
                  <Button
                    className='button button-primary p-0'
                    onClick={() => setShowModal(true)}
                    disabled={!canSubmit()}
                  >
                    {props.intl.messages['save']}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-3 pe-md-0 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.AT_COMUNICATION} />
        </div>
      </div>

      {showModal && (
        <Modal
          titleKey={'scheduleSAFTConfirm'}
          actions={{
            submit: {
              label: 'scheduleSAFTConfirmLabel',
              onClick: handleSubmit,
            },
            cancel: {
              onClick: () => setShowModal(false),
            },
          }}
        >
          <div className='d-flex flex-column w-100'>
            {(!fields.active && (
              <Span className='color-gray-400'>
                {props.intl.messages['scheduleSAFTDisable']}
              </Span>
            )) || (
              <>
                <Span className='color-gray-400'>
                  <FormattedMessage
                    id={'scheduleSAFTModalDescription'}
                    values={{
                      day: fields.days,
                    }}
                  />
                </Span>

                <div className='d-flex flex-row flex-wrap mt-3 w-100'>
                  {!fields.accountant_not_active && (
                    <div className='d-flex flex-row mb-2 col-6 px-0'>
                      <div className='d-flex flex-row w-100 px-2'>
                        <Paragraph className='color-brand-primary bold m-0 text-break'>
                          {fields.accountant_email}
                        </Paragraph>
                        <Span className='color-gray-400 ms-1'>
                          <small>
                            (
                            {props.intl.messages['scheduleSAFTAccountantTitle']}
                            )
                          </small>
                        </Span>
                      </div>
                    </div>
                  )}
                  {fields.users.length > 0 &&
                    fields.users.map((user) => (
                      <div
                        key={user.id}
                        className='d-flex flex-row mb-2 col-6 px-0'
                      >
                        <div className='d-flex flex-row w-100 px-2'>
                          <Paragraph className='color-brand-primary bold m-0 text-break'>
                            {user.email}
                          </Paragraph>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </Modal>
      )}

      {isSubmitted && (
        <HandleCallResultModal
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton={true}
        />
      )}
    </div>
  ) : (
    <NotAllowedLayout
      title='scheduleSAFTNotAllowedTitle'
      description='scheduleSAFTNotAllowedDescription'
    />
  );
};

export default injectIntl(ScheduleSaftLayout);
