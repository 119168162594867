import React from 'react';
import { injectIntl } from 'react-intl';
import parse from 'html-react-parser';

const SMSPreview = (props) => {
  return (
    <div className='template-email'>
      <div className='template-body bg-ix-white'>
        <div className='template-content flex'>{parse(props.content)}</div>
      </div>
    </div>
  );
};

export default injectIntl(SMSPreview);
