import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import { DigitalSignatureHelper } from './helper';
import NotFoundCard from '../../feedback/card/NotFoundCard';
import DigitalSignatureProviderCard from './DigitalSignatureProviderCard';
import ContentModal from './modal/ContentModal';
import ProviderCardSkeleton from './_skeleton/ProviderCardSkeleton';
import { SignatureProviderService } from '../../../../services';
import { SETTINGS } from '../../../../constants/url';
import Alert from '../../../molecules/alerts/Alert';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';

/**
 * Enum for type of signatures
 * @enum {string}
 * @readonly
 */
export const SIGNATURES = {
  AMA: 'ama',
  MULTICERT: 'multicert',
};

/**
 * Enum for AMA state validation
 * @enum {string}
 * @readonly
 */
const PROVIDER_EXTERNAL_STATE = {
  CANCEL: 'cancelled',
};

/**
 * Enum for AMA status configuration
 * @enum {string}
 * @readonly
 */
const SIGNATURE_STATUS = {
  ACTIVE: '1',
  ERROR: '2',
  IN_PROGRESS: '3',
};

export const DigitalSignatureLayout = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reloadData, setReloadData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isStepLoading, setIsStepLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    DigitalSignatureHelper.CONFIGURATION_STEPS.TO_CONFIGURE
  );
  const [providers, setProviders] = useState({ list: [], current: {} });
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [signatureStatus, setSignatureStatus] = useState(null);
  const [isConsentAgreementChecked, setIsConsentAgreementChecked] =
    useState('');

  const reloadPageData = () => {
    setReloadData((state) => state + 1);
  };

  const openModal = (providerId) => {
    setSelectedProvider(providerId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(false);
    setCurrentStep(DigitalSignatureHelper.CONFIGURATION_STEPS.TO_CONFIGURE);
  };

  const fetchActiveProvider = async () => {
    const response = await SignatureProviderService.getActiveProvider();

    setProviders((prevState) => ({
      ...prevState,
      current: !response?.error ? response.provider : {},
    }));

    if (!response.provider) {
      await checkProviderStatusConfiguration();
    }
  };

  const fetchProviders = async () => {
    const response = await SignatureProviderService.getAll();
    if (!response.error) {
      setProviders((prevState) => ({
        ...prevState,
        list: response.providers,
      }));
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const checkProviderStatusConfiguration = async () => {
    const response =
      await SignatureProviderService.getSignatureStatusConfiguration();

    if (!response.error) {
      setSignatureStatus(response.digital_signature_item.status);
      return;
    }

    setSignatureStatus(null);
  };

  /**
   * Verify state from AMA callback
   */
  const verifyState = async () => {
    const hash = new URLSearchParams(window.location.hash.substring(1));

    if (hash.get('error') === PROVIDER_EXTERNAL_STATE.CANCEL) {
      setCurrentStep(DigitalSignatureHelper.CONFIGURATION_STEPS.NOT_CONFIGURED);
      setIsModalOpen(true);
      setIsLoading(false);
    }

    if (hash.get('access_token')) {
      setCurrentStep(DigitalSignatureHelper.CONFIGURATION_STEPS.CONFIGURING);
      setIsModalOpen(true);
      setIsStepLoading(true);

      const dataToken = Object.fromEntries(hash.entries());
      const response = await SignatureProviderService.sendToken(dataToken);

      if (!response.error) {
        setCurrentStep(DigitalSignatureHelper.CONFIGURATION_STEPS.CONFIGURING);
      } else {
        setCurrentStep(
          DigitalSignatureHelper.CONFIGURATION_STEPS.NOT_CONFIGURED
        );
      }

      setTimeout(() => {
        setIsModalOpen(false);
      }, 1500);
    }

    window.history.replaceState(
      null,
      props.documentsTab,
      SETTINGS.DIGITAL_SIGNATURE
    );

    await fetchProviders();
    await fetchActiveProvider();
  };

  useEffect(() => {
    verifyState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  return (
    <div id='main-content' className='container --ds-page'>
      <div className='row mx-0 justify-content-center'>
        {/* Header */}
        <div className='col-12 px-0 title-block page-header-block'>
          <div className='text-header h2'>
            <FormattedMessage id='digitalSignatureTitle' />
          </div>
          <div className='text-paragraph --secondary mt-2'>
            <FormattedMessage id='digitalSignatureSubtitle' />
          </div>
        </div>

        {/* Left Content */}
        <div className='col-lg-9 ps-0'>
          <div className='row mx-0'>
            {signatureStatus && (
              <div className='mb-3 --ds-modals'>
                <Alert
                  id={'logo_error_alert'}
                  iconClassName='fas fa-circle-exclamation'
                  alertType={`${
                    signatureStatus === SIGNATURE_STATUS.ERROR
                      ? 'alert-error'
                      : 'alert-information'
                  }  --icon --small`}
                >
                  <FormattedMessage
                    id={
                      signatureStatus === SIGNATURE_STATUS.ERROR
                        ? 'dsNotConfiguredTitle'
                        : 'dsInProgressTitle'
                    }
                    values={{
                      b: (...chunks) => <Span className='bold'>{chunks}</Span>,
                    }}
                  />
                </Alert>
              </div>
            )}

            {isLoading ? (
              <ProviderCardSkeleton />
            ) : !providers?.current?.id ? (
              <NotFoundCard title='signatureNotConfigured'>
                <FormattedMessage id='signatureNotConfiguredInfo' />
              </NotFoundCard>
            ) : (
              <DigitalSignatureProviderCard
                key={'provider-selected'}
                isActiveMode
                provider={providers.current}
                openModal={openModal}
                reload={reloadPageData}
                isConsentAgreementChecked={isConsentAgreementChecked}
                setIsConsentAgreementChecked={setIsConsentAgreementChecked}
              />
            )}

            {/* List of signature providers */}
            <div className='row mx-0'>
              <div className='col-12 px-0 mt-5'>
                <Paragraph className='text-header h4'>
                  {props.intl.messages['digitalSignatureProviders']}
                </Paragraph>
                <Span className='color-gray-400'>
                  {props.intl.messages['digitalSignatureProvidersDescription']}
                </Span>
                <div className='sep-light mt-2' />
              </div>

              <div className='col-12 px-0 mt-5 justify-content-center'>
                {isLoading ? (
                  <ProviderCardSkeleton count={4} />
                ) : (
                  providers?.list?.length > 0 &&
                  providers?.list?.map((provider, index) => {
                    return (
                      !provider.in_use && (
                        <DigitalSignatureProviderCard
                          key={index}
                          provider={provider}
                          openModal={openModal}
                          isWaitingConfiguration={
                            SIGNATURE_STATUS.IN_PROGRESS === signatureStatus ||
                            providers?.current?.id
                          }
                          isConsentAgreementChecked={isConsentAgreementChecked}
                          setIsConsentAgreementChecked={
                            setIsConsentAgreementChecked
                          }
                        />
                      )
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Content */}
        <div className='col-lg-3 pe-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.AT_COMUNICATION} />
        </div>

        {/* Step by Step Modal */}
        {isModalOpen && (
          <ContentModal
            id='ds-content-modal'
            isLoading={isStepLoading}
            onClose={() => closeModal()}
            step={currentStep}
            nextStep={setCurrentStep}
            selectedProvider={selectedProvider}
            reload={reloadPageData}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(DigitalSignatureLayout);
