import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import SummaryTable from '../../tables/SummaryTable';
import { CREATE_ESTIMATE_PATHS } from '../../../../constants/index';
import { buildPath } from '../util/pathHelper';
import { buildChildren } from '../util/buttonsHelper';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const RightMenu = (props) => {
  /**
   * Creates a child component for each help information.
   * @function
   * @param {object} helpInfo - JSON object with each help information.
   * @returns {object} array with all child components.
   */
  const addHelpInfo = () => {
    const helpInfo = ['quoteHelp', 'proformaHelp', 'feesNoteHelp'];

    return helpInfo.map((element, index) => (
      <FormattedMessage
        key={element}
        id={helpInfo[index]}
        values={{
          span: (chunks) => <span className='d-block bold'>{chunks}</span>,
        }}
      />
    ));
  };

  const summaryMobileDisplay = openMobileSideContent(
    'mobileSummary',
    props.mobileSideContent
  );

  const buttonsMobileDisplay = openMobileSideContent(
    'mobileButtons',
    props.mobileSideContent
  );

  if (summaryMobileDisplay === 'open' || buttonsMobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  const {
    globalSummaryKey,
    totals,
    documentsNumber,
    accountSettings,
    filters,
    bulkLoading,
    documentsTab,
  } = props;

  return (
    <div className='container px-0'>
      {/* Mobile Menu: add class 'open' */}
      <div
        className={`buttons-block mobile-side-content ${buttonsMobileDisplay}`}
      >
        <div className='row mx-0 content-block button-primary-block'>
          <Anchor
            className='button button-primary d-block'
            href={buildPath(CREATE_ESTIMATE_PATHS['newQuote'])}
          >
            <FormattedMessage id='createNewQuote' />
          </Anchor>
        </div>
        <div className='row mx-0 content-block dropdown-button-block'>
          <ButtonDropdown
            label='createOtherDocuments'
            dropdownType='button-secondary'
          >
            {buildChildren(CREATE_ESTIMATE_PATHS)}
          </ButtonDropdown>
        </div>
        {/* Mobile Menu */}
        <div
          className={`row mx-0 p-0 content-block summary-table-block mobile-side-content ${summaryMobileDisplay}`}
        >
          <SummaryTable
            globalSummaryKey={globalSummaryKey}
            totals={totals}
            documentsNumber={documentsNumber}
            accountSettings={accountSettings}
            filters={filters}
            bulkLoading={bulkLoading}
            documentsTab={documentsTab}
          />
          <InformativeDropdown
            label='help'
            dropdownType='help-info-panel'
            iconClassName='icon fas fa-info-circle'
            isVisible={true}
          >
            {addHelpInfo()}
          </InformativeDropdown>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(RightMenu);
