import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../atoms/icons/Icon';
import Span from '../../../atoms/spans/Span';
import { injectIntl } from 'react-intl';
import ButtonWithConfirmedState from '../../../molecules/buttons/ButtonWithConfirmedState';
import {
  registrationClassName,
  sequenceStatusName,
  registerStatusName,
  isCollectionInactive,
} from '../../../organisms/modals/sequences/util/states.js';
import { DEMO_ACCOUNT_SEQUENCE } from '../../../../constants';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const SequenceStatus = (props) => {
  const { sequence, isDefault, onButtonClick, registerStatus, status } = props;

  const registrationClass = registrationClassName(registerStatus);
  const registerStatusMessage = registerStatusName(registerStatus);

  let statusName, statusClassName;

  if (isCollectionInactive(registerStatus, sequence.serie_name)) {
    statusName = sequenceStatusName('inactive');
    statusClassName = 'inactive';
  } else if (sequence.serie_name === DEMO_ACCOUNT_SEQUENCE) {
    statusClassName = 'final';
    statusName = 'active';
  } else {
    statusClassName = status === 'active' ? 'final' : status;
    statusName = sequenceStatusName(status);
  }

  return (
    <div className='sequence-status '>
      <div className={`status-label bold status-color-${statusClassName}`}>
        <Icon className='icon fas fa-circle' />
        <Span className='test'>{props.intl.messages[statusName]}</Span>
      </div>
      <div
        className={`registration-label bold uppercase  status-color-${
          registrationClass || 'inactive'
        }`}
      >
        <Icon className={`icon fas fa-circle-check`} />
        <Icon className={`icon fas fa-circle-exclamation`} />
        <Icon className={`icon fas fa-circle-xmark`} />
        <Icon className={`icon fas fa-ban`} />
        {props.intl.messages[registerStatusMessage]}
      </div>
      {registerStatus && (
        <div className='uppercase text-paragraph --tiny'>
          {props.intl.messages[sequence.serie_type]}
        </div>
      )}
      <ButtonWithConfirmedState
        isConfirmed={isDefault}
        confirmedLabel='defaultSequence'
        buttonLabel='defaultSequenceAction'
        onButtonClick={onButtonClick}
      />
    </div>
  );
};

SequenceStatus.propTypes = {
  sequence: PropTypes.object,
  isDefault: PropTypes.bool,
};

export default injectIntl(SequenceStatus);
