import { errorKeys } from '../../constants/documentCustomization';

/**
 * Enum HTTP Status
 * @enum {number}
 * @readonly
 */
const HTTP_STATUS = {
  NO_CONTENT: 204,
};
Object.freeze(HTTP_STATUS);

export class ErrorHandler {
  static isObject = (obj) => {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
  };

  static handleRequest = async (call) => {
    try {
      const response = await call();

      const responseJson =
        HTTP_STATUS.NO_CONTENT !== response.status ? await response.json() : {};

      const responseWithStatus = { ...responseJson, status: response.status };

      if (responseWithStatus.errors) {
        if (responseWithStatus.errors.length === 1) {
          if (!this.isObject(responseWithStatus.errors[0])) {
            return responseWithStatus.errors[0];
          } else {
            return { ...responseWithStatus.errors[0], status: response.status };
          }
        }
      }

      return responseWithStatus;
    } catch {
      return { error: errorKeys.fetchError };
    }
  };
}
