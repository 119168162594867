import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { clickOutsideDropdown } from '../../organisms/dropdowns/util/filterSelectionHelper';
import Icon from '../../atoms/icons/Icon';

/**
 *
 * TODO: Didn't transform this class into a functional component,
 * because I still don't know how to use this.bodyRef in a functional component.
 *
 * ES6 stateless component
 * * @param {object} props - React props object (className, children, intl, onClick function).
 */
export default class ButtonDropdown extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.state = {
      isVisible: this.props?.isVisible || false,
    };
  }

  /**
   * Click on dropDown to open or close it.
   * @function
   */
  showOptions = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  /**
   * Hanlde click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    !this.props.noMouseDown &&
      document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const { isVisible } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';

    return (
      <div
        id={this.props.id}
        data-testid={this.props.id}
        className={`button button-dropdown ${this.props.dropdownType} ${cssClass} ${this.props.className}`}
        onClick={this.showOptions}
        ref={this.headerRef}
      >
        {this.props.iconClassName && (
          <Icon className={this.props.iconClassName} />
        )}
        <div className='label header'>
          <FormattedMessage
            id={this.props.label}
            values={this.props.labelValues}
          />
        </div>
        {this.state.isVisible && !this.props.showFixedContent && (
          <div
            className='content-to-hide'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <ul>
              {this.props.children.map((child, index) => (
                <li onClick={child.onClick} key={index}>
                  {child}
                </li>
              ))}
            </ul>
          </div>
        )}

        {this.state.isVisible && this.props.showFixedContent && (
          <div
            className='content-to-hide-fixed'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <ul>
              {this.props.children.map((child, index) => (
                <li onClick={child.onClick} key={index}>
                  {child}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

ButtonDropdown.propTypes = {
  label: PropTypes.string,
  dropdownType: PropTypes.string,
  children: PropTypes.array,
};
