import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import { CREATE_SCHEDULES_PATHS } from '../../../../constants/index';
import { buildPath } from '../util/pathHelper';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

const RightMenu = (props) => {
  /**
   * Creates a child component for each help information.
   * @function
   * @param {object} helpInfo - JSON object with each help information.
   * @returns {object} array with all child components.
   */
  const addHelpInfo = () => {
    let childComponents = [];
    const helpInfo = ['scheduleHelp', 'automaticSendingHelp'];

    childComponents = helpInfo.map((element, index) => (
      <FormattedMessage
        key={element}
        id={helpInfo[index]}
        values={{
          span: (chunks) => <span className='d-block bold'>{chunks}</span>,
        }}
      />
    ));
    return childComponents;
  };

  const summaryMobileDisplay = openMobileSideContent(
    'mobileSummary',
    props.mobileSideContent
  );

  const buttonsMobileDisplay = openMobileSideContent(
    'mobileButtons',
    props.mobileSideContent
  );

  if (summaryMobileDisplay === 'open' || buttonsMobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='container px-0'>
      <div
        className={`buttons-block mobile-side-content ${buttonsMobileDisplay}`}
      >
        {false && (
          <div className='row mx-0 content-block button-primary-block'>
            <Anchor
              className='button button-primary d-block'
              href={buildPath(CREATE_SCHEDULES_PATHS['createNewSchedule'])}
            >
              <FormattedMessage id='createNewSchedule' />
            </Anchor>
          </div>
        )}
        <div
          className={`row mx-0 p-0 content-block summary-table-block mobile-side-content ${summaryMobileDisplay}`}
        >
          <InformativeDropdown
            label='help'
            dropdownType='help-info-panel mt-0'
            iconClassName='icon fas fa-info-circle'
            isVisible={true}
          >
            {addHelpInfo()}
          </InformativeDropdown>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(RightMenu);
