import React, { Component } from 'react';
import TableHeaderSimple from '../headers/TableHeaderSimple';
import BodyNoSelection from './BodyNoSelection';
import Pagination from '../../molecules/grids/Pagination';
import * as pageManager from './util/pageManager';
import { isQueryStringEmpty } from '../../templates/util/api/queryStringManager';
import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';
import { ReactComponent as LoadingTable } from '../../../assets/imgs/loading-table.svg';
import { ReactComponent as LoadingTableMobile } from '../../../assets/imgs/loading-table-mobile.svg';
import { LOADING_TIME } from '../../../constants/index';
import MobileButtons from '../buttons/MobileButtons';
import NoSearchResults from './NoSearchResults';
import FirstDocumentScreen from './FirstDocumentScreen';
import { isOnSequencesTab } from '../../templates/helper/documentsTabHelper.js';

/**
 * React component
 * @class
 * Documents table that renders header, body and footer/pagination.
 */
export default class TableSimple extends Component {
  /**
   * @constructor
   * @param {object} props - React props object.
   */
  constructor(props) {
    super(props);

    this.state = {
      loadingTimerFlag: true,
      lastDocumentChecked: null,
      sortOptions: props.sortOptions,
    };

    this.disableLoading = this.disableLoading.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
    this.timer = setTimeout(this.disableLoading, LOADING_TIME);
  }

  renderTableBody(filters, isFirstDocument) {
    if (isFirstDocument) {
      return <FirstDocumentScreen documentsTab={this.props.documentsTab} />;
    } else {
      if (
        !pageManager.hasDocumentsToShow(this.props.documents) &&
        !this.props.loadingFirstRequest
      ) {
        return <NoSearchResults documentsTab={this.props.documentsTab} />;
      } else {
        if (!this.props.loadingFirstRequest) {
          return (
            <BodyNoSelection
              id={this.props.id}
              documents={this.props.documents}
              accountSettings={this.props.accountSettings}
              accountId={this.props.accountId}
              showValuesWithVat={filters.showTotalWithIVA}
              documentsTab={this.props.documentsTab}
              getListing={this.props.getListing}
              updateListing={this.props.updateListing}
              credentialsInformation={this.props.credentialsInformation}
              setSelectedLines={this.props.setSelectedLines}
              selectedLines={this.props.selectedLines}
              openModal={this.props.openModal}
              userId={this.props.userId}
            />
          );
        }
      }
    }
  }

  /**
   * Disables loading component timer.
   * @function
   */
  disableLoading() {
    this.setState({ loadingTimerFlag: false });
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    if (prevProps.globalTableKey !== this.props.globalTableKey) {
      this.setState({ loadingTimerFlag: true });
      this.timer = setTimeout(this.disableLoading, LOADING_TIME);
    }
  }

  render() {
    const {
      loadingFirstRequest,
      loading,
      filters,
      accountSettings,
      numberOfPages,
      getDocumentsPage,
      sortDocumentsByPropertyAndOrder,
      setItemsPerPage,
      resetAllFilters,
      openMobileMenu,
      mobileSideContent,
      closeMobileMenu,
      documentsTab,
      noResultsPerPage,
    } = this.props;

    const { loadingTimerFlag, sortOptions } = this.state;

    const isLoadingEnabled = pageManager.isLoadingEnabled(
      loading,
      loadingTimerFlag
    );

    const isFirstDocument = pageManager.isFirstDocumentCreated(
      accountSettings,
      documentsTab
    );

    return (
      <div data-testid={this.props.id}>
        <div className='row mx-0 mobile-buttons-block'>
          <MobileButtons
            openMobileMenu={openMobileMenu}
            documentsTab={documentsTab}
          />
        </div>
        <React.Fragment>
          {!isOnSequencesTab(documentsTab) && (
            <div className='content-block table-options-block'>
              <TableHeaderSimple
                id={this.props.id}
                globalResetKey={this.props.globalResetKey}
                filters={filters}
                setItemsPerPage={setItemsPerPage}
                sortDocumentsByPropertyAndOrder={
                  sortDocumentsByPropertyAndOrder
                }
                sortOptions={sortOptions}
                resetAllFilters={resetAllFilters}
                isQueryStringEmpty={isQueryStringEmpty}
                mobileSideContent={mobileSideContent}
                closeMobileMenu={closeMobileMenu}
                documentsTab={documentsTab}
                noResultsPerPage={noResultsPerPage}
              />
            </div>
          )}
          <React.Fragment>
            <div className='content-block list-table-block'>
              {!isFirstDocument && isLoadingEnabled && (
                <div className='loading loading-tables'>
                  <IxLoading />
                  {loadingFirstRequest && <LoadingTable />}
                  {loadingFirstRequest && <LoadingTableMobile />}
                </div>
              )}
              {this.renderTableBody(filters, isFirstDocument)}
            </div>
            <div className='content-block pagination-block'>
              <Pagination
                page={filters.page}
                numberOfPages={numberOfPages}
                getDocumentsPage={getDocumentsPage}
              />
            </div>
          </React.Fragment>
        </React.Fragment>
      </div>
    );
  }
}
