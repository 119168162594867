import React from 'react';
import Alert from '../../molecules/alerts/Alert';
import { FormattedMessage } from 'react-intl';
import Span from '../../atoms/spans/Span';

const RequestHandelingAlert = (props) => {
  const type = props.type || 'success';

  const icons = {
    success: 'fa-circle-check color-brand-primary',
    warning: 'fa-triangle-exclamation',
    information: 'fa-info-circle',
  };

  return (
    <div className='notification-area margin-top'>
      {props.error && (
        <Alert
          id='request_alert_error'
          alertType={`alert-error --icon`}
          iconClassName='fas fa-circle-exclamation'
        >
          <FormattedMessage id={`${props.error}`} />
        </Alert>
      )}
      <Alert
        id='request_alert_success'
        alertType={`alert-${type} --icon ${
          props.error ? 'd-none' : 'd-block'
        } `}
        iconClassName={`fas ${icons[type]}`}
      >
        <FormattedMessage
          id={props.message ? props.message : 'dataSavedSuccess'}
          values={{
            b: (...chunks) => <Span className='bold'>{chunks}</Span>,
          }}
        />
      </Alert>
    </div>
  );
};

export default RequestHandelingAlert;
