import {
  documentNumberOfCopies,
  fillTaxExemptionReason,
  paymentDays,
} from './documentInformationHelper';

const _toKeyValue = (list, intl, type) => {
  return list.map((value) => {
    return {
      key: type ? intl.messages[type + '_' + value] : intl.messages[value],
      value,
    };
  });
};

const getLanguageList = (intl) => {
  const languages = ['pt', 'en', 'es'];

  return _toKeyValue(languages, intl);
};

const getPaymentMechanismList = (intl) => {
  const paymentMechanisms = [
    'TB',
    'MB',
    'CC',
    'CD',
    'CH',
    'CO',
    'CS',
    'DE',
    'LC',
    'NU',
    'PR',
    'TR',
    'CI',
    'OU',
  ];

  return _toKeyValue(paymentMechanisms, intl);
};

const getTaxExemptionList = (intl) => {
  const taxes = fillTaxExemptionReason();

  return _toKeyValue(taxes, intl);
};

const getDueList = (intl) => {
  const values = paymentDays();
  const keys = [0, 15, 30, 45, 60, 90];

  return keys.map((key) => {
    return {
      key: intl.messages[values[key]],
      value: key,
    };
  });
};

const getNumberOfCopiesList = (intl) => {
  const values = documentNumberOfCopies();
  const keys = [1, 2, 3, 4];

  return keys.map((key) => {
    return {
      key: intl.messages[values[key]],
      value: key,
    };
  });
};

/**
 * Return a key value with the days of the week
 *
 * @param {object} intl translations
 */
const getDaysOfWeekList = (intl) => {
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

  return _toKeyValue(daysOfWeek, intl);
};

/**
 * Enum for types of frequency
 * @enum {string}
 * @readonly
 */
const FREQUENCY_TYPE = {
  NOT_SEND: 'disabled',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  FIRST_WEEK: 'first_week',
  LAST_WEEK: 'last_week',
};

/**
 * Return a key value with the frequency of alerts
 *
 * @param {object} intl translations
 */
const getFrequencyOfAlertList = (intl) => {
  const frequencies = Object.values(FREQUENCY_TYPE);

  return _toKeyValue(frequencies, intl);
};

/**
 * Enum for schedule period
 * @enum {string}
 * @readonly
 */
const SCHEDULE_WHEN = {
  AFTER_OVERDUE: 'after_overdue',
  BEFORE_OVERDUE: 'before_overdue',
};

/**
 * Return a key value with the days of the week
 *
 * @param {object} intl translations
 */
const getTypeOfFrequencyList = (intl) => {
  const daysOfWeek = Object.values(SCHEDULE_WHEN);

  return _toKeyValue(daysOfWeek, intl);
};

/**
 * Return a key value with the days of the week
 *
 * @param {object} intl translations
 */
const getWorkPeriodList = (intl) => {
  const workPeriod = ['morning', 'afternoon'];

  return _toKeyValue(workPeriod, intl);
};

/**
 * Return a key value with the users role
 *
 * @param {object} intl translations
 */
const getUsersRoleList = (intl) => {
  const languages = ['administrator', 'contributor', 'contributor_guides'];

  return _toKeyValue(languages, intl);
};

/**
 * Return a key value with units
 *
 * @param {object} intl translations
 */
const getItemsUnitsList = (intl) => {
  const languages = ['hour', 'day', 'month', 'unit', 'service', 'other'];

  return _toKeyValue(languages, intl);
};

const getDocumentTypes = (intl, type) => {
  const document_types = [
    'invoice',
    'simplified_invoice',
    'invoice_receipt',
    'credit_note',
    'debit_note',
    'receipt',
    'quote',
    'proforma',
    'fees_note',
    'shipping',
    'transport',
    'devolution',
    'purchase_order',
    'cash_invoice',
    'open_account_link',
    'payment_thank_you',
  ];

  return _toKeyValue(document_types, intl, type);
};

const getDecimalMarkerList = (intl) => {
  const languages = [',', '.'];

  return _toKeyValue(languages, intl);
};

export const GlobalsHelpers = {
  getLanguageList,
  getPaymentMechanismList,
  getTaxExemptionList,
  getDueList,
  getNumberOfCopiesList,
  getDaysOfWeekList,
  getWorkPeriodList,
  getFrequencyOfAlertList,
  getTypeOfFrequencyList,
  getUsersRoleList,
  getItemsUnitsList,
  getDocumentTypes,
  getDecimalMarkerList,

  FREQUENCY_TYPE,
  SCHEDULE_WHEN,
};
