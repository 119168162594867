import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Anchor from '../../atoms/anchors/Anchor';
import { formatMoney } from '../../../formatters/money';
import Span from '../../atoms/spans/Span';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */

const RowBalanceMovement = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  if (props.identifiers.indexOf(props.document.id) === -1) {
    props.identifiers.push(props.document.id);
  }

  useEffect(() => {
    setIsChecked(props.isAllLinesSelected);
  }, [props.isAllLinesSelected]);

  const addDocumentAction = (document) => {
    return (
      <div className='cell checkbox-cell align-middle'>
        <CheckBoxLabel
          id={`${props.id}_checkbox-${props.index}`}
          type='checkbox'
          className='checkbox'
          checked={isChecked}
          onChange={() => {
            setIsChecked((state) => !state);
            props.setSelectedLines(document.id);
          }}
        />
      </div>
    );
  };

  const addDocumentNumber = (document) => {
    return (
      <div className='cell'>
        <Span
          className='card-label'
          label={`${props.intl.messages['regularizationNumber']}:`}
        />
        <div className='bold'>{document.number}</div>
      </div>
    );
  };

  const addDocumentDate = (document) => {
    return (
      <div className='cell col-2'>
        <Span
          className='d-block card-label'
          label={`${props.intl.messages['date']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.date}
        </div>
      </div>
    );
  };

  const addDocumentObservation = (document) => {
    return (
      <div className='cell --observations'>
        <Span
          className='card-label'
          label={`${props.intl.messages['observations']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.observation ?? '--'}
        </div>
      </div>
    );
  };

  const addDocumentBalance = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block card-label'
          label={`${props.intl.messages['regularizeBalancePayment']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description bold'
        >
          {formatMoney(
            document.value,
            props.accountSettings.currency_code,
            ','
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {props.index === 0 && (
        <CheckBoxLabel
          id='selectAll'
          type='checkbox'
          className='checkbox'
          onChange={() => {
            props.isAllLinesSelected
              ? props.setSelectedLines([])
              : props.setSelectedLines(props.identifiers);

            props.setIsAllLinesSelected((state) => !state);
          }}
        >
          {'selectAll'}
        </CheckBoxLabel>
      )}

      <div className={`card --simple`} data-testid={`contact-row`}>
        <Anchor
          className={`${isChecked && 'bg-brand-100'}`}
          id={`${props.id}_tableRow-${props.index}`}
        >
          <div className='mobile-left'>
            {addDocumentAction(props.document)}
            {addDocumentNumber(props.document)}
          </div>
          <div className='mobile-right'>
            {addDocumentDate(props.document)}
            {addDocumentObservation(props.document)}
            {addDocumentBalance(props.document)}
          </div>
        </Anchor>
      </div>
    </>
  );
};

RowBalanceMovement.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
  accountSettings: PropTypes.object,
};

export default injectIntl(RowBalanceMovement);
