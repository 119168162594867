import React from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../../atoms/icons/Icon';
import Button from '../../../atoms/buttons/Button';
import { openMobileMenu } from '../util/mobileHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const MobileMenu = (props) => {
  return (
    <div
      className={`mobile-side-container ${openMobileMenu(
        props.showMobileMenu
      )}`}
    >
      <div className='mobile-side-header'>
        <div className='header-text h4'>
          {props.intl.messages[props.sideContent]}
        </div>
        <Button
          id='btn-close-mobile-menu'
          className='close-button'
          onClick={props.closeMobileMenu}
        >
          <Icon className={'icon fas fa-xmark'} />
        </Button>
      </div>
    </div>
  );
};

export default injectIntl(MobileMenu);
