import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../headers/TableHeader';
import Body from './Body';
import Pagination from '../../molecules/grids/Pagination';
import * as pageManager from './util/pageManager';
import { isQueryStringEmpty } from '../../templates/util/api/queryStringManager';
import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';
import { ReactComponent as LoadingTable } from '../../../assets/imgs/loading-table.svg';
import { ReactComponent as LoadingTableMobile } from '../../../assets/imgs/loading-table-mobile.svg';
import { LOADING_TIME } from '../../../constants/index';
import MobileButtons from '../buttons/MobileButtons';
import MobileSummary from '../buttons/MobileSummary';
import NoSearchResults from './NoSearchResults';
import FirstDocumentScreen from './FirstDocumentScreen';

/**
 * React component
 * @class
 * Documents table that renders header, body and footer/pagination.
 */
export default class Table extends Component {
  /**
   * @constructor
   * @param {object} props - React props object.
   */
  constructor(props) {
    super(props);

    this.state = {
      loadingTimerFlag: true,
      lastDocumentChecked: null,
      sortOptions: props.sortOptions,
    };

    this.updateSelection = this.updateSelection.bind(this);
    this.disableLoading = this.disableLoading.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
    this.timer = setTimeout(this.disableLoading, LOADING_TIME);
  }

  /**
   * Changes the allSelected component state.
   * @function
   */
  updateSelection(e, document = null) {
    this.setState({ lastDocumentChecked: document });

    const documentsInfo = {
      documents: this.props.documents,
      documentsSelected: this.props.documentsSelected,
      documentsDeselected: this.props.documentsDeselected,
    };

    const allDocumentsSelectedInfo = {
      allDocumentsSelected: this.props.allDocumentsSelected,
      prevAllDocumentsSelected: this.props.prevAllDocumentsSelected,
    };

    let newDocumentIds = documentsInfo.documentsSelected;
    let deselectedIds = documentsInfo.documentsDeselected;
    let documentsChecked;

    if (e.nativeEvent.shiftKey) {
      documentsChecked = pageManager.shiftKeySelection(
        documentsInfo.documents,
        this.state.lastDocumentChecked,
        document,
        newDocumentIds,
        deselectedIds
      );
    } else {
      documentsChecked = pageManager.updateDocumentsSelected(
        e.target.checked,
        document || documentsInfo.documents,
        newDocumentIds,
        deselectedIds
      );
    }

    if (allDocumentsSelectedInfo.allDocumentsSelected)
      this.props.deselectAllDocuments();

    if (
      pageManager.shouldSelectAllDocuments(
        allDocumentsSelectedInfo,
        documentsInfo
      )
    )
      this.props.selectAllDocuments();

    this.props.getDocumentTotals(documentsChecked.selection);
  }

  renderTableBody(filters, isPageSelected) {
    if (this.props.accountSettings.first_document) {
      return <FirstDocumentScreen documentsTab={this.props.documentsTab} />;
    } else {
      if (
        !pageManager.hasDocumentsToShow(this.props.documents) &&
        !this.props.loadingFirstRequest
      ) {
        return <NoSearchResults documentsTab={this.props.documentsTab} />;
      } else {
        if (!this.props.loadingFirstRequest) {
          return (
            <Body
              documents={this.props.documents}
              accountSettings={this.props.accountSettings}
              pageSelected={isPageSelected}
              documentsSelected={this.props.documentsSelected}
              showValuesWithVat={filters.showTotalWithIVA}
              updateSelection={this.updateSelection}
              documentsTab={this.props.documentsTab}
              documentsDeselected={this.props.documentsDeselected}
              prevAllDocumentsSelected={this.props.prevAllDocumentsSelected}
            />
          );
        }
      }
    }
  }

  /**
   * Disables loading component timer.
   * @function
   */
  disableLoading() {
    this.setState({ loadingTimerFlag: false });
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    if (prevProps.globalTableKey !== this.props.globalTableKey) {
      this.setState({ loadingTimerFlag: true });
      this.timer = setTimeout(this.disableLoading, LOADING_TIME);
    }
  }

  render() {
    Table.propTypes = {
      documents: PropTypes.array,
      documentsSelected: PropTypes.object,
      documentsDeselected: PropTypes.object,
      allDocumentsSelected: PropTypes.bool,
      prevAllDocumentsSelected: PropTypes.bool,
      filters: PropTypes.object,
      numberOfPages: PropTypes.number,
      accountSettings: PropTypes.object,
      getDocumentsPage: PropTypes.func,
      sortDocumentsByPropertyAndOrder: PropTypes.func,
      setItemsPerPage: PropTypes.func,
      setshowValuesWithVat: PropTypes.func,
      getDocumentTotals: PropTypes.func,
      resetAllFilters: PropTypes.func,
      selectAllDocuments: PropTypes.func,
      deselectAllDocuments: PropTypes.func,
    };

    const {
      loadingFirstRequest,
      loading,
      documents,
      documentsSelected,
      allDocumentsSelected,
      filters,
      accountSettings,
      numberOfPages,
      getDocumentsPage,
      sortDocumentsByPropertyAndOrder,
      setItemsPerPage,
      setshowValuesWithVat,
      resetAllFilters,
      totals,
      openMobileMenu,
      mobileSideContent,
      closeMobileMenu,
      documentsDeselected,
      prevAllDocumentsSelected,
      documentsTab,
    } = this.props;

    const { loadingTimerFlag, sortOptions } = this.state;

    const documentsInformation = {
      documents,
      documentsSelected,
      documentsDeselected,
    };

    const pageSelected = pageManager.pageHasAllDocumentsSelected(
      documentsInformation,
      allDocumentsSelected,
      prevAllDocumentsSelected
    );

    const isLoadingEnabled = pageManager.isLoadingEnabled(
      loading,
      loadingTimerFlag
    );

    return (
      <React.Fragment>
        <div className='row mx-0 mobile-buttons-block'>
          <MobileButtons
            openMobileMenu={openMobileMenu}
            documentsTab={documentsTab}
          />
        </div>
        <React.Fragment>
          <div className='content-block table-options-block'>
            <TableHeader
              globalResetKey={this.props.globalResetKey}
              filters={filters}
              setItemsPerPage={setItemsPerPage}
              setshowValuesWithVat={setshowValuesWithVat}
              sortDocumentsByPropertyAndOrder={sortDocumentsByPropertyAndOrder}
              sortOptions={sortOptions}
              pageSelected={pageSelected}
              selectAllDocuments={this.updateSelection}
              resetAllFilters={resetAllFilters}
              isQueryStringEmpty={isQueryStringEmpty}
              mobileSideContent={mobileSideContent}
              closeMobileMenu={closeMobileMenu}
              documentsTab={documentsTab}
            />
            <MobileSummary totalDocuments={totals.count} />
          </div>
          <React.Fragment>
            <div className='content-block list-table-block'>
              {!accountSettings.first_document && isLoadingEnabled && (
                <div className='loading loading-tables'>
                  <IxLoading />
                  {loadingFirstRequest && <LoadingTable />}
                  {loadingFirstRequest && <LoadingTableMobile />}
                </div>
              )}
              {this.renderTableBody(filters, pageSelected)}
            </div>
            <div className='content-block pagination-block'>
              <Pagination
                page={filters.page}
                numberOfPages={numberOfPages}
                getDocumentsPage={getDocumentsPage}
              />
            </div>
          </React.Fragment>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
