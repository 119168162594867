import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Span from '../../atoms/spans/Span';
import Icon from '../../atoms/icons/Icon';
import {
  formatTotals,
  buildStatusSummaryValues,
  buildDraftAndCanceledSummaryValues,
  buildStatusEstimatesSummaryValues,
} from './util/summaryManager';
import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';
import { LOADING_TIME } from '../../../constants/index';
import { isOnBillingsTab } from '../../templates/helper/documentsTabHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
class SummaryTable extends Component {
  /**
   * @constructor
   * @param {object} props - React props object.
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.buildDraftAndCanceledSummary =
      this.buildDraftAndCanceledSummary.bind(this);
    this.buildStatusEstimatesSummary =
      this.buildStatusEstimatesSummary.bind(this);
    this.buildVATSummary = this.buildVATSummary.bind(this);
    this.buildStatusSummary = this.buildStatusSummary.bind(this);
    this.disableLoading = this.disableLoading.bind(this);
    this.timer = setTimeout(this.disableLoading, LOADING_TIME);
  }

  /**
   * Builds the Draft and Canceled Summary rows in Invoices
   */
  buildDraftAndCanceledSummary = (totals, intl, filters, tab) => {
    let childComponents = [];
    const status = tab === 'Invoices' ? ['draft', 'canceled'] : ['canceled'];

    const classNames = tab === 'Invoices' ? ['first', 'last'] : [''];

    const values = buildDraftAndCanceledSummaryValues(
      totals,
      filters.showTotalWithIVA
    );

    status.map((element, index) => {
      return childComponents.push(
        <div
          key={`${element}-${index}`}
          className={`summary-container --${classNames[index]}`}
        >
          <div className='summary-row --status' id='status-self-row'>
            <div className='summary-left'>
              <div className={`status-label bold status-color-${element}`}>
                <Icon className='icon fas fa-circle' />
                {intl.messages[element]}
              </div>
            </div>
            <div className='summary-right'>
              {tab === 'Invoices' ? (
                <Span label={`${values[index]}`} />
              ) : (
                <Span label={`${values[1]}`} />
              )}
            </div>
          </div>
        </div>
      );
    });
    return childComponents;
  };

  /**
   * Builds the VAT and without VAT Summary rows
   */
  buildVATSummary = (totals, intl) => {
    let childComponents = [];
    const vatOptions = ['without/Vat', 'vat'];
    const values = [totals.total_before_taxes, totals.total_taxes];

    vatOptions.map((element, index) => {
      return childComponents.push(
        <div
          key={`${element}-${index}`}
          id='iva-row'
          className='summary-row --medium'
        >
          <div className='summary-left'>{`${intl.messages[element]}:`}</div>
          <div className='summary-right'>
            <Span label={`${values[index]}`} />
          </div>
        </div>
      );
    });
    return childComponents;
  };

  /**
   * Builds the Status Summary rows in Invoices and Estimates
   */
  buildStatusSummary = (totals, intl, filters, tab) => {
    let childComponents = [];

    const status =
      tab === 'Invoices' || tab === 'Suppliers'
        ? ['due', 'overdue', 'paid', 'corrections']
        : ['due', 'overdue'];
    const values = buildStatusSummaryValues(totals, filters.showTotalWithIVA);

    status.map((element, index) => {
      element = ['due', 'overdue'].includes(element) ? 'final' : 'paid';

      return childComponents.push(
        <div
          key={`${element}-${index}`}
          id='status-row'
          className='summary-row --small'
        >
          <div className='summary-left'>{`${
            intl.messages[status[index]]
          }:`}</div>
          <div className='summary-right'>
            <Span label={`${values[index]}`} />
          </div>
        </div>
      );
    });
    return childComponents;
  };

  /**
   * Builds the Status Summary rows in Estimates
   */
  buildStatusEstimatesSummary = (totals, intl, filters) => {
    let childComponents = [];
    const status = ['draft', 'accepted', 'refused', 'canceled'];
    const classNames = ['first', 'middle', 'middle', 'last'];
    const values = buildStatusEstimatesSummaryValues(
      totals,
      filters.showTotalWithIVA
    );

    status.map((element, index) => {
      return childComponents.push(
        <div
          key={`${element}-${index}`}
          className={`summary-container --${classNames[index]}`}
        >
          <div className='summary-row --status' id='status-self-row'>
            <div className='summary-left'>
              <div className={`status-label bold status-color-${element}`}>
                <Icon className='icon fas fa-circle' />
                {intl.messages[element]}
              </div>
            </div>
            <div className='summary-right'>
              <Span label={`${values[index]}`} />
            </div>
          </div>
        </div>
      );
    });
    return childComponents;
  };

  addLastSummaryRows = (formattedTotals, tab) => {
    switch (tab) {
      case 'Estimates':
        return this.buildStatusEstimatesSummary(
          formattedTotals,
          this.props.intl,
          this.props.filters
        );
      case 'Invoices':
      default:
        return this.buildDraftAndCanceledSummary(
          formattedTotals,
          this.props.intl,
          this.props.filters,
          this.props.documentsTab
        );
    }
  };

  /**
   * Disables loading component.
   * @function
   */
  disableLoading() {
    this.setState({ loading: false });
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    if (prevProps.globalSummaryKey !== this.props.globalSummaryKey) {
      this.setState({ loading: true });
      this.timer = setTimeout(this.disableLoading, LOADING_TIME);
    }
  }

  render() {
    const { loading } = this.state;
    const { accountSettings } = this.props;
    const formattedTotals = formatTotals(
      this.props.totals,
      this.props.accountSettings
    );

    const headerId =
      this.props.documentsNumber === 1 ? 'document' : 'documents';

    return (
      <React.Fragment>
        <div className='summary-table px-0'>
          {!accountSettings.first_document && loading && (
            <div className='loading loading-tables'>
              <IxLoading />
            </div>
          )}
          <div className='summary-title'>
            <Span className='bold' label={this.props.documentsNumber} />
            {this.props.intl.messages[headerId]}
            <Icon className='icon fas fa-arrow-down' />
          </div>
          <div className='summary-container --first'>
            {this.buildStatusSummary(
              formattedTotals,
              this.props.intl,
              this.props.filters,
              this.props.documentsTab
            )}
          </div>
          <div className='summary-container --middle'>
            {this.buildVATSummary(formattedTotals, this.props.intl)}
          </div>
          <div className='summary-container --last'>
            <div className='summary-row --large' id='total-row'>
              <div className='summary-left'>
                {`${this.props.intl.messages['total']}:`}
              </div>
              <div className='summary-right'>
                <Span label={`${formattedTotals.total}`} />
              </div>
            </div>
          </div>
          {!isOnBillingsTab(this.props.documentsTab) &&
            this.addLastSummaryRows(formattedTotals, this.props.documentsTab)}
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SummaryTable);
