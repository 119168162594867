import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import Icon from '../../atoms/icons/Icon';
import { FormattedMessage } from 'react-intl';
import { defaultNumberOfItemsOptions } from '../../templates/invoices/util/filters/defaultFilters';
import {
  clickOutsideDropdown,
  getClassNameForActiveValue,
  buildResultsLabelMessage,
} from './util/filterSelectionHelper';

/**
 * React component
 * @class
 * Dropdown to change how many results should be shown per page.
 */
class SetItemsPerPage extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.state = {
      isVisible: false,
      itemsPerPage: parseInt(this.props.filters.itemsPerPage),
    };

    this.clickOnNumberOfItemsLabel = this.clickOnNumberOfItemsLabel.bind(this);
  }

  /**
   * Handles the click over any anchor element.
   * @function
   * @param {object} e - event.
   */
  clickOnNumberOfItemsLabel = (e) => {
    defaultNumberOfItemsOptions.forEach((option) => {
      if (e.target.id === `${option}_results`) {
        this.setState({
          itemsPerPage: option,
          isVisible: false,
        });
        this.props.setItemsPerPage(option);
      }
    });
  };

  /**
   * Click on dropdown to open or close it.
   * @function
   */
  showNumberOfItemsOptions = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  /**
   * Return an Icon component if the option is selected.
   * @function
   * @param {string} element - unique element id.
   * @param {string} className - element className.
   * @return {object} a check icon component
   */
  addSelectedIconIfActive = (element, className) => {
    if (className === 'selected') {
      return <Icon id={`${element}`} className={'icon fas fa-check'} />;
    }
  };

  /**
   * Creates a child component for each option of number of items.
   * @function
   * @param {string} element - unique element id.
   * @param {string} className - element className.
   * @returns {object} array object with all child components.
   */
  addShowResultOptions = () => {
    let childComponents = [];
    defaultNumberOfItemsOptions.map((element) => {
      const className = getClassNameForActiveValue(
        this.state.itemsPerPage,
        element
      );
      return childComponents.push(
        <li key={`${element}_results`}>
          {this.addSelectedIconIfActive(element, className)}
          <Anchor
            id={`${element}_results`}
            className={className}
            onClick={this.clickOnNumberOfItemsLabel}
          >
            <FormattedMessage
              id='showResults'
              values={{
                itemsPerPage: element,
              }}
            />
          </Anchor>
        </li>
      );
    });
    return childComponents;
  };

  /**
   * Hanlde click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps, prevState) {
    //Update state when filters have updated
    if (prevProps.filters.itemsPerPage !== this.props.filters.itemsPerPage) {
      this.setState({
        itemsPerPage: parseInt(this.props.filters.itemsPerPage),
      });
    }
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const { isVisible, itemsPerPage } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';

    return (
      <div className={`table-options-dropdown js-search-filter ${cssClass}`}>
        <div
          data-testid='set-items-per-page'
          className='header'
          ref={this.headerRef}
          onClick={this.showNumberOfItemsOptions}
        >
          <div className='label'>
            {buildResultsLabelMessage(
              itemsPerPage,
              this.props.intl.messages['itemsPerPage']
            )}
          </div>
        </div>
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <ul className='bold'>{this.addShowResultOptions()}</ul>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SetItemsPerPage);
