import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MobileMenu from '../../../organisms/containers/mobile/MobileMenu';
import TableSimple from '../../../organisms/tables/TableSimple';
import SearchBox from '../../../organisms/containers/schedules/SearchBox';
import { getAccountSettings } from '../../../../test/data/account';
import { usersSortOptions } from '../../invoices/util/filters/defaultFilters';
import * as queryStringManager from '../../util/api/queryStringManager';
import {
  closeMobile,
  openMobile,
} from '../../helper/documentInformationHelper';
import { UserService } from '../../../../services/UserService';
import EditUserModal from './edit/EditUserModal';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import ToolBar from '../../../organisms/toolbar/ToolBar';
import { AppContext } from '../../../../contexts/AppContext';
import { toBoolean } from '../../../../formatters/string';
import TableContainer from '../../../organisms/containers/table-container/TableContainer';

const defaultOrdenation = {
  sort: 'id',
  sort_order: 'asc',
  text: '',
};

const ManageUsersLayout = (props) => {
  const { appState } = useContext(AppContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [mobileSideContent, setMobileSideContent] = useState('');
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [globalResetKey, setResetGlobalKey] = useState(0);
  const [loadingFirstRequest, setLoadingFirstRequest] = useState(true);
  const [filters, setFilters] = useState({
    total_entries: 1,
    current_page: 1,
    page: 1,
    total_pages: 0,
    per_page: 10,
  });
  const [ordenation, setOrdenation] = useState(defaultOrdenation);
  const [globalKeys, setGlobalKeys] = useState({
    key: 1,
    resetKey: 1,
  });
  const [currentUser, setCurrentUser] = useState({});
  const [usersIdsSelected, setUsersIdsSelected] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(true);

  /**
   * Close modal
   * @function
   */
  const handleCloseModal = () => {
    setIsUpdated(true);
    setShowModal(false);
    setIsEditMode(false);
  };

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  const resetAllFilters = () => {
    setUsersIdsSelected([]);
    setResetGlobalKey(globalResetKey + 1);
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      filters.page
    );
    queryStringManager.clearQueryString(
      props.windowLocation,
      queryStringWithPage
    );
    setOrdenation(defaultOrdenation);
    getDocumentsPage(1);
  };

  /**
   * Get all clients
   *
   * @async
   * @param {number} page
   * @param {function} handleError
   * @returns {object}
   */
  const fetchAllUsers = async () => {
    const apiFilters = {
      page: filters.current_page,
      items_per_page: filters.per_page,
      sort: ordenation.sort || 'email',
      sort_order: ordenation.sort_order,
    };

    if (ordenation.text) {
      apiFilters.term = ordenation.text;
    }

    const params = new URLSearchParams(apiFilters).toString();

    return await UserService.getAll(params);
  };

  const updateSearchParams = (key, value) => {
    queryStringManager.updateQueryStringParam(key, value, props.windowLocation);
  };

  const handleSearchInput = (text) => {
    setUsersIdsSelected([]);
    setOrdenation({
      ...ordenation,
      text: text,
    });
    updateSearchParams('text', text);
    getDocumentsPage(1);
  };

  const sortTable = (name, order) => {
    setOrdenation({
      ...ordenation,
      sort: name,
      sort_order: order || 'asc',
    });

    updateSearchParams('sort', name);
    updateSearchParams('sort_order', order || 'asc');
    setIsUpdated(true);
  };

  const setItemsPerPage = (itemsPerPage) => {
    setFilters({
      ...filters,
      per_page: itemsPerPage,
    });
    setGlobalKeys({ ...globalKeys, key: globalKeys.key + 1 });
    setIsUpdated(true);

    updateSearchParams('per_page', itemsPerPage);
  };

  /**
   * Update document by page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  const getDocumentsPage = async (nextPage) => {
    setFilters({
      ...filters,
      page: nextPage,
      current_page: nextPage,
    });
    updateSearchParams('page', nextPage);
    setIsUpdated(true);
  };

  /**
   * Fetch document by page
   */
  const fetchDocument = async () => {
    const response = await fetchAllUsers();
    if (!response.error) {
      setUsers(response?.users);
      setFilters({
        ...response?.pagination,
        page: response?.pagination?.current_page,
      });
    }

    setIsUserAdmin(toBoolean(appState.isUserAdmin));
    setLoading(false);
  };

  /**
   *  Delete documents
   */
  const handleDelete = async () => {
    setLoading(true);
    const response = await UserService.deleteAll(usersIdsSelected);
    if (!response.error) {
    }
    setLoading(false);
    setIsUpdated(true);
    setUsersIdsSelected([]);
  };

  const handleSelectedLines = (id) => {
    let filteredLines = usersIdsSelected.filter((item) => item !== id);

    if (filteredLines.length === usersIdsSelected.length) {
      filteredLines.push(id);
    }

    setUsersIdsSelected(filteredLines);
  };

  useEffect(() => {
    if (loadingFirstRequest) {
      getDocumentsPage(1);
      fetchDocument();
    }
    setLoadingFirstRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFirstRequest]);

  useEffect(() => {
    if (isUpdated) {
      fetchDocument();
    }
    setIsUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const defaultAccountSettings = getAccountSettings('EUR', 'auto');

  return (
    <>
      <TableContainer
        className='--contact-list'
        message={<FormattedMessage id='manageUsersTitle' />}
        searchBox={
          <SearchBox
            key={globalResetKey}
            filters={filters}
            getDocumentsByTextInput={handleSearchInput}
            searchByPlugins={() => {}}
            documentsTab={props.documentsTab}
            placeholder={'searchBarPlaceholderManageUsers'}
            newLayout
            handleButtonClick={() => {
              setShowModal(true);
              setCurrentUser({});
            }}
            isButtonDisabled={!isUserAdmin}
            buttonLabel={'newUserLabel'}
          />
        }
        table={
          <TableSimple
            id='listUsers'
            data-testid='manage-user-table-simple'
            globalResetKey={globalKeys.resetKey}
            globalTableKey={globalKeys.key}
            userId={appState.rawUser}
            loading={loading}
            loadingFirstRequest={loadingFirstRequest}
            documents={users || []}
            filters={filters}
            numberOfPages={filters.total_pages}
            accountSettings={defaultAccountSettings}
            getDocumentsPage={getDocumentsPage}
            sortDocumentsByPropertyAndOrder={sortTable}
            setItemsPerPage={setItemsPerPage}
            sortOptions={usersSortOptions}
            resetAllFilters={resetAllFilters}
            documentsTab={props.documentsTab}
            openMobileMenu={(sideContent) =>
              openMobile(setShowMobileMenu, setMobileSideContent, sideContent)
            }
            mobileSideContent={mobileSideContent}
            closeMobileMenu={() =>
              closeMobile(setShowMobileMenu, setMobileSideContent)
            }
            openModal={(users) => {
              setIsEditMode(true);
              setCurrentUser(users);
              setShowModal(true);
            }}
            setSelectedLines={handleSelectedLines}
            selectedLines={usersIdsSelected}
          />
        }
        rightMenu={<Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />}
        toolbar={
          <ToolBar
            accountId={appState.accountId}
            documents={[]}
            userId={appState.rawUser}
            userEmail={appState.userEmail}
            language={appState.language}
            filters={filters}
            accountSettings={defaultAccountSettings}
            windowLocation={props.windowLocation}
            documentsSelected={{
              usersIdsSelected,
              size: usersIdsSelected.length,
            }}
            documentsDeselected={[]}
            allDocumentsSelected={false}
            prevAllDocumentsSelected={false}
            numberOfDocuments={0}
            documentsNumber={usersIdsSelected.length}
            selectionAmount={0}
            totalDocuments={0}
            clearSelection={() => {}}
            selectAllDocuments={() => {}}
            isPerformingBulk={false}
            hasBulkFinished={false}
            documentsTab={props.documentsTab}
            handleDelete={handleDelete}
          />
        }
        mobileMenu={
          <>
            <MobileMenu
              showMobileMenu={showMobileMenu}
              closeMobileMenu={() =>
                closeMobile(setShowMobileMenu, setMobileSideContent)
              }
              sideContent={mobileSideContent}
            />

            {showModal && (
              <EditUserModal
                onCloseClick={handleCloseModal}
                isEditMode={isEditMode}
                currentUser={currentUser}
              />
            )}
          </>
        }
      />
      {/*
      <div id='main-content' className='container --contact-list'>
        <div className='row justify-content-center'>
          <div className='col-10 col-lg-9'>
            <div className='container justify-content-center'>
              <div className='row content-block advanced-search-block'>
                <div className='row title-block'>
                  <div className='col-9 text-header h2'>
                    <FormattedMessage id='manageUsersTitle' />
                  </div>
                </div>

                <div className='row content-block advanced-search-block'>
                  <SearchBox
                    key={globalResetKey}
                    filters={filters}
                    getDocumentsByTextInput={handleSearchInput}
                    searchByPlugins={() => {}}
                    documentsTab={props.documentsTab}
                    placeholder={'searchBarPlaceholderManageUsers'}
                    newLayout
                    handleButtonClick={() => {
                      setShowModal(true);
                      setCurrentUser({});
                    }}
                    buttonLabel='newUserLabel'
                    isButtonDisabled={!isUserAdmin}
                  />
                </div>

                <TableSimple
                  id='listUsers'
                  data-testid='manage-user-table-simple'
                  globalResetKey={globalKeys.resetKey}
                  globalTableKey={globalKeys.key}
                  userId={appState.rawUser}
                  loading={loading}
                  loadingFirstRequest={loadingFirstRequest}
                  documents={users || []}
                  filters={filters}
                  numberOfPages={filters.total_pages}
                  accountSettings={defaultAccountSettings}
                  getDocumentsPage={getDocumentsPage}
                  sortDocumentsByPropertyAndOrder={sortTable}
                  setItemsPerPage={setItemsPerPage}
                  sortOptions={usersSortOptions}
                  resetAllFilters={resetAllFilters}
                  documentsTab={props.documentsTab}
                  openMobileMenu={(sideContent) =>
                    openMobile(
                      setShowMobileMenu,
                      setMobileSideContent,
                      sideContent
                    )
                  }
                  mobileSideContent={mobileSideContent}
                  closeMobileMenu={() =>
                    closeMobile(setShowMobileMenu, setMobileSideContent)
                  }
                  openModal={(users) => {
                    setIsEditMode(true);
                    setCurrentUser(users);
                    setShowModal(true);
                  }}
                  setSelectedLines={handleSelectedLines}
                  selectedLines={usersIdsSelected}
                />
              </div>
              <ToolBar
                accountId={appState.accountId}
                documents={[]}
                userId={appState.rawUser}
                userEmail={appState.userEmail}
                language={appState.language}
                filters={filters}
                accountSettings={defaultAccountSettings}
                windowLocation={props.windowLocation}
                documentsSelected={{
                  usersIdsSelected,
                  size: usersIdsSelected.length,
                }}
                documentsDeselected={[]}
                allDocumentsSelected={false}
                prevAllDocumentsSelected={false}
                numberOfDocuments={0}
                documentsNumber={usersIdsSelected.length}
                selectionAmount={0}
                totalDocuments={0}
                clearSelection={() => {}}
                selectAllDocuments={() => {}}
                isPerformingBulk={false}
                hasBulkFinished={false}
                documentsTab={props.documentsTab}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          <div className='col-lg-3 mt-4'>
            <Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />
          </div>
          <MobileMenu
            showMobileMenu={showMobileMenu}
            closeMobileMenu={() =>
              closeMobile(setShowMobileMenu, setMobileSideContent)
            }
            sideContent={mobileSideContent}
          />

          {showModal && (
            <EditUserModal
              onCloseClick={handleCloseModal}
              isEditMode={isEditMode}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>*/}
    </>
  );
};

export default injectIntl(ManageUsersLayout);
