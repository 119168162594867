import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ADMINISTRATOR } from '../../../../../constants/user_roles';
import { validateEmailFormat } from '../../../../../helpers/validateFormatHelper';
import { UserService } from '../../../../../services/UserService';
import Input from '../../../../atoms/inputs/Input';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import Modal from '../../../../organisms/modals/Modal';
import { GlobalsHelpers } from '../../../helper/globals';

const EditUserModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    email: props.currentUser?.email,
    role: props.currentUser?.role || ADMINISTRATOR,
  });
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [errors, setRequestErrors] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  /**
   * Verify if login is valid
   * @param {string} login
   */
  const verifyEmail = (login) => {
    setIsEmailInvalid(!validateEmailFormat(login));
  };

  /**
   * Handle fields state
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  const handleFormFields = async (key, value) => {
    const updatedForm = {
      ...formFields,
      [key]: value,
    };
    setFormFields(updatedForm);
  };

  const isRequiredFieldsFilled = () => {
    return formFields.email && formFields.role && !isEmailInvalid;
  };

  const handleSubmit = async () => {
    setShowAlert(false);

    setIsLoading(true);

    const response = props.isEditMode
      ? await UserService.update({
          ...formFields,
          userId: props.currentUser?.id,
        })
      : await UserService.create({ ...formFields });

    if (!response.error) {
      props.onCloseClick();

      setIsLoading(false);
      return;
    }

    setRequestErrors(response.error);
    setShowAlert(true);
  };

  return (
    <Modal
      titleKey={props.isEditMode ? 'editUserLabel' : 'newUserLabel'}
      isLoading={isLoading}
      iconName={false}
      alert={{
        show: showAlert,
        errors,
      }}
      actions={{
        submit: {
          label: 'save',
          onClick: handleSubmit,
          isDisabled: !isRequiredFieldsFilled(),
        },
        cancel: {
          onClick: props.onCloseClick,
        },
      }}
    >
      <div className='col-12'>
        <label className='text-label mt-3'>
          {props.intl.messages['email']}*
        </label>
        <Input
          id='email'
          type='text'
          value={formFields?.email || ''}
          onChange={(e) => handleFormFields('email', e.target.value)}
          onBlur={(e) => verifyEmail(e.target.value)}
          hasError={isEmailInvalid}
        />
      </div>

      <div className='col-12'>
        <label className='text-label mt-3'>
          {props.intl.messages['profile']}*
        </label>
        <SelectWithValues
          key='profile'
          id='profile'
          value={formFields?.role}
          options={GlobalsHelpers.getUsersRoleList(props.intl)}
          onChange={(e) => {
            handleFormFields('role', e.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default injectIntl(EditUserModal);
