const parseAddressObject = (address) => {
  if (!address)
    return {
      street: '',
      city: '',
      postal_code: '',
    };
  const addressString = address.trim();

  const postalCodeRegex = /(\b\d{4}-\d{3}\b)\s*([\p{L}\s-]+)?/u;

  const lines = addressString.split('\n').filter((line) => line.trim() !== '');

  let street = '';
  let city = '';
  let postal_code = '';

  for (const line of lines) {
    const match = line.match(postalCodeRegex);
    if (match) {
      postal_code = match[1];
      city = match[2]?.trim() || '';
      break;
    }
    if (!street) {
      street = line.trim();
    }
  }

  return { street, city, postal_code };
};

export const BillingDataHelper = { parseAddressObject };
