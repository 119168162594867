import { injectIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import Input from '../../../atoms/inputs/Input';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import { validateEmailFormat } from '../../../../helpers/validateFormatHelper';

const CompanyInformation = (props) => {
  const { email, website, phoneCountry, phone } = props.state;

  const [emailInput, setEmailInput] = useState(email);

  const [invalidEmail, setInvalidEmail] = useState(false);

  const setHandler = (key, value) => {
    props.handler({
      ...props.state,
      [key]: value,
    });

    if (key !== 'email') {
      props.setUserChangedInput(true);
    }
  };

  useEffect(() => {
    setEmailInput(email);
  }, [email]);

  useEffect(() => {
    if (!props.userChangedInput) {
      setInvalidEmail(false);
    }
  }, [props.userChangedInput]);

  const verifyEmail = (value) => {
    if (!value) {
      props.setUserChangedInput(true);
      props.removeInputErrorHandler('email');
      setInvalidEmail(false);
      return;
    }
    if (!validateEmailFormat(value)) {
      props.setUserChangedInput(true);
      props.addInputErrorHandler('email');
      setInvalidEmail(true);
    } else {
      props.setUserChangedInput(true);
      props.removeInputErrorHandler('email');
      setInvalidEmail(false);
    }
  };

  return (
    <div className='row px-0 mx-0 content-block options-block'>
      <div className='text-header h3 section-title'>
        {props.intl.messages['companyContacts']}
      </div>
      <div className='form-container px-0'>
        <div className='form-row row px-0 mx-0'>
          <div className='col-sm-6 ps-0'>
            <label className='text-label'>
              {props.intl.messages['email']}:
            </label>
            <Input
              id='email_input'
              type='text'
              value={emailInput}
              onChange={(e) => setHandler('email', e.target.value)}
              onBlur={(e) => verifyEmail(e.target.value)}
              hasError={invalidEmail}
              placeholder='exemplo@email.com'
            />
          </div>
          <div className='col-sm-6 pe-0'>
            <label className='text-label'>
              {props.intl.messages['website']}:
            </label>
            <Input
              id='website_input'
              type='text'
              value={website}
              onChange={(e) => setHandler('website', e.target.value)}
              placeholder='exemplo.pt'
            />
          </div>
        </div>
        <label className='text-label'>
          {props.intl.messages['telephone']}:
        </label>
        <div className='form-row dependent row px-0 mx-0'>
          <div className='col-sm-6 ps-0'>
            <SelectWithValues
              id='phone_codes_select'
              value={phoneCountry}
              keyName='phone_country_id'
              valueName='phone_code'
              options={props.phoneCountryCodes}
              onChange={(e) => setHandler('phoneCountry', e.target.value)}
            />
          </div>
          <div className='col-sm-6 pe-0'>
            <Input
              id='phone_number_input'
              type='text'
              value={phone}
              onChange={(e) => setHandler('phone', e.target.value)}
              placeholder='123456789'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CompanyInformation);
