import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Button from '../../../atoms/buttons/Button';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';
import { ExportDataHelper } from './helper';
import { SettingService } from '../../../../services/SettingService';
import HandleCallResultModal from '../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const ExportDataLayout = (props) => {
  const defaultFormData = ExportDataHelper.getInitialState(props.intl);
  const dataExportTypes = ExportDataHelper.getDataExportTypes(props.intl);
  const [form, setForm] = useState(defaultFormData);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);

  useEffect(() => {}, []);

  const onFormChange = (key, value) => setForm({ ...form, [key]: value });

  const onSubmit = async () => {
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('exportDataProcessing');
    const data = structuredClone(form);

    if (data.exportSelector === 'accountData') {
      data.exportDataType = 'account';
      data.exportFormat = 'xml';
    }

    const response = await SettingService.exportData(
      data.exportDataType,
      data.exportFormat
    );
    setSubmitting(false);

    if (!response.error) {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.message);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(true);
    } else {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.error);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(false);
    }
  };

  return (
    <div id='main-content' className='container --export-data'>
      <div className='d-flex flex-wrap row mx-0 w-100'>
        <div
          data-testid='export-data-layout'
          className='col-12 col-lg-9 ps-md-0'
        >
          <div className='d-flex flex-column w-100'>
            <div className='col-lg-10 px-0'>
              <Paragraph className='text-header h2'>
                {props.intl.messages['exportDataTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {props.intl.messages['exportDataDescription']}
                </Span>
              </Paragraph>
            </div>

            <div className='sep-light mt-2' />

            <div className='form-container d-flex flex-column w-100 mt-5'>
              <div className='d-flex flex-column w-100'>
                <label className='text-label mb-3'>
                  {props.intl.messages['exportDataSelectionsLabel']}
                </label>
                <div className='d-flex w-100'>
                  <div className='option-container'>
                    <RadioButtonLabel
                      id='contactsDocsAndItems'
                      name='contactsDocsAndItems'
                      className='checkbox round'
                      value={'contactsDocsAndItems'}
                      checked={form?.exportSelector === 'contactsDocsAndItems'}
                      onChange={(e) => {
                        onFormChange('exportSelector', e.target.value);
                      }}
                    >
                      {'exportDataContactAndDocsLabel'}
                    </RadioButtonLabel>
                  </div>

                  <div className='option-container ms-3'>
                    <RadioButtonLabel
                      id='accountData'
                      name='accountData'
                      className='checkbox round'
                      value={'accountData'}
                      checked={form?.exportSelector === 'accountData'}
                      onChange={(e) => {
                        onFormChange('exportSelector', e.target.value);
                      }}
                    >
                      {'exportDataAccountLabel'}
                    </RadioButtonLabel>
                  </div>
                </div>
              </div>
              {form?.exportSelector === 'contactsDocsAndItems' && (
                <div
                  data-testid='data-type-selectors'
                  className='d-flex flex-column flex-sm-row col-lg-10 px-0 mt-5 period-selector-container'
                >
                  <div className='col period-selector'>
                    <label className='text-label mb-3'>
                      {props.intl.messages['exportDataDataTypeLabel']}
                    </label>
                    <SelectWithValues
                      id='dataTypeSelector'
                      key='dataType'
                      options={dataExportTypes}
                      value={form.exportDataType}
                      onChange={(e) => {
                        onFormChange('exportDataType', e.target.value);
                      }}
                    />
                  </div>
                  <div className='col period-selector'>
                    <label className='text-label mb-3'>
                      {props.intl.messages['exportDataFormatLabel']}
                    </label>
                    <SelectWithValues
                      id='formatSelector'
                      key='format'
                      options={ExportDataHelper.FORMAT}
                      value={form.exportFormat}
                      onChange={(e) => {
                        onFormChange('exportFormat', e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex col-12 col-lg-10 px-0 mt-4'>
              <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                <div
                  data-testid='export_data_disclaimer'
                  className='d-flex w-100 export-disclaimer me-4 mb-4 my-sm-auto'
                >
                  {form?.exportSelector === 'contactsDocsAndItems' && (
                    <span className='text-paragraph'>
                      <FormattedMessage
                        id='exportDataContactAndDocsInfo'
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </span>
                  )}

                  {form?.exportSelector === 'accountData' && (
                    <span className='text-paragraph'>
                      <FormattedMessage
                        id='exportDataAccountInfo'
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </span>
                  )}
                </div>

                <Button
                  className='button button-primary ms-auto ms-sm-0'
                  id={'save_button'}
                  label={props.intl.messages['exportDataButton']}
                  onClick={onSubmit}
                  disabled={submitting}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-3 pe-md-0 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />
        </div>
      </div>

      {isSubmitted && (
        <HandleCallResultModal
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton={true}
        />
      )}
    </div>
  );
};

export default injectIntl(ExportDataLayout);
