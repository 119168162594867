/**
 * APP constants - External request (v3)
 */

import {
  v3PathBase,
  v2PathBase,
  apiV3PathBase,
  v2AuthPathBase,
} from '../config/env.config.js';

export const V3_PATH_BASE = v3PathBase();
export const V2_PATH_BASE = v2PathBase();
// TODO: Session it's not managed by react. Remove this in the future
export const V2_AUTH_PATH_BASE = v2AuthPathBase();
export const API_V3_PATH_BASE = apiV3PathBase();

export const INVOICES_PATH = 'api/v3/invoices';
export const ESTIMATES_PATH = 'api/v3/estimates';
export const GUIDES_PATH = 'api/v3/guides';
export const SUPPLIERS_PATH = 'api/v3/suppliers';
export const SCHEDULES_PATH = 'api/v3/schedules';
export const SEQUENCE_PATH = 'api/v3/sequences/by_name';
export const SEQUENCES_PATH = 'api/v3/sequences';
export const SEQUENCES_COLLECTIONS_PATH = 'api/v3/sequences/collection';
export const CLIENTS_PATH = 'api/v3/clients/suggestions';
export const BULK_PATH = 'api/v1/bulks';

export const REGISTERED_SERIES = `api/v3/sequences/registered_series.json`;
export const DOCUMENT_CUSTOMIZATION = (accountId) =>
  `accounts/${accountId}/document_customization`;
export const CUSTOMIZE_CLEAR_LOGO = (accountId) =>
  `accounts/${accountId}/clear_logo`;
export const PHONE_LIST = `api/v3/phone_calling_codes.json`;

export const COUNTRIES = `api/v3/tax_countries`;
export const CURRENCIES = `api/v3/currencies`;

export const ACCOUNTS_PATH = 'accounts';
export const MANAGE_PLAN_PATH = 'accounts/manage_plan';

export const AT_ACTIVE_COMMUNICATION_PATH =
  'api/v3/accounts/at_active_communication';
export const AT_COMMUNICATION_DETAILS_PATH = `${API_V3_PATH_BASE}api/v3/account_credentials`;

export const DEFAULT_FAVORITES_PATH = 'api/v3/favorites/default';
export const USER_FAVORITES_PATH = 'api/v3/favorites/all';
export const NEW_FAVORITE_PATH = 'api/v3/favorites';
export const FAVORITE_PATH = 'api/v3/favorites';

export const PDF_PATH = 'pdf/start';
export const ACCOUNT_SETTINGS_PATH = 'api/v3/accounts/settings';

export const LOADING_TIME = 600;
export const LONG_LOADING_TIME = 900;
export const REQUEST_DELAY = 3000;
export const BULK_DELAY = 5000;
export const BULK_SYNC_LIMIT = 50;
export const BULK_ALERT_INFO_LEVEL = 300;
export const BULK_ALERT_WARNING_LEVEL = 1000;
export const BULK_IDS_ENABLED_FOR_ALERT = [
  'archiveDocument',
  'unarchiveDocument',
];

/**
 * Maximum number of documents that each bulk action supports.
 */
export const BULK_SELECTION_MAX_LIMIT = {
  archiveDocument: 10000,
  unarchiveDocument: 10000,
  deleteDocument: 1000,
  finalizeDocument: 100,
  paymentReceipt: 100,
  downloadPDF: 1000,
  exportCSV: 55000,
  sendByEmail: 1000,
};

/**
 * Bulk Actions Identifiers that each operation uses as setup.
 */
export const BULK_IDENTIFIERS = {
  exportCSV: 'exportCSV',
  sendByEmail: 'sendByEmail',
};

/**
 * Represents the threshold, of documents, at which an account is considered small.
 * Why? After performing requests to the Summary endpoint, this is the value at which
 * the response has the same time delay as the animation on the table!
 * Considering values higher than this, would degrade the user experience.
 */
export const ACCOUNT_THRESHOLD = 30000;

/**
 * Represents the percentage at which we could send the except_id parameter on the request,
 * passed the 40% percent of the total volume of the account, the benefits from this approach are
 * marginal
 */
export const EXCEPT_ID_THRESHOLD = 0.4;

/**
 * Requests to accounts endpoint
 */
export const FETCH_ACCOUNTS_PATH = `${V3_PATH_BASE}${ACCOUNTS_PATH}`;

/**
 * Get document paths
 */
export const FETCH_DOCUMENTS_PATHS = {
  invoices: `${API_V3_PATH_BASE}${INVOICES_PATH}`,
  estimates: `${API_V3_PATH_BASE}${ESTIMATES_PATH}`,
  guides: `${API_V3_PATH_BASE}${GUIDES_PATH}`,
  suppliers: `${API_V3_PATH_BASE}${SUPPLIERS_PATH}`,
  billings: `${API_V3_PATH_BASE}${SUPPLIERS_PATH}`,
  schedules: `${API_V3_PATH_BASE}${SCHEDULES_PATH}`,
  sequences: `${API_V3_PATH_BASE}${SEQUENCES_PATH}`,
};

/**
 * Get updated Summary
 */
export const SUMMARY_PATH = {
  invoices: 'api/v3/invoices/summary',
  estimates: 'api/v3/estimates/summary',
  suppliers: 'api/v3/suppliers/summary',
  guides: 'api/v3/guides/summary',
};

/**
 * Number of allowed characters in the open search box
 */
export const SEARCH_BOX_LENGTH_LIMIT = 300;

/**
 * Invoices
 */
export const DOCUMENT_PATHS = {
  invoice: 'invoices',
  invoiceReceipt: 'invoice_receipts',
  simplifiedInvoice: 'simplified_invoices',
  cashInvoice: 'cash_invoices',
  debitNote: 'debit_notes',
  vatMossInvoice: 'vat_moss_invoices',
  creditNote: 'credit_notes',
  vatMossCreditNote: 'vat_moss_credit_notes',
  receipt: 'receipts',
  vatMossReceipt: 'vat_moss_receipts',
};

export const CREATE_DOCUMENT_PATHS = {
  createNewInvoice: `${DOCUMENT_PATHS['invoice']}/new`,
  newInvoiceReceipt: `${DOCUMENT_PATHS['invoiceReceipt']}/new`,
  newSimplifiedInvoice: `${DOCUMENT_PATHS['simplifiedInvoice']}/new`,
  newInvoiceBatch: 'import_invoice/upload',
};

export const INVOICES_BULK_ACTIONS_PATH = {
  archiveDocument: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/archive`,
  unarchiveDocument: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/unarchive`,
  deleteDocument: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/delete`,
  downloadPDF: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/download_pdf`,
  exportCSV: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/export`,
  sendByEmail: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/email`,
  finalizeDocument: `v3/${DOCUMENT_PATHS['invoice']}/bulk_finalize`,
  paymentReceipt: `v3/${DOCUMENT_PATHS['invoice']}/bulk_settle`,
};

export const CHECK_STATUS_PATH = {
  finalizeDocument: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/status_finalize`,
  paymentReceipt: `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/status_settle`,
};

export const CHECK_INVOICES_WITHOUT_EMAIL_PATH = `${BULK_PATH}/${DOCUMENT_PATHS['invoice']}/documents_without_email`;

export const ID_TO_FORMAT = {
  microsoftFormat: 'excel',
  appleFormat: 'numbers',
  googleFormat: 'google_sheets',
  jsonFormat: 'json',
};

/**
 * Estimates
 */
export const ESTIMATE_PATHS = {
  quote: 'quotes',
  proforma: 'proformas',
  feesNote: 'fees_notes',
};

export const CREATE_ESTIMATE_PATHS = {
  newQuote: `${ESTIMATE_PATHS['quote']}/new`,
  newProforma: `${ESTIMATE_PATHS['proforma']}/new`,
  newFeesNote: `${ESTIMATE_PATHS['feesNote']}/new`,
};

export const ESTIMATES_BULK_ACTIONS_PATH = {
  archiveDocument: `${BULK_PATH}/estimates/archive`,
  unarchiveDocument: `${BULK_PATH}/estimates/unarchive`,
  deleteEstimates: `${BULK_PATH}/estimates/delete`,
  downloadPDF: `${BULK_PATH}/estimates/download_pdf`,
  exportCSV: `${BULK_PATH}/estimates/export`,
  sendByEmail: `${BULK_PATH}/estimates/email`,
};

export const CHECK_ESTIMATES_WITHOUT_EMAIL_PATH = `${BULK_PATH}/estimates/documents_without_email`;

/**
 * Guides
 */
export const GUIDE_PATHS = {
  transport: 'transports',
  shipping: 'shippings',
  devolution: 'devolutions',
};

export const CREATE_GUIDE_PATHS = {
  createNewDeliveryNote: `${GUIDE_PATHS['transport']}/new`,
  newShippingNote: `${GUIDE_PATHS['shipping']}/new`,
  newReturnDeliveryNote: `${GUIDE_PATHS['devolution']}/new`,
};

export const GUIDES_BULK_ACTIONS_PATH = {
  archiveDocument: `${BULK_PATH}/guides/archive`,
  unarchiveDocument: `${BULK_PATH}/guides/unarchive`,
  deleteGuides: `${BULK_PATH}/guides/delete`,
  downloadPDF: `${BULK_PATH}/guides/download_pdf`,
  exportCSV: `${BULK_PATH}/guides/export`,
  sendByEmail: `${BULK_PATH}/guides/email`,
};

export const CHECK_GUIDES_WITHOUT_EMAIL_PATH = `${BULK_PATH}/guides/documents_without_email`;

/**
 * Schedules
 */

export const SCHEDULES_PATHS = {
  schedule: 'schedules',
};

export const CREATE_SCHEDULES_PATHS = {
  createNewSchedule: `${SCHEDULES_PATHS['schedule']}/new`,
};

const SET_DEFAULT_SEQUENCE_PATH = 'api/v3/sequences/default';
const REGISTER_SEQUENCE_PATH = 'api/v3/sequences/register';

export const SEQUENCES_ACTIONS_PATH = {
  create: `${API_V3_PATH_BASE}${SEQUENCES_PATH}`,
  setDefault: `${API_V3_PATH_BASE}${SET_DEFAULT_SEQUENCE_PATH}`,
  register: `${API_V3_PATH_BASE}${REGISTER_SEQUENCE_PATH}`,
};

/**
 * REDIRECT LINKS
 */
export const REDIRECT_TO = {
  invoices: 'v3/invoices',
  sequences: 'v3/sequences',
};

/**
 * IX LABS
 */
const LABS_PATH = 'labs';

export const LABS_FEATURES_PATH = {
  list: `${V2_PATH_BASE}${LABS_PATH}`,
  atcud: `${V2_PATH_BASE}${LABS_PATH}/atcud`,
};

/**
 * Links used to give deeper knowledge on the
 * developed features
 */
export const KNOWLEDGE_BASE_LABS_LINKS = {
  atcud:
    'https://invoicexpress.helpscoutdocs.com/article/202-atcud-indice-completo',
  ixLabs:
    'https://invoicexpress.helpscoutdocs.com/article/215-invoicexpress-labs',
};

/**
 * Links to help users with ATCUD/Sequences management
 */
export const KNOWLEDGE_BASE_ATCUD_LINKS = {
  atcud: 'https://invoicexpress.helpscoutdocs.com/category/200-atcud',
  atconfiguration:
    'https://invoicexpress.helpscoutdocs.com/article/212-atcud-guia-passo-a-passo-para-criar-e-registar-series',
  register:
    'https://invoicexpress.helpscoutdocs.com/article/210-quais-os-estados-do-registo-da-serie',
};

/**
 * Home
 */
export const HOME_PATH = 'home';

/**
 * HELP LINKS
 */
export const NEW_INVOICE_VIDEO = 'https://www.youtube.com/watch?v=LUZn4JZEUQ4';
export const NEW_INVOICE_ARTICLE =
  'https://invoicexpress.helpscoutdocs.com/category/73-facturas';
export const NEW_ESTIMATE_VIDEO = 'https://www.youtube.com/watch?v=S5ksdZgfY64';
export const NEW_ESTIMATE_ARTICLE =
  'https://invoicexpress.helpscoutdocs.com/category/114-orcamentos';

/**
 * IE Warning links
 */
export const IE_SUPPORT_ARTICLE =
  'https://www.microsoft.com/pt-pt/microsoft-365/windows/end-of-ie-support';
export const GOOGLE_CHROME_DOWNLOAD =
  'https://www.google.com/intl/pt-PT/chrome/';
export const SAFARI_DOWNLOAD =
  'https://support.apple.com/pt_PT/downloads/safari';
export const FIREFOX_DOWNLOAD = 'https://www.mozilla.org/en-US/firefox/new/';
export const SUPPORT_EMAIL = 'mailto:support@invoicexpress.com';
export const IE_SUPPORT_IMAGE =
  'https://invoicexpress-assets.s3-eu-west-1.amazonaws.com/wall-of-death.jpg';

/**
 * IX WEBSITE
 */
export const IX_WEBSITE = 'https://invoicexpress.com';

/**
 * Bulk Email: Font Sizes
 */
export const customStyleMap = {
  FONT_SIZE_10: {
    fontSize: '10px',
  },
  FONT_SIZE_14: {
    fontSize: '14px',
  },
  FONT_SIZE_18: {
    fontSize: '18px',
  },
  FONT_SIZE_24: {
    fontSize: '24px',
  },
  BOLD: {
    fontFamily: 'Oxygen-bold',
  },
};

/**
 * Guides License Plate PlaceHolder
 *
 * When a Guide's License Plate is empty, it should be modified by this value
 */
export const EMPTY_LICENSE_PLATE = '-- -- --';

/**
 * Demo Sequence Name
 *
 * When the user chose to just experiment without configure ATCUD, this sequence is created
 */
export const DEMO_ACCOUNT_SEQUENCE = 'INVOICEXPRESSDEMO';

/**
 * Contact Page
 *
 * List of documents to create
 */
export const CONTACT_NEW_DOCUMENT_PATHS = (clientId = '') => ({
  createNewInvoice: `${DOCUMENT_PATHS.invoice}/new`,
  newInvoice: `${DOCUMENT_PATHS.invoice}/new?client_id=${clientId}`,
  newSimplifiedInvoice: `${DOCUMENT_PATHS.simplifiedInvoice}/new?client_id=${clientId}`,
  newInvoiceReceipt: `${DOCUMENT_PATHS.invoiceReceipt}/new?client_id=${clientId}`,
  newCreditNote: `credit_notes/new?client_id=${clientId}`,
  newDebitNote: `debit_notes/new?client_id=${clientId}`,
});

export const CONTACT_PATH = (clientId) => ({
  EDIT_CONTACT: `v4/contacts/${clientId}/edit`,
  NEW_CONTACT: `v4/contacts/new`,
  SHOW_CONTACT: `v4/contacts/${clientId}`,
});

export const ALL_INVOICES_BY_CLIENT = (clientId) => {
  return `v3/${DOCUMENT_PATHS['invoice']}?page=1&client_id[]=${clientId}`;
};

/**
 * Reports
 */
export const CREATE_REPORTS_PATHS = (clientId) => ({
  invoicingClientReport: 'reports/invoicing_per_client',
  receivedReport: 'reports/received_payments',
  agingReport: 'reports/invoice_aging',
  balanceReport: 'reports/balance_per_client',
  currentAccountReport: `reports/client_current_account`,
});

/**
 * Edit Contact
 */
export const CREATE_CONTACT_PATHS = {
  createContactHelp: 'createContactHelpTitle',
  numberOfCopiesHelp: 'numberOfCopiesHelpTitle',
  paymentDaysHelp: 'paymentDaysHelpTitle',
};

/**
 * TODO: Update when all screens have a React page
 *
 * Settings path
 */
export const FINANCIAL_SETTINGS_PATHS = {
  saft: 'accounts/saft',
  configureSaft: 'account/configure-schedule-saft',
  configureAT: 'accounts/autoridade_tributaria/communication',
  configureTax: 'accounts/autoridade_tributaria/regime_iva',
};

/**
 * Billing and Alerts path
 */
export const BILLING_ALERTS_PATHS = (accountId = '') => ({
  messageTemplates: `accounts/${accountId}/notification_configurations`,
  paymentPlataform: 'payment_services',
});

/**
 * Registration Paths
 */
export const ACCOUNT_AND_DATA_PATH = ({ userId, accountId }) => ({
  _legacy: ``,
  menuMyProfile: `users/${userId}/edit`,
  menuUsers: `users`,
  menuImportData: `imports/new`,
  menuExportData: `account/export`,
  menuDeleteAccount: `accounts/${accountId}/clear_account`,
  menuPlanAndSubscription: `accounts/details`,
  menuBillingData: `account/billing_data`,
});

export const PERSONALIZATION_PATH = ({ accountId }) => ({
  _legacy: ``,
  menuCompanyData: `account/tax_data`,
  menuCompanyBillingPreferences: `account/billing_preferences`,
  menuDocuments: `customize_document`,
  menuTaxes: `taxes`,
  menuBillingAlerts: `billing_alerts`,
  menuBillingAlertsTemplates: `message_templates`,
});

export const COMUNICATION_WITH_STATE_PATH = ({ userId, accountId }) => ({
  _legacy: ``,
  menuExportSaft: `accounts/saft`,
  menuScheduleSaft: `account/configure-schedule-saft`,
  menuSeries: `v3/sequences`,
  menuATComunication: `accounts/autoridade_tributaria/communication`,
  menuRegimeIVA: `accounts/autoridade_tributaria/regime_iva`,
  menuDigitalSignature: `digital_signature`,
});

export const INTEGRATIONS_PATH = ({ userId, accountId }) => ({
  _legacy: ``,
  menuAPI: `users/${userId}/api`,
  menuPlugins: `integrations`,
});

export const REGISTER_PATH = () => ({
  CREATE_ACCOUNT: `accounts/new`,
  FORGOT_PASSWORD: `passwords/recover`,
  LOGIN: `login`,
});

export const ITEMS_PATH = (id) => ({
  menuItemEdit: `items/${id}/edit`,
  menuItemCreate: `items/new`,
});

export const ITEM_PATH = (id) => ({
  ITEM_SHOW: `items/${id}`,
});
