import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import Anchor from '../../atoms/anchors/Anchor';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import {
  extractDocumentColumns,
  isDateExpired,
  buildDateInformation,
  isLicensePlateEmpty,
} from './util/documentManager';
import { formatEmptyLicensePlate } from './util/helper/documentsManagerHelper';
import { downloadPdfFile } from './util/pdfManager';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import {
  DOCUMENT_PATHS,
  ESTIMATE_PATHS,
  GUIDE_PATHS,
  DEMO_ACCOUNT_SEQUENCE,
} from '../../../constants/index';
import { isOnSuppliersTab } from '../../templates/helper/documentsTabHelper.js';

/**
 * React component
 * @class
 * Table row that renders document data.
 */
class Row extends Component {
  /**
   * @constructor
   * @param {object} props - React props object.
   */
  constructor(props) {
    super(props);

    this.addDocumentStatus = this.addDocumentStatus.bind(this);
    this.addDocumentNumber = this.addDocumentNumber.bind(this);
    this.addDocumentTypeAndClient = this.addDocumentTypeAndClient.bind(this);
    this.addDocumentDate = this.addDocumentDate.bind(this);
    this.addDocumentTotal = this.addDocumentTotal.bind(this);
    this.addPdfButton = this.addPdfButton.bind(this);
  }

  /**
   * Considering the document JSON, it creates the content about its status.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns {object} returns childs.
   */
  addDocumentStatus = (document) => {
    // DESIGN TEAM WILL CHANGE THIS
    let status = document.status === 'sent' ? 'final' : document.status;
    status = status === 'settled' ? 'paid' : status;
    // ------------------------------

    return (
      <div className='cell status align-middle'>
        <div className={`status-label bold status-color-${status}`}>
          <Icon className='icon fas fa-circle' />
          {this.props.intl.messages[document.status]}
        </div>
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content about its number.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns {object} returns childs.
   */
  addDocumentNumber = (document) => {
    return (
      <div className='cell sequence-number align-middle'>
        <div
          className='sequence-number-text'
          data-text={document.documentNumber}
        >
          {document.documentNumber}
        </div>
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content about its type and client.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns {object} returns childs.
   */
  addDocumentTypeAndClient = (document) => {
    let archivedComponent = '';
    if (document.archived) {
      archivedComponent = (
        <Span className='archived-title' label={'archived'} />
      );
    }

    let demoBadgeComponent = '';
    if (document.documentNumber === DEMO_ACCOUNT_SEQUENCE) {
      demoBadgeComponent = <Span className='card-badge gray' label='DEMO' />;
    }

    return (
      <div className='cell type-client'>
        {demoBadgeComponent}
        <Span className='type' label={document.type} />
        {archivedComponent}
        <Span className='client' label={document.clientName} />
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content about its date.
   * @function
   * @param {string} date - document date or due date.
   * @param {string} status - document status.
   * @param {string} date label.
   * @param {string} className .
   * @returns {object} returns childs.
   */
  addDocumentDate = (date, status, originalDate, label, className, icon) => {
    let classNameExpired = '';
    if (isDateExpired(label, status, originalDate, this.props.documentsTab)) {
      classNameExpired = 'expired';
    }

    return (
      <div className={`${className} ${classNameExpired}`}>
        <Span className='d-block card-label' label={label} />
        <Span className='d-block date' label={date} />
        <Icon className={`icon far ${icon}`} />
      </div>
    );
  };

  /**
   * Adds the first date element on the row.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns the call to addDocumentDate.
   */
  addFirstDateElement = (document) => {
    const label = {
      Invoices: 'createdAt',
      Estimates: 'createdAt',
      Guides: 'loadedAt',
      PurchaseOrders: 'createdAt',
      Suppliers: 'createdAt',
    }[this.props.documentsTab];

    const date = {
      Invoices: { value: document.date, rawValue: this.props.document.date },
      Suppliers: { value: document.date, rawValue: this.props.document.date },
      Estimates: { value: document.date, rawValue: this.props.document.date },
      Guides: {
        value: document.loadedAt,
        rawValue: this.props.document.loaded_at,
      },
    }[this.props.documentsTab];

    return this.addDocumentDate(
      date.value,
      document.status,
      date.rawValue,
      label,
      'cell date-cr',
      'fa-calendar-alt'
    );
  };

  /**
   * Adds the second date element on the row.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns the call to addDocumentDate.
   */
  addSecondDateElement = (document) => {
    const hasSecondDate = this.props.documentsTab !== 'Guides';

    if (hasSecondDate) {
      const { label, convertedDate, originalDate } = buildDateInformation(
        this.props.document,
        document,
        this.props.documentsTab
      );

      return this.addDocumentDate(
        convertedDate,
        document.status,
        originalDate,
        label,
        'cell date-ex',
        'fa-calendar-times'
      );
    } else {
      if (isLicensePlateEmpty(document)) {
        formatEmptyLicensePlate(document);
      }

      return (
        <div className={`cell date-ex`}>
          <Span className='d-block card-label' label='licensePlate' />
          <Span className='d-block date' label={document.licensePlate} />
          <Icon className={'icon fas fa-car'} />
        </div>
      );
    }
  };

  /**
   * Considering the document JSON, it creates the content about total.
   * @function
   * @param {string} total - document total, with or without IVA.
   * @returns {object} returns childs.
   */
  addDocumentTotal = (total) => {
    return (
      <div className='cell value text-align-right align-middle'>
        <div className='value-text'>{`${total}`}</div>
      </div>
    );
  };

  /**
   * Renders the Download PDF button.
   * @function
   * @returns {object} a button for the PDF Download.
   */
  addPdfButton = (documentId) => {
    return (
      <div
        className='button button-icon-self vertical-sep'
        onClick={() => downloadPdfFile(documentId)}
      >
        <Icon className='icon far fa-file-pdf' />
      </div>
    );
  };

  /**
   * Verify if the document is Invoice Receipt and has the
   * legacy Invoice Receipt configuration enabled.
   * @function
   * @returns {boolean} true when the document is a legacy Invoice Receipt
   */
  isLegacyInvoiceReceiptConfig = (document) => {
    return (
      document.type === 'InvoiceReceipt' &&
      document.legacy_invoice_receipt_config
    );
  };

  render() {
    Row.propTypes = {
      documents: PropTypes.object,
      index: PropTypes.number,
      intl: PropTypes.object,
      accountSettings: PropTypes.object,
      showValuesWithVat: PropTypes.bool,
      selected: PropTypes.bool,
      updateSelection: PropTypes.func,
    };

    const document = extractDocumentColumns(
      this.props.document,
      this.props.intl.locale,
      this.props.accountSettings,
      this.props.showValuesWithVat,
      this.props.documentsTab
    );

    const rawDocument = this.props.document;

    const redirectPath = (document) => {
      if (this.isLegacyInvoiceReceiptConfig(rawDocument)) {
        return buildPath(`${DOCUMENT_PATHS.invoice}/${document.id}`);
      }

      const paths = { ...DOCUMENT_PATHS, ...ESTIMATE_PATHS, ...GUIDE_PATHS };

      if (isOnSuppliersTab(this.props.documentsTab)) {
        return buildPath(`documents/${document.token}`);
      }

      return buildPath(`${paths[document.type]}/${document.id}`);
    };

    const archivedClassName = document.archived ? 'archived' : '';
    const selectedClassName = this.props.selected ? 'selected-card' : '';

    return (
      <div
        className={`card ${archivedClassName} ${selectedClassName} prevent-selection`}
      >
        <div className='cell select-checkbox align-middle'>
          {!isOnSuppliersTab(this.props.documentsTab) && (
            <CheckBoxLabel
              className='checkbox'
              checked={this.props.selected}
              onChange={(e) =>
                this.props.updateSelection(e, [this.props.document])
              }
            />
          )}
        </div>
        <Anchor href={redirectPath(document)}>
          <div className='mobile-left'>
            {this.addDocumentStatus(document)}
            {this.addDocumentNumber(document)}
            {this.addDocumentTypeAndClient(document)}
          </div>
          <div className='mobile-right'>
            <div className='dates'>
              {this.addFirstDateElement(document)}
              {this.addSecondDateElement(document)}
            </div>
            {this.addDocumentTotal(document.total)}
          </div>
        </Anchor>
        <div className='cell action align-middle'>
          {this.addPdfButton(this.props.document.id)}
        </div>
      </div>
    );
  }
}

export default injectIntl(Row);
