import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../atoms/spans/Span';
import Button from '../../../../atoms/buttons/Button';
import { StateHelper } from '../../../helper/state';
import { AppContext } from '../../../../../contexts/AppContext';
import Menu, { MENU_CATEGORY } from '../../../../organisms/menu/Menu';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import { buildOptionsFromCollection } from '../../../util/api/adapterHelper';
import { errorKeys } from '../../../../../constants/documentCustomization';
import { ContactService } from '../../../../../services';
import { TaxDataHelper } from './helper';
import Input from '../../../../atoms/inputs/Input';
import Icon from '../../../../atoms/icons/Icon';
import HandleCallResultModal from '../../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const initialFields = {
  tax_location_id: '',
  fiscal_id: '',
  currency_id: '',
  organization_name: '',
  address: '',
  city: '',
  postal_code: '',
};

const TaxDataLayout = ({ intl, ...props }) => {
  const { appState } = useContext(AppContext);
  const [fields, setFields] = useState(initialFields);
  const [accountSettings, setAccountSettings] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const [requestErrors, setRequestErrors] = useState('');
  const [canChangeNif, setCanChangeNif] = useState(true);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [nifValidated, setNifValidated] = useState(false);

  useEffect(() => {
    (async () => {
      const { account } = await ContactService.getAccountSettings();
      setAccountSettings(account);
      const keys = Object.keys(initialFields);
      setFields((prev) => ({
        ...prev,
        ...keys.reduce(
          (acc, key) => ({ ...acc, [key]: account[key] || '' }),
          {}
        ),
      }));
      const countriesRes =
        (await StateHelper.fetchCountries(
          appState.accountId,
          () => setRequestErrors(errorKeys.fetchError),
          'country_code'
        )) || [];
      setCountries(countriesRes);

      const currenciesRes =
        (await StateHelper.fetchCurrencies(appState.accountId, () =>
          setRequestErrors(errorKeys.fetchError)
        )) || [];
      setCurrencies(buildOptionsFromCollection('name', 'id', currenciesRes));

      setCanChangeNif(account.can_change_nif);
    })();
  }, [appState.accountId]);

  const onChangeField = (key, value) => setFields({ ...fields, [key]: value });

  const isButtonDisabled = () => {
    if (nifValidated) return false;
    const { organization_name, address, city, fiscal_id, tax_location_id } =
      fields;
    const { fiscal_id: accountFiscalId, tax_location_id: accountTaxLocation } =
      accountSettings;
    return (
      submitting ||
      !organization_name ||
      !address ||
      !city ||
      !fiscal_id ||
      fiscal_id !== accountFiscalId ||
      tax_location_id !== accountTaxLocation
    );
  };

  const responseErrorParser = (response) => {
    if (response.error) {
      const parsed = parse(DOMPurify.sanitize(response.error));
      return parsed;
    } else if (response.errors) {
      const parsed = parse(DOMPurify.sanitize(response.errors[0]['error']));
      return parsed;
    } else {
      return false;
    }
  };
  const onSubmit = async () => {
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('taxDataProcessing');

    const changedFields = Object.keys(fields).reduce((acc, key) => {
      const value =
        typeof fields[key] === 'string' ? fields[key].trim() : fields[key];
      if (value !== accountSettings[key]) acc[key] = value;
      return acc;
    }, {});

    if (!Object.keys(changedFields).length) {
      setSubmitting(false);
      setCallResponse('noChanges');
      setCallSucceeded(true);
      return;
    }

    const response = await ContactService.updateAccountSettings(changedFields);
    setSubmitting(false);

    if (!responseErrorParser(response)) {
      setAccountSettings(response.account);
      setCallResponse('taxDataSuccess');
      setCallSucceeded(true);
    } else {
      const parsed = responseErrorParser(response);
      setCallResponse(parsed);
      setCallSucceeded(false);
    }
  };

  const validateNIF = async () => {
    const iso_code = getCountryIsoCode();
    setCallResponse('validateNIFProcessing');
    setSubmitting(true);
    setIsSubmitted(true);

    if (
      fields.fiscal_id === accountSettings.fiscal_id &&
      fields.tax_location_id === accountSettings.tax_location_id
    ) {
      setSubmitting(false);
      setCallResponse('noChangesNif');
      setCallSucceeded(true);
      return;
    }

    const response = await ContactService.vatNumberLookup(
      fields.fiscal_id,
      iso_code
    );
    setSubmitting(false);

    if (response.valid === true) {
      if (response.address) {
        const parsedAddress = TaxDataHelper.parseAddressObject(
          response.address
        );
        setFields({
          ...fields,
          organization_name: response.name,
          address: parsedAddress.street,
          city: parsedAddress.city,
          postal_code: parsedAddress.postal_code,
        });
        setCallResponse('nifSuccess');
        setCallSucceeded(true);
        setNifValidated(true);
      }
      setCallResponse('nifSuccess');
      setCallSucceeded(true);
      setNifValidated(true);
    } else {
      const invalidVATError = intl.messages['invalidVATError'];
      setCallResponse(invalidVATError);
      setCallSucceeded(false);
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    onChangeField('postal_code', value);
    setPostalCodeError(!/^\d{4}-\d{3}$/.test(value));
  };

  const getCountryIsoCode = () => {
    const country = countries.find(
      (c) => c.value === parseInt(fields.tax_location_id)
    );
    return country?.code ?? null;
  };

  return (
    <div id='main-content' className='container --tax-data'>
      <div className='row mx-0 justify-content-center'>
        <div className='col-12 col-lg-9 ps-md-0'>
          <div className='d-flex flex-column w-100'>
            <div className='col-12 px-0'>
              <Paragraph className='text-header h2'>
                {intl.messages['taxDataTitle']}
              </Paragraph>
              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {intl.messages['taxDataDescription']}
                </Span>
              </Paragraph>
              <div className='sep-light mt-2'></div>
            </div>

            <div className='form-container d-flex flex-column w-100 mt-4'>
              <div className='d-flex flex-row w-100 align-items-end justify-content-between mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['taxDataFiscalId']}
                  </label>
                  <SelectWithValues
                    data-testid='country-selector'
                    id='tax_location_id'
                    key='tax_location_key'
                    keyName='id'
                    valueName='name'
                    options={countries}
                    value={fields.tax_location_id}
                    onChange={(e) => {
                      onChangeField('tax_location_id', e.target.value);
                      setNifValidated(false);
                    }}
                    disabled={!canChangeNif}
                  />
                  {requestErrors && (
                    <div
                      data-testid='country-error-message'
                      className='input-error'
                      id='error-message'
                    >
                      <Icon className='icon fas fa-circle-exclamation' />
                      {requestErrors}
                    </div>
                  )}
                </div>
                <div className='input-wrapper-all flex-grow-1'>
                  <Input
                    data-testid='fiscal-id-field'
                    type='text'
                    id='fiscal_id'
                    value={fields.fiscal_id}
                    onChange={(e) => {
                      onChangeField('fiscal_id', e.target.value);
                      setNifValidated(false);
                    }}
                    disabled={!canChangeNif}
                  />
                </div>
                <div>
                  <Button
                    data-testid='validate-nif-button'
                    className='button button-primary p-0'
                    id='validate_button'
                    label={intl.messages['validateNIF']}
                    onClick={validateNIF}
                    disabled={!canChangeNif || fields.fiscal_id === ''}
                  >
                    <Icon className='icon fas fa-search me-1' />
                  </Button>
                </div>
              </div>
              <div className='form-container d-flex flex-wrap w-100 mt-4'>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['currency']} *
                    </label>
                    <SelectWithValues
                      data-testid='currency-selector'
                      id='currency_id_select'
                      key='currency_key_select'
                      valueName='value'
                      options={currencies}
                      value={fields.currency_id}
                      onChange={(e) =>
                        onChangeField('currency_id', e.target.value)
                      }
                      disabled={!canChangeNif}
                    />
                    {requestErrors && (
                      <div
                        data-testid='currency-error-message'
                        className='input-error'
                        id='error-message'
                      >
                        <Icon className='icon fas fa-circle-exclamation' />
                        {requestErrors}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['companyName']}
                    </label>
                    <Input
                      data-testid='company-name-field'
                      id='organization_name'
                      type='text'
                      value={fields.organization_name}
                      onChange={(e) =>
                        onChangeField('organization_name', e.target.value)
                      }
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['address']}
                    </label>
                    <Input
                      data-testid='address-field'
                      id='address'
                      type='text'
                      value={fields.address}
                      onChange={(e) => onChangeField('address', e.target.value)}
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['city']}
                    </label>
                    <Input
                      data-testid='city-field'
                      id='city'
                      type='text'
                      value={fields.city}
                      onChange={(e) => onChangeField('city', e.target.value)}
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['postalCode']}
                    </label>
                    <Input
                      data-testid='postal-code-field'
                      id='postal_code'
                      type='text'
                      value={fields.postal_code}
                      onChange={handlePostalCodeChange}
                      disabled={submitting}
                    />
                    {postalCodeError && (
                      <div
                        data-testid='postal-code-error'
                        className='input-error'
                        id='postal_code_error'
                      >
                        <Icon className='icon fas fa-circle-exclamation' />
                        {intl.messages['invalidPostalCodeFormat']}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex col-12 px-0 mt-4'>
                <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                  <Button
                    data-testid='save-button'
                    className='button button-primary ms-auto ms-sm-0'
                    id='save_button'
                    label={intl.messages['save']}
                    onClick={onSubmit}
                    disabled={isButtonDisabled() || postalCodeError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 pe-md-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.PERSONALIZATION} />
        </div>
      </div>
      {isSubmitted && (
        <HandleCallResultModal
          data-testid='handle-call-result-modal'
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton
        />
      )}
    </div>
  );
};

export default injectIntl(TaxDataLayout);
