import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import {
  ACCOUNT_AND_DATA_PATH,
  COMUNICATION_WITH_STATE_PATH,
  INTEGRATIONS_PATH,
  PERSONALIZATION_PATH,
} from '../../../constants';
import { AppContext } from '../../../contexts/AppContext';
import ButtonDropdown from '../../molecules/buttons/ButtonDropdown';
import { buildChildren } from '../containers/util/buttonsHelper';

/**
 * Enum for menu category
 * @enum {string}
 * @readonly
 */
export const MENU_CATEGORY = {
  ACCOUNT: 'ACCOUNT',
  PERSONALIZATION: 'PERSONALIZATION',
  AT_COMUNICATION: 'AT_COMUNICATION',
  INTEGRATION: 'INTEGRATION',
};

const Menu = ({ activeCategory }) => {
  const { appState } = useContext(AppContext);
  const state = {
    userId: appState.rawUser,
    accountId: appState.rawAccount,
  };

  const MENU_PATHS = [
    {
      title: 'menuItemAccountAndData',
      item: ACCOUNT_AND_DATA_PATH(state),
      isActive: activeCategory === MENU_CATEGORY.ACCOUNT,
    },
    {
      title: 'menuItemPersonalization',
      item: PERSONALIZATION_PATH(state),
      isActive: activeCategory === MENU_CATEGORY.PERSONALIZATION,
    },
    {
      title: 'menuItemATComunication',
      item: COMUNICATION_WITH_STATE_PATH(state),
      isActive: activeCategory === MENU_CATEGORY.AT_COMUNICATION,
    },
    {
      title: 'menuItemIntegrations',
      item: INTEGRATIONS_PATH(state),
      isActive: activeCategory === MENU_CATEGORY.INTEGRATION,
    },
  ];

  return (
    <div className='menu'>
      <div
        className={`row px-0 m-0 content-block dropdown-button-block  mobile-side-content inside-menu`}
      >
        {MENU_PATHS.map((menu, key) => {
          return (
            <ButtonDropdown
              key={`item_${key}`}
              id={`${key}_otherDocuments`}
              className='mt-2'
              label={menu.title}
              dropdownType='button-secondary'
              isVisible={menu.isActive}
              noMouseDown
            >
              {buildChildren(menu.item)}
            </ButtonDropdown>
          );
        })}
      </div>
    </div>
  );
};

export default injectIntl(Menu);
