import React, { useEffect, useState } from 'react';
import { Quill } from 'react-quill-new';
import { injectIntl } from 'react-intl';

// Insert tags into the content editor
function insertCustomTags(value) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

// Add sizes to whitelist
const Size = Quill.import('formats/size');
Size.whitelist = ['small', 'normal', 'large', 'huge'];
Quill.register(Size, true);

// Custom Toolbar
export const modules = {
  toolbar: {
    container: '#ql-toolbar',
    handlers: {
      insertCustomTags: insertCustomTags,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = ['header', 'bold', 'italic'];

const ToolbarEditor = ({ customVariables, intl, disabled }) => {
  const [toolbarVariables, setToolbarVariables] = useState(customVariables);

  const renderOptions = () => {
    return Object.keys(toolbarVariables).map((item, index) => {
      return (
        <option key={index} id={item} value={toolbarVariables[item]}>
          {intl.messages[item]}
        </option>
      );
    });
  };

  useEffect(() => {
    setToolbarVariables(customVariables);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customVariables]);

  return (
    <div
      id='ql-toolbar'
      className={`ql-toolbar ql-snow ${disabled ? 'ql-disabled' : ''}`}
    >
      <span className='ql-formats'>
        <select className='ql-header' defaultValue='3'>
          <option value='1'>{intl.messages['header']}</option>
          <option value='2'>{intl.messages['subheader']}</option>
          <option value='3'>{intl.messages['normal']}</option>
          <option value='5'>{intl.messages['small']}</option>
        </select>
      </span>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
      </span>

      {toolbarVariables && (
        <span className='ql-formats'>
          <label htmlFor='variables'>{intl.messages['variables']}</label>
          <select name='variables' className='ql-insertCustomTags'>
            {renderOptions()}
          </select>
        </span>
      )}
    </div>
  );
};

export default injectIntl(ToolbarEditor);
