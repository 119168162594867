import {
  BILLING_VARIABLES,
  CA_TEMPLATE_VARIABLES,
  DUMMY_VARIABLES,
  MESSAGE_TEMPLATE_VARIABLES,
  PREVIEW_CONTENT_TYPE,
  TY_TEMPLATE_VARIABLES,
} from '../../../../constants/textEditor';

export const buildEmailVariables = (type) => {
  if (!type) {
    return {};
  }

  let variables = {};

  if (type === PREVIEW_CONTENT_TYPE.EMAIL) {
    variables = {
      ...MESSAGE_TEMPLATE_VARIABLES,
      ...CA_TEMPLATE_VARIABLES,
      ...TY_TEMPLATE_VARIABLES,
    };
  } else if (type === PREVIEW_CONTENT_TYPE.EMAIL_ALERTS) {
    variables = {
      ...BILLING_VARIABLES,
    };
  } else {
    return {};
  }

  let previewVariables;

  Object.keys(variables).forEach((key) => {
    previewVariables = {
      ...previewVariables,
      [variables[key]]: DUMMY_VARIABLES[key],
    };
  });

  return previewVariables;
};

export const buildEmailContent = (emailTemplate, previewVariables) => {
  let emailContent = emailTemplate;

  Object.keys(previewVariables).forEach((key) => {
    emailContent = emailContent.replaceAll(key, previewVariables[key]);
  });

  return emailContent;
};
