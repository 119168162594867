const DUMMY_VARIABLES = {
  CLIENT_NAME: 'Manuel Silva',
  CLIENT_FISCAL_ID: '276010108',
  FISCAL_ID: '276010108',
  EMAIL: 'manuel.silva@dummy.email',

  ORGANIZATION_NAME: 'MS, Lda.',
  COMPANY_NAME: 'MS, Lda.',
  EMAIL_FROM: 'dummy@e.mail',
  PAYMENT_IBAN: 'PT65003506515281641772445',
  PAYMENT_SWIFT: 'PBPODEVZXXX',

  SUMMARY_OPEN_DOCUMENTS: 'dummy.open.documents',
  SUMMARY_OVERDUE_DOCUMENTS: '500.00',

  PREFERENCIAL_CONTACT_NAME: 'José Aguiar',
  PREFERENCIAL_CONTACT_EMAIL: 'jose.aguiar@dummy.email',
  PREFERENCIAL_CONTACT_PHONE: '999999999',
  OPEN_ACCOUNT_LINK: 'dummy.open.account.link',

  INVOICE_LIST: 'dummy.invoice.list',
  INVOICE_DAYS_LEFT: 'dummy.invoice.days.left',
  INVOICE_NUMBER: '555999',
  INVOICE_TOTAL: '500.00',
  INVOICE_BEFORE_TAXES: '406.50',
  INVOICE_PAID_VALUE: '300.00',
  INVOICE_REMAINING_VALUE: '200.00',
  INVOICE_DATE: '2025-01-01',
  INVOICE_DUE_DATE: '2025-01-20',
  INVOICE_URL: 'dummy.invoice.url',
  MB_ENTITY: 'Dummy Entity',
  MB_REFERENCE: 'Dummy Reference',
  MB_TOTAL: '500.00',
  CREDIT_CARD_LINK: 'dummy.credit.card.link',
};
Object.freeze(DUMMY_VARIABLES);

const BILLING_VARIABLES = {
  CLIENT_NAME: '{{client_name}}',
  CLIENT_FISCAL_ID: '{{client_fiscal_id}}',

  ORGANIZATION_NAME: '{{organization_name}}',
  EMAIL_FROM: '{{email_from}}',
  PAYMENT_IBAN: '{{iban}}',
  PAYMENT_SWIFT: '{{swift}}',

  SUMMARY_OPEN_DOCUMENTS: '{{value}}',
  SUMMARY_OVERDUE_DOCUMENTS: '{{overdue_value}}',

  PREFERENCIAL_CONTACT_NAME: '{{preferencial_contact_name}}',
  PREFERENCIAL_CONTACT_EMAIL: '{{preferencial_client_email}}',
  PREFERENCIAL_CONTACT_PHONE: '{{preferencial_client_phone}}',
  OPEN_ACCOUNT_LINK: '{{open_account_link}}',
};
Object.freeze(BILLING_VARIABLES);

const BASE_TEMPLATE_VARIABLES = {
  COMPANY_NAME: '%company_name%',
  FISCAL_ID: '%fiscal_id%',
  EMAIL: '%email%',
  CLIENT_NAME: '%client_name%',
  CLIENT_FISCAL_ID: '%client_fiscal_id%',
  PREFERENCIAL_CONTACT_NAME: '%preferencial_contact_name%',
  PREFERENCIAL_CONTACT_EMAIL: '%preferencial_client_email%',
  PREFERENCIAL_CONTACT_PHONE: '%preferencial_client_phone%',
};

const MESSAGE_TEMPLATE_VARIABLES = {
  ...BASE_TEMPLATE_VARIABLES,
  INVOICE_LIST: '%invoice_list%',
  INVOICE_DAYS_LEFT: '%invoice_days_left%',
  INVOICE_NUMBER: '%invoice_number%',
  INVOICE_TOTAL: '%invoice_total%',
  INVOICE_BEFORE_TAXES: '%invoice_before_taxes%',
  INVOICE_PAID_VALUE: '%invoice_paid_value%',
  INVOICE_REMAINING_VALUE: '%invoice_remaining_value%',
  INVOICE_DATE: '%invoice_date%',
  INVOICE_DUE_DATE: '%invoice_due_date%',
  INVOICE_URL: '%invoice_url%',
  MB_ENTITY: '%mb_entity%',
  MB_REFERENCE: '%mb_reference%',
  MB_TOTAL: '%mb_total%',
  CREDIT_CARD_LINK: '%credit_card_link%',
};
Object.freeze(MESSAGE_TEMPLATE_VARIABLES);

const CA_TEMPLATE_VARIABLES = {
  ...BASE_TEMPLATE_VARIABLES,
  OPEN_ACCOUNT_LINK: '%open_account_link%',
};
Object.freeze(CA_TEMPLATE_VARIABLES);

const TY_TEMPLATE_VARIABLES = {
  ...BASE_TEMPLATE_VARIABLES,
  INVOICE_NUMBER: '%invoice_number%',
};
Object.freeze(TY_TEMPLATE_VARIABLES);

const FONT_SIZE_VARIABLE = {
  FONT_SIZE_10: 'small',
  FONT_SIZE_14: 'normal',
  FONT_SIZE_18: 'large',
  FONT_SIZE_24: 'huge',
};
Object.freeze(FONT_SIZE_VARIABLE);

const PREVIEW_CONTENT_TYPE = {
  EMAIL: 'email',
  EMAIL_ALERTS: 'email_alerts',
  SMS: 'sms',
};
Object.freeze(PREVIEW_CONTENT_TYPE);

export {
  DUMMY_VARIABLES,
  BILLING_VARIABLES,
  MESSAGE_TEMPLATE_VARIABLES,
  CA_TEMPLATE_VARIABLES,
  TY_TEMPLATE_VARIABLES,
  FONT_SIZE_VARIABLE,
  PREVIEW_CONTENT_TYPE,
};
