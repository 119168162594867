/**
 * Enum for steps configuration
 * @readonly
 * @enum {string}
 */
const CONFIGURATION_STEPS = {
  TO_CONFIGURE: 'to_configure',
  CONFIGURED: 'configured',
  CONFIGURING: 'configuring',
  NOT_CONFIGURED: 'not_configured',
};
Object.freeze(CONFIGURATION_STEPS);

export const DigitalSignatureHelper = {
  CONFIGURATION_STEPS,
};
