import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SortByFilter from '../dropdowns/SortByFilter';
import SetItemsPerPage from '../dropdowns/SetItemsPerPage';
import Button from '../../atoms/buttons/Button';
import { openMobileSideContent } from '../../organisms/containers/util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const TableHeader = (props) => {
  const {
    globalResetKey,
    filters,
    setItemsPerPage,
    sortDocumentsByPropertyAndOrder,
    resetAllFilters,
    isQueryStringEmpty,
    sortOptions,
    closeMobileMenu,
    noResultsPerPage,
  } = props;

  const mobileDisplay = openMobileSideContent(
    'mobileOptions',
    props.mobileSideContent
  );

  if (mobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='table-options row mx-0 align-items-center mobile-empty'>
      {!isQueryStringEmpty() && (
        <div className='col-lg-4 select-col px-0'>
          <Button
            id='globalReset'
            data-testid='globalReset'
            className='button button-secondary no-margin-left'
            type='button'
            label='reset'
            onClick={resetAllFilters}
          />
        </div>
      )}
      {/* Mobile Menu */}
      <div
        className={`col-lg-8 px-0 mx-0 options-col text-align-right mobile-side-content ${mobileDisplay}`}
      >
        <div className='option' style={{ width: '25%' }}>
          <SortByFilter
            key={globalResetKey}
            sortOption={filters.sort}
            sortOrder={filters.sortOrder}
            showTotalWithIVA={filters.showTotalWithIVA}
            sortDocumentsByPropertyAndOrder={sortDocumentsByPropertyAndOrder}
            sortOptions={sortOptions}
          />
        </div>
        {!noResultsPerPage && (
          <div className='option'>
            <SetItemsPerPage
              key={globalResetKey}
              filters={filters}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        )}
        <div className='col mobile-filter'>
          <Button
            id='showResultsButton'
            className='button button-primary align-left d-block'
            label='showResultsButton'
            onClick={closeMobileMenu}
          />
        </div>
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  filters: PropTypes.object,
  setItemsPerPage: PropTypes.func,
  sortDocumentsByPropertyAndOrder: PropTypes.func,
};

export default injectIntl(TableHeader);
