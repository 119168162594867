import React, { createContext, useState } from 'react';
import RequestAdapter from '../adapters/RequestAdapter';

export const AppContext = createContext();

const buildState = (stateToBuildFrom) => ({
  accountId: stateToBuildFrom.accountId,
  clientId: stateToBuildFrom.clientId,
  userId: stateToBuildFrom.userId,
  userRole: stateToBuildFrom.userRole,
  userEmail: stateToBuildFrom.userEmail,
  companyName: stateToBuildFrom.companyName,
  language: stateToBuildFrom.language,
  foreignAccount: stateToBuildFrom.foreignAccount,
  plan: stateToBuildFrom.plan,
  hasApiKey: stateToBuildFrom?.hasApiKey,
  accountTrial: stateToBuildFrom?.accountTrial,
  rawAccount: stateToBuildFrom?.rawAccount,
  rawUser: stateToBuildFrom?.rawUser,
  isUserAdmin: stateToBuildFrom?.isUserAdmin,
  PlanHasBillingAlerts: stateToBuildFrom?.PlanHasBillingAlerts,
  CanUseOldAlerts: stateToBuildFrom?.CanUseOldAlerts,
});

const AppContextProvider = ({ stateProps, children }) => {
  const state = buildState(stateProps);
  const [appState, setAppState] = useState(state);

  RequestAdapter.setAccountId(appState.accountId);

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
