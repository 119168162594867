import React, { PureComponent } from 'react';
import DatePicker from 'react-date-picker';
import { injectIntl } from 'react-intl';
import Select from '../../atoms/selects/Select';
import Icon from '../../atoms/icons/Icon';
import Label from '../../atoms/labels/Label';
import { defaultDateOptions } from '../../templates/invoices/util/filters/defaultFilters';
import {
  getDateInterval,
  _formatISODate,
  dateIntervalToDateObject,
} from './util/dateIntervalHelper';
import { mapThisQuarterLabel } from './util/dateSelectHelper';

/**
 * TODO: How to pass "this" to a childRef function, when
 * transforming this class into a functional component?
 */
class DateForm extends PureComponent {
  /**
   * DATE FORM
   * @constructor
   * Sets all date options and values.
   * @param {object} props - React props object.
   */
  constructor(props) {
    super(props);

    this.state = {
      defaultOptions: defaultDateOptions,
      activeOption: mapThisQuarterLabel(this.props.activeOption),
      dateInterval: { from: '', to: '' },
      dateObjects: this.props.value,
    };

    this.onChangeSelectOption = this.onChangeSelectOption.bind(this);
  }

  /**
   * Handles click on select component.
   * @function
   * @param {object} e - event.
   */
  onChangeSelectOption = (e) => {
    const interval = getDateInterval(e.target.value);
    const dateObjects = dateIntervalToDateObject(interval);
    this.setState({
      dateInterval: interval,
      activeOption: e.target.value,
      dateObjects,
    });
  };

  /**
   * Resets the form filters
   */
  resetFilters = () => {
    this.setState({ dateInterval: { from: '', to: '' }, activeOption: '' });
  };

  /**
   * Handles the onChange of the From Input
   * @param {object} e - Date object.
   */
  onFromChange = (e) => {
    const value = _formatISODate(e);
    this.setState((prevState) => ({
      activeOption: 'personalized',
      dateInterval: {
        from: value,
        to: prevState.dateInterval.to,
      },
      dateObjects: {
        from: e,
        to: prevState.dateObjects.to,
      },
    }));
  };

  /**
   * Handles the onChange of the To Input
   * @param {object} e - Date object.
   */
  onToChange = (e) => {
    const value = _formatISODate(e);

    this.setState((prevState) => ({
      activeOption: 'personalized',
      dateInterval: {
        from: prevState.dateInterval.from,
        to: value,
      },
      dateObjects: {
        from: prevState.dateObjects.from,
        to: e,
      },
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.showResetButton();

    this.props.onDateUpdate(this.state.dateObjects, this.state.activeOption);
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  // componentWillMount(){
  //   const { childRef } = this.props;
  //   childRef(undefined);
  // }

  render() {
    const { activeOption, defaultOptions, dateObjects } = this.state;
    return (
      <div className='search-filter-date'>
        <Label className='text-label --small'>{`${this.props.label}:`}</Label>
        <div className='input-select row'>
          <div className='col'>
            <Select
              value={activeOption}
              options={defaultOptions}
              onChange={this.onChangeSelectOption}
            />
          </div>
        </div>
        <div className='input-dates row'>
          <div className='col-date col'>
            <Label className='text-label --small'>{'from'}</Label>
            <DatePicker
              minDetail={'decade'}
              onChange={this.onFromChange}
              value={dateObjects.from}
              calendarIcon={null}
              clearIcon={null}
              dayPlaceholder={'dd'}
              monthPlaceholder={'mm'}
              yearPlaceholder={'yyyy'}
              format={'dd/MM/yyyy'}
              locale={this.props.intl.locale}
            />
          </div>
          <div className='col-date col'>
            <Label className='text-label --small'>{'to'}</Label>
            <DatePicker
              minDetail={'decade'}
              onChange={this.onToChange}
              value={dateObjects.to}
              calendarIcon={null}
              clearIcon={null}
              dayPlaceholder={'dd'}
              monthPlaceholder={'mm'}
              yearPlaceholder={'yyyy'}
              format={'dd/MM/yyyy'}
              locale={this.props.intl.locale}
            />
          </div>
          <div className='col-date col-1'>
            <Icon className='icon far fa-calendar-alt' />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(DateForm);
