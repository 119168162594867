import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../../../../organisms/modals/Modal';
import { DigitalSignatureHelper } from '../helper';
import CredentialStep from './steps/CredentialStep';
import ErrorStep from './steps/ErrorStep';
import LoadingStep from './steps/LoadingStep';
import SuccessStep from './steps/SuccessStep';

const { CONFIGURATION_STEPS } = DigitalSignatureHelper;

/**
 * Render the modal content by step
 *
 * @param {*} props
 * @returns {{ [x: string]: ReactComponentElement; }}
 */
const STEPS = (props) => ({
  [CONFIGURATION_STEPS.TO_CONFIGURE]: (
    <CredentialStep
      providerId={props.selectedProvider}
      closeModal={props.onClose}
      nextStep={props.nextStep}
      reload={props.reload}
    />
  ),
  [CONFIGURATION_STEPS.CONFIGURING]: (
    <LoadingStep nextStep={props.nextStep} closeModal={props.onClose} />
  ),
  [CONFIGURATION_STEPS.CONFIGURED]: (
    <SuccessStep nextStep={props.nextStep} closeModal={props.onClose} />
  ),
  [CONFIGURATION_STEPS.NOT_CONFIGURED]: (
    <ErrorStep closeModal={props.onClose} />
  ),
});

/**
 * Render content modal
 *
 * @returns {*}
 */
const ContentModal = (props) => {
  const { step, isLoading } = props;
  let icon = {};

  if (step !== CONFIGURATION_STEPS.TO_CONFIGURE) {
    icon = {
      iconName:
        step === CONFIGURATION_STEPS.CONFIGURED ? 'fa fa-check' : 'fa fa-xmark',
    };
  }

  return (
    <Modal isLoading={isLoading} {...icon}>
      {STEPS(props)[step]}
    </Modal>
  );
};

export default injectIntl(ContentModal);
