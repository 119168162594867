import React, { useRef, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { ReactComponent as IxLogo } from '../../../../assets/imgs/ix-logo.svg';
import GlobalEmailEditor from './GlobalEmailEditor';
import Input from '../../../atoms/inputs/Input';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Button from '../../../atoms/buttons/Button';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import { ContactService } from '../../../../services/ContactService';
import RequestHandelingAlert from '../../alerts/RequestHandelingAlert';
import { SettingService } from '../../../../services/SettingService';
import Skeleton from 'react-loading-skeleton';

const GlobalSendEmailModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setRequestErrors] = useState('');

  const [isCheckedUpdateEmail, setisCheckedUpdateEmail] = useState(false);
  const [previewRequested, setPreviewRequested] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [form, setForm] = useState({
    email: '',
    save: false,
    send_email_copy: false,
    subject: '',
    body: '',
  });

  const [messageTemplate, setMessageTemplate] = useState(undefined);
  const [accountInfo, setAccountInfo] = useState(undefined);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);

  const modalScrollRef = useRef(null);

  const parseMessage = (message) => {
    if (!message) return '';

    let result = message
      .replaceAll('%company_name%', accountInfo.organization_name)
      .replaceAll('%fiscal_id%', accountInfo.fiscal_id)
      .replaceAll('%email%', props.userEmail)
      .replaceAll('%client_name%', props.contactName)
      .replaceAll('%client_fiscal_id%', props.contactFiscalID)
      .replaceAll(
        '%preferencial_contact_name%',
        props.contactPrefName || props.contactName
      )
      .replaceAll('%preferencial_client_email%', props.contactPrefEmail)
      .replaceAll('%preferencial_client_phone%', props.contactPrefPhone)
      .replaceAll('%open_account_link%', props.openAccountLink)
      .replaceAll('\n', '<br>');

    return result;
  };

  const configureForm = () => {
    let data = {
      email: props.contactEmail,
      subject: parseMessage(messageTemplate.subject),
      body: parseMessage(messageTemplate.template_email),
      send_email_copy: messageTemplate.send_copy,
    };

    setForm({ ...form, ...data });

    setTimeout(() => {
      setIsLoadingInfo(false);
    }, 1000);
  };

  const parseTemplateToEditor = (template) => {
    return template.replaceAll('\n', '<br>');
  };

  const onCloseClick = () => {
    if (previewRequested) {
      setPreviewRequested(false);
    } else {
      props.changeModalVisibility();
    }
  };

  const handleFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const isRequiredFieldsFilled = () => {
    return form.email !== '';
  };

  const showAlertMessage = () => {
    setIsLoading(false);
    setShowAlert(true);

    if (form.save && !errors) {
      props.refresh();
    }
  };

  const handleSubmit = async () => {
    setPreviewRequested((prevState) => !prevState);
    if (previewRequested && isRequiredFieldsFilled()) {
      setIsLoading(true);

      const response = await ContactService.sendCheckingAccountByEmail(
        props.clientId,
        form
      );

      if (response.error) {
        showAlertMessage();
        setRequestErrors(response.error);
        modalScrollRef.current.scrollTo(0, 0);

        return;
      }

      showAlertMessage();
      setTimeout(() => {
        props.changeModalVisibility();
      }, 1000);
    }
  };

  useEffect(() => {
    if (accountInfo && messageTemplate) {
      configureForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo, messageTemplate]);

  useEffect(() => {
    const fetchMessageTemplates = async () => {
      const response = await SettingService.getMessageTemplates();

      if (!response.error) {
        if (response.message_templates.length > 0) {
          response.message_templates.forEach((template) => {
            template.template_email = parseTemplateToEditor(
              template.template_email
            );
          });

          setMessageTemplate(
            response.message_templates.find(
              (template) => template.notification_type === 'open_account_link'
            )
          );
        }
      }
    };

    const fetchAccountInfo = async () => {
      const response = await ContactService.getAccountSettings();

      if (!response.error) {
        setAccountInfo(response.account);
      }
    };

    fetchMessageTemplates();
    fetchAccountInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`modal-container d-block`}>
      <div className='modal-content-container --medium'>
        {/* IX logo loading */}
        <div className={`logo-circle ${isLoading ? 'loading' : ''}`}>
          {!isLoading && <IxLogo />}
          {isLoading && <IxLoading />}
        </div>

        {/* Modal content */}
        <div className='modal-content modal-scroll' ref={modalScrollRef}>
          {/* Modal Header */}
          <div className='modal-header'>
            <div className='text-header h3'>
              {props.intl.messages[props.title]}
            </div>
            <Paragraph className='text-paragraph h6'>
              {props.intl.messages[props.description]}
            </Paragraph>
          </div>

          {showAlert && <RequestHandelingAlert error={errors} />}
          <div className='form-container mb-0'>
            <div className='form-row row mx-0 no-margim-bottom '>
              <div className='col-12 px-0'>
                <label className='text-label'>
                  <span className='color-red-200'>*</span>
                  {props.intl.messages['toAddress']}:
                </label>
                <Input
                  id='send_to'
                  className='d-block'
                  type='text'
                  value={form.email}
                  onChange={(e) => handleFields('email', e.target.value)}
                />
                <div className='cc-bcc-links'>
                  <Button
                    id='add_cc'
                    className={`button button-link color-brand-primary margin-right ${
                      showCC && 'd-none'
                    }`}
                    label={'CC '}
                    onClick={() => setShowCC((prevState) => !prevState)}
                  />
                  <Button
                    id='add_bcc'
                    className={`button button-link color-brand-primary ${
                      showBCC && 'd-none'
                    }`}
                    label='BCC'
                    onClick={() => setShowBCC((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>
            {showCC && (
              <div className='form-row row mx-0'>
                <div className='col-12 px-0'>
                  <label className='text-label'>CC:</label>
                  <Input
                    id='input_cc'
                    type='text'
                    value={form.cc}
                    onChange={(e) => handleFields('cc', e.target.value)}
                  />
                </div>
              </div>
            )}
            {showBCC && (
              <div className='form-row row mx-0'>
                <div className='col-12 px-0'>
                  <label className='text-label'>BCC:</label>
                  <Input
                    id='input_bcc'
                    type='text'
                    value={form.bcc}
                    onChange={(e) => handleFields('bcc', e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className='form-row row mx-0 checkbox-row'>
              <div className='col-12 px-0'>
                <CheckBoxLabel
                  id='updateContactEmail'
                  className='checkbox color-gray-base my-0'
                  checked={isCheckedUpdateEmail}
                  onChange={(e) => {
                    setisCheckedUpdateEmail((prevState) => !prevState);
                    handleFields('save', e.target.checked);
                  }}
                >
                  {props.intl.messages['updateContactEmail']}
                </CheckBoxLabel>
              </div>
            </div>
            <div className='form-row row mx-0'>
              <div className='col-12 px-0'>
                <label className='text-label'>
                  {props.intl.messages['subject']}
                </label>
                <Input
                  id='send_subject'
                  type='text'
                  value={form?.subject || ''}
                  onChange={(e) => handleFields('subject', e.target.value)}
                />
              </div>
            </div>
            <div className='form-row row mx-0'>
              <div className='col-12 px-0'>
                {!isLoadingInfo && form?.body ? (
                  <GlobalEmailEditor
                    previewRequested={previewRequested}
                    updateEmailComponents={props.updateBodyAndSubject}
                    emailContent={
                      form?.body ?? props.intl.messages['defaultContentEmail']
                    }
                    accountLogo={
                      (messageTemplate.email_logo && accountInfo.logo_url) || ''
                    }
                    onChange={(e) => handleFields('body', e.editorContentHtml)}
                  />
                ) : (
                  <Skeleton height={300} />
                )}
              </div>
            </div>
            <div className='form-row row mx-0'>
              <div className='col-12 px-0'>
                <CheckBoxLabel
                  id='sendCopy'
                  className='checkbox color-gray-base'
                  checked={form.send_email_copy}
                  onChange={(e) => {
                    handleFields('send_email_copy', e.target.checked);
                  }}
                >
                  {props.intl.messages['sendCopyToMe']}{' '}
                  <span>{props.userEmail}</span>
                </CheckBoxLabel>
              </div>
            </div>
          </div>

          <Paragraph className='color-gray-400 text-align-right'>
            {props.intl.messages['requiredFields']}
            <span className='color-red-200'>*</span>
          </Paragraph>

          <div className='row mx-0 buttons-container justify-content-end'>
            <div className='col-2 ps-0'>
              <Button
                id='cancelAction'
                className='button button-outline w-100'
                onClick={() => onCloseClick()}
                label={
                  previewRequested
                    ? props.intl.messages['goBack']
                    : props.intl.messages['cancelAction']
                }
              />
            </div>
            <div className='col-3 d-flex justify-content-end pe-0'>
              <Button
                id='confirmButtonId'
                className='button button-primary w-100'
                label={
                  previewRequested
                    ? props.intl.messages['sendEmail']
                    : props.intl.messages['previewEmail']
                }
                onClick={handleSubmit}
                disabled={isLoading || !isRequiredFieldsFilled()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(GlobalSendEmailModal);
