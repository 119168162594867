import React from 'react';
import { injectIntl } from 'react-intl';
import Select from '../../../atoms/selects/Select';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { ALL_DOCUMENTS } from '../../../../constants/documentCustomization';

const CustomizationTypeController = (props) => {
  const { selectedSerie, series } = props.state;

  const optionToShow =
    props.selectedOption === ALL_DOCUMENTS ? 'allDocuments' : selectedSerie;

  const allDocumentsShown =
    props.selectedOption === ALL_DOCUMENTS ? true : false;

  return (
    <div className='row px-0 mx-0 content-block controller-block '>
      {/* <RadioButtonLabel
        id='all_documents'
        className='checkbox round'
        checked={props.selectedOption === 'all_documents'}
        onChange={(e) => {
          props.setSelectedOption(e.target.id);
        }}
      >
        <Span className='bold'>{props.intl.messages['allDocuments']}</Span>
        <Span className='text-paragraph --tiny d-block'>
          {props.intl.messages['allDocumentsDescription']}
        </Span>
      </RadioButtonLabel>
      <RadioButtonLabel
        id='by_sequence'
        className='checkbox round'
        checked={props.selectedOption === 'by_sequence'}
        onChange={(e) => {
          props.setSelectedOption(e.target.id);
        }}
      >
        <Span className='bold'>{props.intl.messages['bySequence']}</Span>
        <Span className='text-paragraph --tiny d-block'>
          {props.intl.messages['bySequenceDescription']}
        </Span>
      </RadioButtonLabel> */}
      <div className='select-col px-0'>
        <Select
          className='d-block'
          value={optionToShow}
          id='sequence_customization_select'
          options={['allDocuments'].concat(series)}
          onChange={(e) => {
            let optionSelected = e.target.value;

            if (optionSelected === 'allDocuments') {
              optionSelected = ALL_DOCUMENTS;
            }
            props.setSelectedOption(optionSelected);
          }}
        />
      </div>

      <div className='description-col px-0'>
        <Paragraph className='text-paragraph --small input-info'>
          {allDocumentsShown
            ? 'allDocumentsDescription'
            : 'bySequenceDescription'}
        </Paragraph>
      </div>
    </div>
  );
};

export default injectIntl(CustomizationTypeController);
