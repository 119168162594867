import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '../../atoms/icons/Icon';

/**
 *
 * TODO: Didn't transform this class into a functional component,
 * because I still don't know how to use this.bodyRef in a functional component.
 *
 * ES6 stateless component
 * * @param {object} props - React props object (className, children, intl, onClick function).
 */
export default class InformativeDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: this.props.isVisible,
    };
  }

  /**
   * Click on dropDown to open or close it.
   * @function
   */
  showOptions = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  render() {
    const { isVisible } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';

    return (
      <div
        className={`button button-dropdown ${this.props.dropdownType} ${cssClass}`}
      >
        <div
          id={this.props.id}
          data-testid={this.props.id}
          className='dropdown-header'
          onClick={this.showOptions}
        >
          {this.props.iconClassName && (
            <Icon className={this.props.iconClassName} />
          )}
          <div className='label header'>
            <FormattedMessage id={this.props.label} />
          </div>
        </div>
        {this.state.isVisible && (
          <div
            className='content-to-hide content-dropdown'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <ul>
              {this.props.children.map((child, index) => (
                <li onClick={child.onClick} key={index}>
                  {child}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

InformativeDropdown.propTypes = {
  label: PropTypes.string,
  dropdownType: PropTypes.string,
  children: PropTypes.array,
  isVisible: PropTypes.bool,
};
