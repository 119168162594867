import React from 'react';
import { injectIntl } from 'react-intl';
import { buildPath } from '../../organisms/containers/util/pathHelper.js';

const SideNavigation = (props) => {
  return (
    <div
      id={props.id}
      data-testid={props.id}
      className='side-navigation d-block'
    >
      <ul>
        <li className='title bold'>{props.intl.messages[props.title]}</li>
        {props.pathsList.map((page, index) => (
          <li key={index}>
            <a
              className={page.path === props.selectedPath ? 'selected' : ''}
              href={buildPath(page.path)}
            >
              {props.intl.messages[page.name]}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default injectIntl(SideNavigation);
