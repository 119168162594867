import {
  fetchDocuments,
  fetchClients,
} from '../../../../invoices/util/api/request';
import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../../api/queryStringManager';

/**
 * Requests the backend to update the information regarding the documents by client
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchClientDocuments(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  prevStateCallback();

  updateQueryStringParam('page', 1, searchInformation.windowLocation);
  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );

  const clientNames = extraInformation.clientList.map((client) => client.name);
  const clientIds = extraInformation.clientList.filter(function( element ) {
    return element !== undefined;
  });

  if (clientIds.length > 0) {
    updateQueryStringParam('client_id', clientIds, searchInformation.windowLocation);
  } else {
    updateQueryStringParam(
      'client_name',
      clientNames,
      searchInformation.windowLocation
    );
  }

  const response = await fetchDocuments(
    accountInformation.accountId,
    accountInformation.language,
    searchInformation.documentsTab,
    searchInformation.filters,
    searchInformation.windowLocation
  );

  updateStateCallback(response);
}

/**
 * Requests the backend to fetch the current filter client list and merge with the current selected client list
 *
 * @function
 *
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchClientsMatch(
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  const response = await fetchClients(
    accountInformation.accountId,
    extraInformation.value,
    searchInformation.documentsTab
  );

  if (Array.isArray(extraInformation.clientsToFilter)) {
    extraInformation.clientsToFilter.forEach((clientData) => {
      if (clientData.id === 'undefined' || clientData.id.length === 0) {
        return;
      }
      response.clients = response.clients.filter(
        (client) => client.id.toString() !== clientData.id
      );
      let client = {
        id: clientData.id,
        name: clientData.name,
        fiscal_id: clientData.fiscal_id,
      };
      response.clients.unshift(client);
    });
  }

  updateStateCallback(response, extraInformation.value);
}
