const FORMAT = [
  {
    key: 'XLS',
    value: 'xls',
  },
  {
    key: 'CSV',
    value: 'csv',
  },
  {
    key: 'XML',
    value: 'xml',
  },
];

const exportTypeLabels = {
  contacts: 'exportDataContacts',
  items: 'exportDataItems',
  invoices: 'exportDataDocuments',
  inventory: 'exportDataInventory',
};

const dataExportTypes = (intl) =>
  Object.keys(exportTypeLabels).map((value) => ({
    key: intl.messages[exportTypeLabels[value]],
    value,
  }));

const getDataExportTypes = (intl) => {
  return dataExportTypes(intl);
};

const getInitialState = (intl) => {
  return {
    exportSelector: 'contactsDocsAndItems',
    exportDataType: dataExportTypes(intl)[0].value,
    exportFormat: FORMAT[0].value,
  };
};

export const ExportDataHelper = {
  getInitialState,
  getDataExportTypes,
  FORMAT,
};
