import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../../atoms/paragraphs/Paragraph';

import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Tab = (props) => {
  const [currentTab, setCurrentTab] = useState(0);

  const showContentByTab = () => {
    return <ul className='tab--content'>{props.tabs[currentTab].content}</ul>;
  };

  return (
    <div className='tab'>
      <ul id={props.id} className='tab--list'>
        {props.tabs.map((tab, index) => (
          <li
            key={`${props.id}_${index}`}
            data-testid={`${props.id}_${index}`}
            onClick={() => {
              setCurrentTab(index);
              props.onTabChange(props.tabs[index].tab);
            }}
            className={`px-4 ${currentTab === index ? 'tab--active' : ''}`}
          >
            <Paragraph className='text-header h5'>{tab.name}</Paragraph>
          </li>
        ))}
      </ul>

      {props.isLoading ? (
        <div className='tab--loading'>
          <IxLoading alt='ix-loading' width={250} />
        </div>
      ) : (
        showContentByTab()
      )}
    </div>
  );
};

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
};

export default injectIntl(Tab);
