import React, { useEffect, useMemo, useState } from 'react';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tab from '../../../molecules/tab/Tab';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import Input from '../../../atoms/inputs/Input';
import {
  MESSAGE_TEMPLATE_VARIABLES,
  CA_TEMPLATE_VARIABLES,
  TY_TEMPLATE_VARIABLES,
  PREVIEW_CONTENT_TYPE,
} from '../../../../constants/textEditor';
import TextEditor from '../../../organisms/editor/TextEditor';
import EditorPreview from '../../../organisms/editor/previews/EditorPreview';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import { SettingService } from '../../../../services/SettingService';
import { GlobalsHelpers } from '../../helper/globals';
import Button from '../../../atoms/buttons/Button';
import HandleCallResultModal from '../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { ContactService } from '../../../../services/ContactService';
import Anchor from '../../../atoms/anchors/Anchor';
import { buildPath } from '../../../organisms/containers/util/pathHelper';

const MessageTemplatesLayout = (props) => {
  const [activeTab, setActiveTab] = useState('Doc');
  const [fields, setFields] = useState({
    id: undefined,
    email_bcc: '',
    email_cc: '',
    email_logo: false,
    notification_type: 'invoice',
    send_button_to_view_document: false,
    send_copy: false,
    send_current_account_link: false,
    subject: props.intl.messages[`messageTemplatesDefault${activeTab}Subject`],
    template_email:
      props.intl.messages[`messageTemplatesDefault${activeTab}Body`],
  });
  const [accountInfo, setAccountInfo] = useState({});
  const hiddenDocumentTypes = [
    'purchase_order',
    'cash_invoice',
    'open_account_link',
    'payment_thank_you',
  ];
  const allDocumentTypes = GlobalsHelpers.getDocumentTypes(
    props.intl,
    'messageTemplatesOption'
  );
  const documentTypes = GlobalsHelpers.getDocumentTypes(
    props.intl,
    'messageTemplatesOption'
  ).filter((type) => !hiddenDocumentTypes.includes(type.value));
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const [subjectVariables, setSubjectVariables] = useState([]);
  const [inUseVariables, setInUseVariables] = useState(
    MESSAGE_TEMPLATE_VARIABLES
  );
  const textEditorKey = useMemo(
    () => JSON.stringify(inUseVariables),
    [inUseVariables]
  );

  const onChangeField = (key, value, tab) => {
    if (key === 'notification_type') {
      if (messageTemplates.length > 0) {
        const currentTemplate = messageTemplates.find(
          (template) => template.notification_type === value
        );

        setFields(currentTemplate);
      } else {
        setFields({
          ...fields,
          [key]: value,
          subject: props.intl.messages[`messageTemplatesDefault${tab}Subject`],
          template_email:
            props.intl.messages[`messageTemplatesDefault${tab}Body`],
        });
      }
    } else {
      setFields({
        ...fields,
        [key]: value,
      });
    }
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);

    if (tab === 'CA') {
      onChangeField('notification_type', 'open_account_link', tab);
      setInUseVariables(CA_TEMPLATE_VARIABLES);
      parseSubjectVariables(CA_TEMPLATE_VARIABLES);
    } else if (tab === 'TY') {
      onChangeField('notification_type', 'payment_thank_you', tab);
      setInUseVariables(TY_TEMPLATE_VARIABLES);
      parseSubjectVariables(TY_TEMPLATE_VARIABLES);
    } else {
      onChangeField('notification_type', 'invoice', tab);
      setInUseVariables(MESSAGE_TEMPLATE_VARIABLES);
      parseSubjectVariables(MESSAGE_TEMPLATE_VARIABLES);
    }
  };

  const parseSubjectVariables = (variables) => {
    const parsedVariables = [
      {
        key: 'messageTemplatesVariables',
        value: props.intl.messages['messageTemplatesVariables'],
      },
    ];
    for (const variable in variables) {
      parsedVariables.push({
        key: variable,
        value: variables[variable],
      });
    }

    setSubjectVariables(parsedVariables);
  };

  const parseTemplateToEditor = (template) => {
    return template.replaceAll('\n', '<br>');
  };

  const parseTemplateToSubmit = (template) => {
    const replaceBR = template.replaceAll('<p><br></p>', '\n');
    const replacePS = replaceBR.replaceAll('<p>', '');
    const replacePE = replacePS.replaceAll('</p>', '\n');
    return replacePE;
  };

  const canSumbit = () => {
    return (
      fields?.subject &&
      fields?.subject.length > 0 &&
      fields?.template_email &&
      fields?.template_email !== '<p><br></p>'
    );
  };

  useEffect(() => {
    const fetchMessageTemplates = async () => {
      const response = await SettingService.getMessageTemplates();

      if (!response.error) {
        if (response.message_templates.length > 0) {
          response.message_templates.forEach((template) => {
            template.template_email = parseTemplateToEditor(
              template.template_email
            );
          });

          setMessageTemplates(response.message_templates);
          setFields(
            response.message_templates.find(
              (template) => template.notification_type === 'invoice'
            )
          );
        }
      }
    };

    const fetchAccountInfo = async () => {
      const response = await ContactService.getAccountSettings();

      if (!response.error) {
        setAccountInfo(response.account);
      }
    };

    fetchMessageTemplates();
    fetchAccountInfo();

    parseSubjectVariables(MESSAGE_TEMPLATE_VARIABLES);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('exportSAFTProcessing');

    const data = structuredClone(fields);

    data.template_email = parseTemplateToSubmit(fields.template_email);

    const response = await SettingService.updateMessageTemplate(data.id, data);

    setSubmitting(false);

    if (!response.error) {
      const responseMessage = props.intl.formatMessage(
        {
          id: 'messageTemplatesSubmitSuccess',
        },
        {
          document: (
            <Span className='color-green-100'>
              {
                allDocumentTypes.find(
                  (document) => document.value === fields.notification_type
                ).key
              }
            </Span>
          ),
        }
      );

      messageTemplates.forEach((template, index) => {
        if (template.id === fields.id) {
          messageTemplates[index] = fields;
        }
      });

      setMessageTemplates(messageTemplates);

      setCallResponse(responseMessage);
      setCallSucceeded(true);
    } else {
      // Handle api response
      const sanitizedResponse = DOMPurify.sanitize(response.error);
      const parsedResponse = parse(sanitizedResponse);
      setCallResponse(parsedResponse);
      setCallSucceeded(false);
    }
  };

  const mountTextEditorWithPreview = (type) => {
    const previewText =
      fields?.template_email !== null && fields?.template_email !== undefined
        ? fields?.template_email
        : props.intl.messages[`messageTemplatesDefault${activeTab}Body`];

    return (
      <div key={type} className='row px-0 mx-0'>
        <div className='col-md-6 ps-0'>
          <TextEditor
            key={textEditorKey}
            defaultValue={fields?.template_email}
            onChange={(value) => {
              onChangeField('template_email', value);
            }}
            variables={inUseVariables}
          />
        </div>
        <div className='col-md-6 pe-0'>
          <EditorPreview
            type={PREVIEW_CONTENT_TYPE[type.toUpperCase()]}
            template={previewText}
            logo={
              fields?.email_logo === true ? accountInfo?.logo_url : undefined
            }
          />
          <Paragraph className='my-3'>
            {props.intl.messages['messageTemplatesWarningMessage']}
          </Paragraph>
        </div>
      </div>
    );
  };

  const tabContent = () => {
    return (
      <div className='form-container d-flex flex-column w-100 mt-5'>
        {(activeTab === 'Doc' && (
          <div className='col-12 col-lg-6 px-0 mb-4'>
            <label className='text-label mb-2'>
              {props.intl.messages['messageTemplatesDocType']}
            </label>
            <SelectWithValues
              id='notification_type'
              key='notification_type'
              valueName='value'
              options={documentTypes}
              value={fields?.notification_type}
              onChange={(e) =>
                onChangeField('notification_type', e.target.value)
              }
            />
          </div>
        )) || <></>}

        <div className='col-12 col-lg-6 px-0 mb-4'>
          <div className='d-flex flex-column w-100'>
            <CheckBoxLabel
              id='messageTemplatesEmailLogo'
              className={`checkbox mt-2 mb-0`}
              onChange={(e) => onChangeField('email_logo', e.target.checked)}
              checked={fields?.email_logo || false}
              disabled={
                accountInfo?.logo_url === null ||
                accountInfo?.logo_url === undefined
              }
            >
              <span className='ms-2'>
                {props.intl.messages['messageTemplatesAddLogo']}
              </span>
            </CheckBoxLabel>

            {(accountInfo?.logo_url === null && (
              <Span className='mt-2'>
                <FormattedMessage
                  id='messageTemplatesNoLogo'
                  values={{
                    a: (chunks) => (
                      <Anchor
                        href={buildPath('customize_document')}
                        className='bold color-brand-primary'
                      >
                        {chunks}
                      </Anchor>
                    ),
                  }}
                />
              </Span>
            )) || <></>}
          </div>
        </div>

        <div className='row mx-0 d-flex w-100 mb-2'>
          <div className='col-12 col-md-6 ps-md-0'>
            <label className='text-label'>
              {props.intl.messages['messageTemplatesCC']}:
            </label>
            <Input
              id='message_templates_cc'
              type='text'
              value={fields?.email_cc || ''}
              onChange={(e) => onChangeField('email_cc', e.target.value)}
            />
          </div>

          <div className='col-12 col-md-6 pe-md-0'>
            <label className='text-label'>
              {props.intl.messages['messageTemplatesBCC']}:
            </label>
            <Input
              id='message_templates_bcc'
              type='text'
              value={fields?.email_bcc || ''}
              onChange={(e) => onChangeField('email_bcc', e.target.value)}
            />
          </div>
        </div>

        <div className='col-12 px-0 mt-3 mb-2'>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex flex-row w-100'>
              <label className='text-label mb-auto'>
                {props.intl.messages['messageTemplatesSubject']}:
              </label>

              <div className='col-6 col-md-3 px-0 ms-auto mb-1'>
                <SelectWithValues
                  id='variables'
                  key='variables'
                  valueName='variable'
                  options={subjectVariables}
                  value={'messageTemplatesVariables'}
                  onChange={(e) =>
                    onChangeField(
                      'subject',
                      fields.subject + JSON.parse(e.target.value).value
                    )
                  }
                  isObject
                />
              </div>
            </div>

            <div className='d-flex flex-column w-100'>
              <Input
                id='message_templates_subject'
                type='text'
                value={fields?.subject}
                onChange={(e) => onChangeField('subject', e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='row mx-0 d-flex w-100 mb-2'>
          <Span className='text-label mt-3 mb-2 px-0'>
            {props.intl.messages['messageTemplatesBody']}
          </Span>
          <div className='sep-light mt-2 mb-4' />
          {mountTextEditorWithPreview('email')}
        </div>

        <div className='sep-light mt-2 mb-4' />

        <div className='d-flex flex-column w-100'>
          <Span className='text-label mt-0 px-0'>
            {props.intl.messages['messageTemplatesSettings']}
          </Span>
          <CheckBoxLabel
            id='messageTemplatesSendCopy'
            className='checkbox mt-2 mb-0'
            onChange={(e) => onChangeField('send_copy', e.target.checked)}
            checked={fields?.send_copy || false}
          >
            <span className='ms-2'>
              {props.intl.messages['messageTemplatesSendCopy']}{' '}
              <small className='color-gray-400'>
                ({accountInfo.email || accountInfo.billing_email})
              </small>
            </span>
          </CheckBoxLabel>

          {(activeTab === 'Doc' && (
            <CheckBoxLabel
              id='messageTemplatesSendButtonToViewDoc'
              className='checkbox mt-2 mb-0'
              onChange={(e) =>
                onChangeField('send_button_to_view_document', e.target.checked)
              }
              checked={fields?.send_button_to_view_document || false}
            >
              <span className='ms-2'>
                {props.intl.messages['messageTemplatesSendButton']}
              </span>
            </CheckBoxLabel>
          )) || <></>}

          {(activeTab === 'Doc' && (
            <CheckBoxLabel
              id='messageTemplatesSendCALink'
              className='checkbox mt-2 mb-0'
              onChange={(e) =>
                onChangeField('send_current_account_link', e.target.checked)
              }
              checked={fields?.send_current_account_link || false}
            >
              <span className='ms-2'>
                {props.intl.messages['messageTemplatesSendLink']}
              </span>
            </CheckBoxLabel>
          )) || <></>}
        </div>
      </div>
    );
  };

  return (
    <div id='main-content' className='container --message-templates'>
      <div className='d-flex flex-wrap row mx-0 w-100'>
        <div
          data-testid='message-templates-layout'
          className='col-12 col-lg-9 ps-md-0'
        >
          <div className='d-flex flex-column w-100'>
            <div className='col-lg-10 px-0'>
              <Paragraph
                id='message-templates-title'
                className='text-header h2'
              >
                {props.intl.messages['messageTemplatesTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {props.intl.messages['messageTemplatesDescription']}
                </Span>
              </Paragraph>
            </div>
            <div className='sep-light mt-2' />
          </div>

          <div className='d-flex flex-column w-100 mt-5'>
            <Tab
              id='message-template-tab'
              tabs={[
                {
                  name: props.intl.messages['messageTemplatesDocuments'],
                  content: tabContent(),
                  tab: 'Doc',
                },
                {
                  name: props.intl.messages['messageTemplatesCurrentAccount'],
                  content: tabContent(),
                  tab: 'CA',
                },
                {
                  name: props.intl.messages['messageTemplatesThankYou'],
                  content: tabContent(),
                  tab: 'TY',
                },
              ]}
              onTabChange={onTabChange}
            />
          </div>

          <div className='d-flex justify-content-end w-100 mt-5'>
            <Button
              id='submitButton'
              className='button button-primary p-0'
              onClick={onSubmit}
              disabled={!canSumbit()}
            >
              {props.intl.messages['save']}
            </Button>
          </div>
        </div>

        <div className='col-lg-3 pe-md-0 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.PERSONALIZATION} />
        </div>
      </div>

      {isSubmitted && (
        <HandleCallResultModal
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton={true}
        />
      )}
    </div>
  );
};

export default injectIntl(MessageTemplatesLayout);
