import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import { CREATE_GUIDE_PATHS } from '../../../../constants/index';
import { buildPath } from '../util/pathHelper';
import { buildChildren } from '../util/buttonsHelper';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

/**
 * React component
 * @class
 * Right Menu component
 */
class RightMenu extends Component {
  /**
   * Creates a child component for each help information.
   * @function
   * @param {object} helpInfo - JSON object with each help information.
   * @returns {object} array with all child components.
   */
  addHelpInfo = () => {
    let childComponents = [];
    const helpInfo = [
      'deliveryNoteHelp',
      'returnDeliveryNoteHelp',
      'shippingNoteHelp',
    ];

    helpInfo.map((element, index) => {
      return childComponents.push(
        <FormattedMessage
          key={element}
          id={helpInfo[index]}
          values={{
            span: (chunks) => <span className='d-block bold'>{chunks}</span>,
          }}
        />
      );
    });

    return childComponents;
  };

  render() {
    const summaryMobileDisplay = openMobileSideContent(
      'mobileSummary',
      this.props.mobileSideContent
    );

    const buttonsMobileDisplay = openMobileSideContent(
      'mobileButtons',
      this.props.mobileSideContent
    );

    if (summaryMobileDisplay === 'open' || buttonsMobileDisplay === 'open') {
      helpScoutBeaconHelper.changeVisibility(true);
    }

    return (
      <div className='container px-0'>
        <div
          className={`buttons-block mobile-side-content ${buttonsMobileDisplay}`}
        >
          <div className='row mx-0 content-block button-primary-block'>
            <Anchor
              className='button button-primary d-block'
              href={buildPath(CREATE_GUIDE_PATHS['createNewDeliveryNote'])}
            >
              <FormattedMessage id='createNewDeliveryNote' />
            </Anchor>
          </div>
          <div className='row mx-0 content-block dropdown-button-block'>
            <ButtonDropdown
              label='createOtherDocuments'
              dropdownType='button-secondary'
            >
              {buildChildren(CREATE_GUIDE_PATHS)}
            </ButtonDropdown>
          </div>
          <div
            className={`row mx-0 p-0 content-block summary-table-block mobile-side-content ${summaryMobileDisplay}`}
          >
            <InformativeDropdown
              label='help'
              dropdownType='help-info-panel'
              iconClassName='icon fas fa-info-circle'
              isVisible={true}
            >
              {this.addHelpInfo()}
            </InformativeDropdown>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(RightMenu);
