import {
  BULK_SYNC_LIMIT,
  BULK_SELECTION_MAX_LIMIT,
} from '../../../../constants/index';
import React from 'react';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import parse from 'html-react-parser';

/**
 * Based on the current bulk process stage, the corresponding
 * string is returned
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @returns {string} - corresponding to the step of the bulk process.
 */
const getProcessStep = (bulkProcessInformation) => {
  let processStep = '';

  if (bulkProcessInformation.isPerformingBulk) {
    processStep = 'Processing';
  } else if (bulkProcessInformation.hasBulkFinished) {
    processStep = 'Final';
  } else {
    processStep = 'Start';
  }

  return processStep;
};

/**
 * For the Download PDF bulk action performed, decides about if the pdf process
 * should be sync or async.
 *
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 * @returns {string} - corresponding to the pdf process, sync or async.
 */
const getPdfProcessByBulkSyncLimit = (numberOfDocumentsSelected) => {
  if (numberOfDocumentsSelected <= BULK_SYNC_LIMIT) {
    return 'Sync';
  }
  return 'Async';
};

/**
 * Based on the bulk action performed, builds the bulk action name
 * section of the ID used to identify the locale.
 *
 * @param {string} performedAction - the name of the bulk action performed.
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 * @returns {string} - corresponding to the bulk action name section of the ID on the locale file.
 */
const getBulkName = (performedAction, numberOfDocumentsSelected) => {
  const BULK_TO_NAME = {
    archiveDocument: 'archive',
    unarchiveDocument: 'unArchive',
    deleteDocument: 'delete',
    deleteEstimates: 'delete',
    deleteGuides: 'delete',
    deleteItems: 'deleteItems',
    finalizeDocument: 'finalize',
    paymentReceipt: 'payReceipt',
    downloadPDF: 'pdf',
    deleteManageUsers: 'deleteUsers',
  };

  let pdfProcess = getPdfProcessByBulkSyncLimit(numberOfDocumentsSelected);
  // Add new condition to when the limit of documents selected is yet to be reached
  // change the pdfProcess from 'Async' to 'Sync'
  if (performedAction === 'downloadPDF') {
    return `${BULK_TO_NAME[performedAction]}${pdfProcess}`;
  } else {
    return BULK_TO_NAME[performedAction];
  }
};

/**
 * Builds the ID of the message that should be used on the BulkConfirmationModal component when there is an error.
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - the number of documents over which the bulk is being performed.
 *
 * @returns {string} - corresponding to the ID of the locale used, that is a combination of characteristics of the process
 * as bulk action performed and bulk size (one document or multiple).
 */
const buildErrorInformation = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  const bulkName = getBulkName(
    bulkProcessInformation.performedAction,
    numberOfDocumentsSelected
  );

  switch (bulkProcessInformation.responseStatus) {
    case 412:
      const errorSize =
        bulkProcessInformation.responseInformation.errors.length === 1
          ? 'Single'
          : 'Bulk';

      return `${bulkName}${errorSize}AlertErrorInfo`;

    default:
    case 500:
      return `${bulkName}AlertError`;
  }
};

/**
 * Builds the ID of the header that should be used on the BulkConfirmationModal component.
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - the number of documents over which the bulk is being performed.
 *
 * @returns {string} - corresponding to the ID of the locale used, that is a combination of characteristics of the process
 * as bulk action performed, step of execution and bulk size (one document or multiple).
 */
export const buildHeaderLabel = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  if (!bulkProcessInformation.validOperation) {
    return 'bulkAlertInvalidHeader';
  }

  const bulkName = getBulkName(
    bulkProcessInformation.performedAction,
    numberOfDocumentsSelected
  );
  const bulkSize = numberOfDocumentsSelected === 1 ? 'Single' : 'Bulk';
  const processStep = getProcessStep(bulkProcessInformation);

  const internalServerError = bulkProcessInformation.responseStatus === 500;

  return internalServerError
    ? 'bulkAlertErrorHeader'
    : `${bulkName}${bulkSize}Header${processStep}`;
};

/**
 * Builds the ID of the message that should be used on the BulkConfirmationModal component.
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 *
 * @returns {string} - corresponding to the ID of the locale used, that is a combination of characteristics of the process
 * as bulk action performed and the size of the bulk (Sync or Async when performing bulk Download PDF).
 */
export const buildModalMessage = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  const bulkName = getBulkName(
    bulkProcessInformation.performedAction,
    numberOfDocumentsSelected
  );

  return `${bulkName}BulkMessage`;
};

/**
 * Builds the information used to put together the last modal of the BulkConfirmationModal component.
 * The content of the object returned depends on the status returned from the backend, and essentially
 * has the CSS class that has to be used, depending on the response status, and the ID of the locale to build
 * the appropriate message.
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 *
 * @returns {object} finalModalInfo - information to build the last modal on the BulkConfirmationModal component.
 */
export const buildFinalModalInfo = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  const finalModalInfo = {
    finalModalCSS: '',
    finalModalId: '',
  };

  if (!bulkProcessInformation.validOperation) {
    finalModalInfo.finalModalCSS = 'alert-error bold';
    finalModalInfo.finalModalId = 'bulkAlertInvalidBody';
    return finalModalInfo;
  }

  switch (bulkProcessInformation.responseStatus) {
    case 500:
      finalModalInfo.finalModalCSS = 'alert-error';
      finalModalInfo.finalModalId = buildErrorInformation(
        bulkProcessInformation,
        numberOfDocumentsSelected
      );
      break;

    case 412:
      finalModalInfo.finalModalCSS = 'alert-error --list --scroll';
      finalModalInfo.finalModalId = buildErrorInformation(
        bulkProcessInformation,
        numberOfDocumentsSelected
      );
      break;

    default:
    case 200:
      finalModalInfo.finalModalCSS = 'alert-success';
      finalModalInfo.finalModalId = 'bulkAlertSuccess';
  }

  return finalModalInfo;
};

/**
 * Builds the error description section, based on the number of errors that occurred
 *
 * @function
 * @param {Array} bulkErrors - an Array with all the errors that happened during the bulk process
 * @returns {object} the error structure that will be displayed in case of error, either Paragraph or <ul>
 */
export const buildErrorSection = (bulkErrors) => {
  const singleError = bulkErrors.length === 1 ? true : false;

  const uniqueErrors = Array.from(new Set(bulkErrors));

  if (singleError) {
    return <Paragraph>{parse(uniqueErrors[0].error)}</Paragraph>;
  } else {
    return (
      <ul>
        {uniqueErrors.map((bulkError, index) => (
          <li key={index}>{parse(bulkError.error)}</li>
        ))}
      </ul>
    );
  }
};

/**
 * Builds the number of documents processed, which can vary in case of error and yet some documents
 * were processed.
 * Ex: When finalizing 100 invoices and 10 of them have errors, thus the number of processed documents
 * is 90.
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 * @returns {number} the number of documents processed.
 */
export const buildDocumentsProcessed = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  if (
    typeof bulkProcessInformation.responseStatus !== 'undefined' &&
    bulkProcessInformation.responseStatus === 412 &&
    typeof bulkProcessInformation.responseInformation.errors !== 'undefined'
  ) {
    return (
      numberOfDocumentsSelected -
      bulkProcessInformation.responseInformation.errors.length
    );
  }

  return numberOfDocumentsSelected;
};

/**
 * Validates if the user can proceed with the bulk action or
 * its selection exceeds the max limit allowed
 *
 * @function
 * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 * @returns {boolean} valid or invalid bulk action.
 */
export const validateBulkMaxSelectionLimit = (
  bulkProcessInformation,
  numberOfDocumentsSelected
) => {
  const maxLimit =
    BULK_SELECTION_MAX_LIMIT[bulkProcessInformation.performedAction];

  if (numberOfDocumentsSelected > maxLimit) {
    bulkProcessInformation.hasBulkFinished = true;
    bulkProcessInformation.isPerformingBulk = false;
    bulkProcessInformation.validOperation = false;
  } else {
    bulkProcessInformation.validOperation = true;
  }
};
