import React from 'react';
import PropTypes from 'prop-types';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Image = (props) => {
  return (
    <img
      data-testid={props.id}
      src={props.src}
      alt={props.alt}
      className={`img ${props.className}`}
      style={props.style}
    />
  );
};

Image.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.string,
  style: PropTypes.object,
};

export default Image;
