import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../../atoms/icons/Icon';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import FinalizationModal from '../FinalizationModal';
import ConfirmationModal from '../ConfirmationModal';
import DocumentsWithoutEmail from '../../dropdowns/email/DocumentsWithoutEmail';
import Button from '../../../atoms/buttons/Button';
import ButtonTooltip from '../../../molecules/buttons/ButtonTooltip';
import StepInfo from '../../../molecules/forms/StepInfo';
import EmailEditor from './EmailEditor';
import Span from '../../../atoms/spans/Span';
import * as request from '../../../templates/invoices/util/api/request';
import { LOADING_TIME } from '../../../../constants/index';
import { fetchDocumentsWithoutEmail } from '../../../../components/templates/invoices/util/api/request';

/**
 * @param {object} props - React props object.
 */
class SendEmailModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      clientsLoading: true,
      sendEmailRequested: false,
      testEmailSent: false,
      exitModalVisibility: false,
      previewRequested: false,
      numDocumentsWithoutEmail: 0,
      allDocumentsHaveEmail: true,
      noDocumentsHaveEmail: false,
    };

    this.disableLoading = this.disableLoading.bind(this);
    this.enableLoading = this.enableLoading.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.showEditor = this.showEditor.bind(this);
    this.setExitModalVisibility = this.setExitModalVisibility.bind(this);
  }

  /**
   * Updates component's state with the correct email components (email body and subject)
   *
   * As the Email content is inside a child component, one needs to add a callback function
   * that needs to update the parent's state, in order to have the correct information when
   * sending the email
   *
   * @function
   * @param {string} emailBody - HTML present in the email body.
   * @param {string} emailSubject - email subject which was already edited by the user.
   */
  updateBodyAndSubject = (emailBody, emailSubject) => {
    this.setState({
      emailBody: emailBody,
      emailSubject: emailSubject,
    });
  };

  /**
   * Starts the loading animation after the click of the 'Send Documents' button.
   */
  async enableLoading() {
    this.setState({ loading: true });
    this.timer = setTimeout(this.disableLoading, LOADING_TIME);
    this.sendEmail(this.state.emailBody, this.state.emailSubject);
  }

  /**
   * Disables the loading animation after the LOADING_TIME has passed,
   * and sets the FinalizationModal as visible.
   */
  disableLoading() {
    this.setState({
      loading: false,
      sendEmailRequested: true,
    });
    clearTimeout(this.timer);
  }

  /**
   * Sets the visibility of the Confirmation Modal upon clicking on the X icon.
   */
  setExitModalVisibility = () =>
    this.setState({ exitModalVisibility: !this.state.exitModalVisibility });

  /**
   * Closes both modals upon click on the 'Confirmation' button.
   */
  closeSendEmail = () => {
    this.props.changeModalVisibility();
    this.setExitModalVisibility();
  };

  /**
   * Closes the modal after the bulk being performed.
   * Only to be used on the FinalizationModal
   */
  closesModalAfterBulk = () => {
    this.props.changeModalVisibility();
    this.props.updateAfterBulk();
  };

  /**
   * Go to Email Preview Step
   */
  showPreview = () => {
    this.setState({ previewRequested: true });
  };

  /**
   * Go back to Email Editior Step
   */
  showEditor = () => {
    this.setState({ testEmailSent: false, previewRequested: false });
  };

  /**
   * Checks if the next button is enabled.
   * Can't be enabled if the request that checks the document's emails is not complete
   * Can't be enabled if no documents have email.
   */
  nextButtonIsDisabled = () => {
    return (
      this.state.noDocumentsHaveEmail ||
      (this.state.clientsLoading && this.state.previewRequested)
    );
  };

  /**
   * Sets the component's state based on the response returned from the backend
   *
   * @function
   * @param {object} documentsWithoutEmailResponse - JSON with the response regarding the documents
   * without email.
   */
  componentStateUpdate = (documentsWithoutEmailResponse) => {
    if (this._isMounted) {
      this.setState({
        clientsLoading: false,
        numDocumentsWithoutEmail:
          documentsWithoutEmailResponse.documentsWithoutEmail,
        allDocumentsHaveEmail:
          documentsWithoutEmailResponse.allDocumentsHaveEmail,
        noDocumentsHaveEmail:
          documentsWithoutEmailResponse.noDocumentsHaveEmail,
      });
    }
  };

  /**
   * Requests the backend to send an email, depending on the testEmail value
   * the request body will have different parameters
   *
   * @function
   * @param {String} emailBody - HTML elements that constitute the body of the email sent.
   * @param {String} emailSubject - with the subject of the email sent.
   * @param {boolean} testEmail - represents with email is being sent, bulk email or just the test email,
   * that should be redirected to the user's email.
   */
  async sendEmail(emailBody, emailSubject, testEmail = false) {
    if (testEmail) {
      this.setState({
        testEmailSent: !this.state.testEmailSent,
      });
    }

    await request.bulkAction(
      this.props.bulkId,
      this.props.documentsTab,
      this.props.accountInformation,
      this.props.documentsInformation,
      this.props.queryStringInformation,
      {
        testEmail: testEmail,
        userEmail: this.props.userEmail,
        emailBody: emailBody,
        emailSubject: emailSubject,
        logo: this.props.accountSettings.logo_url,
        accountName: this.props.accountSettings.account_name,
      },
      this.props.updateAfterBulkCallBack
    );
  }

  /**
   * Sets the state of the SendEmailModal component with the information from the backend
   *
   * @function
   * @param {object} documentsWithoutEmailResponse - JSON with the response regarding the documents
   * without email.
   *  - documentsWithoutEmail -> number of documents that should be displayed
   *  - allDocumentsHaveEmail -> boolean to portray if all the selected documents have email
   */
  documentsWithoutEmailCallback = (documentsWithoutEmailResponse) =>
    setTimeout(
      () => this.componentStateUpdate(documentsWithoutEmailResponse),
      LOADING_TIME
    );

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      await fetchDocumentsWithoutEmail(
        this.props.accountInformation,
        this.props.documentsInformation,
        this.props.queryStringInformation.filters,
        this.documentsWithoutEmailCallback,
        this.props.documentsTab
      );
    }
  }

  render() {
    const { visibility, accountSettings, userEmail } = this.props;
    const {
      loading,
      clientsLoading,
      sendEmailRequested,
      exitModalVisibility,
      previewRequested,
      allDocumentsHaveEmail,
      numDocumentsWithoutEmail,
    } = this.state;

    const cssClass = visibility ? 'd-block' : 'd-none';

    const documentId =
      this.props.documentsInformation.documentsNumber === 1
        ? 'document'
        : 'documents';

    return (
      <div id='' className={`modal-container ${cssClass}`}>
        <div className={'modal-content-container --big'}>
          <div
            className='close-icon'
            onClick={
              sendEmailRequested
                ? this.closesModalAfterBulk
                : this.setExitModalVisibility
            }
          >
            <Icon className='svg-inline--fa fa-xmark fa-w-11 icon fas fa-xmark' />
          </div>
          {loading && (
            <div className='loading loading-modals'>
              <IxLoading />
            </div>
          )}
          {exitModalVisibility && (
            <ConfirmationModal
              showModal={exitModalVisibility}
              modalMessageId={'exitMessage'}
              confirmButtonId={'exitAction'}
              onConfirmationFunction={this.closeSendEmail}
              changeModalVisibility={this.setExitModalVisibility}
              confirmationMessage={'confirmationMessage'}
            />
          )}
          <div className={'modal-content --bulk-email modal-scroll'} id=''>
            <div className='content'>
              {!sendEmailRequested && (
                <div className='modal-header'>
                  <div className='text-header h2'>
                    {this.props.intl.messages['sendEmailTitle']}
                  </div>
                  <Paragraph className='text-paragraph'>
                    {this.props.intl.messages['sendEmailDescription']}
                  </Paragraph>
                </div>
              )}
              {sendEmailRequested ? (
                <FinalizationModal
                  finalizationHeader={'sendingDocuments'}
                  finalizationMessage={'sentProcess'}
                  hasStepsBar={true}
                />
              ) : (
                <div className='row main-content'>
                  <div className='col col-editor'>
                    <EmailEditor
                      previewRequested={previewRequested}
                      sendTestEmail={this.sendEmail}
                      testEmailSent={this.state.testEmailSent}
                      editorIsLoading={false}
                      userEmail={userEmail}
                      accountName={accountSettings.account_name}
                      accountLogo={accountSettings.logo_url}
                      updateEmailComponents={this.updateBodyAndSubject}
                    />
                  </div>
                  <div className='col-3 col-info'>
                    <DocumentsWithoutEmail
                      allDocumentsHaveEmail={allDocumentsHaveEmail}
                      numDocumentsWithoutEmail={numDocumentsWithoutEmail}
                      clientsLoading={clientsLoading}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='status-bar row'>
              <div className='docs-num-container col-auto'>
                <Icon className='icon fas fa-envelope' />
                <Span className='num'>
                  {this.props.documentsInformation.documentsNumber}
                </Span>
                <FormattedMessage id={documentId} />
              </div>
              <div className='steps-container col'>
                <StepInfo
                  stepNum={'1'}
                  stepLabel={'editEmail'}
                  isChecked={previewRequested}
                  isDisabled={false}
                />
                <StepInfo
                  stepNum={'2'}
                  stepLabel={'previewEmail'}
                  isChecked={sendEmailRequested}
                  isDisabled={!previewRequested}
                />
                <StepInfo
                  stepNum={'3'}
                  stepLabel={'sendDocs'}
                  isChecked={sendEmailRequested}
                  isDisabled={!sendEmailRequested}
                />
              </div>
              <div className='buttons-container col'>
                {sendEmailRequested ? (
                  <Button
                    className='button button-primary'
                    onClick={this.closesModalAfterBulk}
                    label='closeAction'
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      id=''
                      className='button button-outline --arrow-left --hide-hover'
                      label='goBack'
                      disabled={
                        !previewRequested || this.state.noDocumentsHaveEmail
                      }
                      onClick={this.showEditor}
                    />
                    <ButtonTooltip
                      id=''
                      className='button button-primary --arrow-right'
                      label={previewRequested ? 'sendDocs' : 'previewEmail'}
                      onClick={
                        previewRequested ? this.enableLoading : this.showPreview
                      }
                      disabled={this.nextButtonIsDisabled()}
                      tooltipMessage={
                        this.state.noDocumentsHaveEmail && 'docsWithoutEmailTip'
                      }
                      tooltipType={'--warning'}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SendEmailModal.propTypes = {
  visibility: PropTypes.bool,
  changeModalVisibility: PropTypes.func,
  accountInformation: PropTypes.object,
  updateAfterBulk: PropTypes.func,
  documentsSelected: PropTypes.number,
  numDocumentsWithoutEmail: PropTypes.number,
};

export default injectIntl(SendEmailModal);
