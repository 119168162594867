import React, { useContext, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { AppContext } from '../../../../contexts/AppContext';
import { useAppDispatch } from '../../../../layouts/redux/hooks';
import { changeModalVisibilityReducer } from '../../../../layouts/redux/modal-fallbacks';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Alert from '../../../molecules/alerts/Alert';
import InputCode from '../../../molecules/inputs/InputCode';
import Modal from '../../../organisms/modals/Modal';

const TwoFactorAuthenticationModal = ({ intl }) => {
  const inputRefs = useRef(
    Array(7)
      .fill(null)
      .map(() => React.createRef())
  );
  const dispatch = useAppDispatch();
  const [form, setForm] = useState(Array(7).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { appState } = useContext(AppContext);

  const handleEventField = (index, value) => {
    const nextIndex = index + 1;
    const prevIndex = index - 1;
    const updatedForm = [...form];

    updatedForm[index] = value;
    setForm(updatedForm);

    if (nextIndex < form.length && value !== '') {
      inputRefs.current[nextIndex].current.focus();
    } else if (prevIndex >= 0 && value === '') {
      inputRefs.current[prevIndex].current.focus();
    }
  };

  const isAllFieldsFilled = () => form.every((digit) => digit.length === 1);

  const handleOnCloseClick = () => {
    dispatch(changeModalVisibilityReducer({ type: '', showFallback: false }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const code = form.join('');

    const response = await AuthenticationService.send2FACode({
      userId: appState.userId,
      xAccount: appState.accountId,
      code,
    });

    setIsLoading(false);
    if (response.success) {
      dispatch(
        changeModalVisibilityReducer({
          type: '',
          isFallbackApproved: true,
          showFallback: false,
        })
      );
      return;
    }

    setHasError(true);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text').slice(0, 7);
    setForm(pasteData.split('').concat(Array(7 - pasteData.length).fill('')));
  };

  return (
    <Modal
      titleKey={'twoFACodeTitle'}
      isLoading={isLoading}
      actions={{
        submit: {
          label: 'send',
          onClick: handleSubmit,
          isDisabled: !isAllFieldsFilled(),
        },
        cancel: {
          onClick: handleOnCloseClick,
        },
      }}
    >
      <div className='col-12'>
        <div className='col-12'>
          <div className='row '>
            <div className='col-12 text-align-center'>
              <Paragraph className='text-paragraph mb-4'>
                {intl.messages['twoFACodeSubtitle']}
              </Paragraph>
            </div>

            {hasError && (
              <div className='col-11 mb-3'>
                <Alert
                  id='request_alert_error'
                  alertType={`alert-error --icon`}
                  iconClassName='fas fa-circle-exclamation'
                >
                  {intl.messages['twoFACodeError']}
                </Alert>
              </div>
            )}

            <div
              data-testid='--two-factor'
              className='--two-factor'
              onPaste={handleOnPaste}
            >
              {form.map((value, key) => (
                <div key={key} className='input-code-container'>
                  <InputCode
                    id={`input-code-${key}`}
                    key={`${key}-${value}`}
                    innerRef={inputRefs.current[key]}
                    type='text'
                    value={value}
                    onChange={(e) => handleEventField(key, e.target.value)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(TwoFactorAuthenticationModal);
