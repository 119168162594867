import React, { useState } from 'react';
import SearchForm from '../../../molecules/forms/SearchForm';
import { injectIntl } from 'react-intl';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import TableHeaderSimple from '../../headers/TableHeaderSimple';
import { isQueryStringEmpty } from '../../../templates/util/api/queryStringManager';
import Button from '../../../atoms/buttons/Button';
import NewSequenceModalRegistration from '../../modals/sequences/NewSequenceModalRegistration';

const SearchBox = (props) => {
  const accountCredentialsPresent =
    props.credentialsInformation?.accountCredentialsPresent;

  const [currentTextSearch, setCurrentTextSearch] = useState(
    props.filters.text
  );
  const [previousTextSearch, setPreviousTextSearch] = useState(
    props.filters.text
  );
  const [textChanged, setTextChanged] = useState(false);
  const [isModalNewSequenceVisible, setIsModalNewSequenceVisible] =
    useState(false);

  /**
   * Submit form preventing page reload.
   * @function
   * @param {object} e - event.
   */
  const submitSearch = (e) => {
    e.preventDefault();

    // prevent request
    if (textChanged === false) {
      return;
    }

    setTextChanged(false);
    setPreviousTextSearch(currentTextSearch);

    props.getSequencesByTextInput(currentTextSearch);
  };

  /**
   * Child input onChange event.
   * @function
   * @param {object} e - event.
   */
  const onChangeInputValue = (e) => {
    const newTextInputValue = e.target.value;

    props.filters.text = e.target.value;

    setTextChanged(newTextInputValue !== previousTextSearch);
    setCurrentTextSearch(e.target.value);
  };

  const mobileDisplay = openMobileSideContent(
    'mobileFilters',
    props.mobileSideContent
  );

  if (mobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  const {
    filters,
    sortDocumentsByPropertyAndOrder,
    setItemsPerPage,
    resetAllFilters,
    mobileSideContent,
    closeMobileMenu,
    globalResetKey,
    sortOptions,
  } = props;

  return (
    <div className='advanced-search --mol'>
      <div className='row search-bar-row mx-0'>
        <div
          className={`${
            props.newLayout
              ? 'col-lg-9 col-12 px-0 ps-lg-0 pe-lg-2'
              : 'col-12 px-0'
          }`}
        >
          <SearchForm
            id='table'
            searchTerm={filters.text}
            textChanged={textChanged}
            onChangeInputValue={onChangeInputValue}
            onSubmit={submitSearch}
            placeholder={props.placeholder}
          />
        </div>
        {props.newLayout && (
          <div className='col-lg-3 col-12 px-0 ps-lg-2 pe-lg-0 mt-2 mt-lg-0'>
            <Button
              className='button button-primary d-block'
              label='createNewSequence'
              onClick={() =>
                setIsModalNewSequenceVisible((prevState) => !prevState)
              }
            />
          </div>
        )}
      </div>
      <div className='row mx-0 align-items-center filters-row mb-0'>
        {/* <div
          className={`col-lg-8 search-filters-row row mobile-side-content ${mobileDisplay}`}
        >
          <div className='col col-filter'>
            <SearchByStatus
              status={filters.status}
              filterByStatus={props.getSequencesByStatus}
              documentsTab={'Sequences'}
              archived={filters.archived}
              nonArchived={filters.nonArchived}
            /> 
          </div>
          <div className='col col-filter'>
            <SearchByType
              types={filters.type}
              filterByType={props.getSequencesByType}
            />
          </div> 
         <div className='col col-filter'>
          </div>
            <SearchByRegistration
              options={filters.registration}
              filterByRegistration={props.getSequencesByRegistration}
            />
          </div>
          <div className='col col-filter'></div> 
          <div className='col mobile-filter'>
            <Button
              className='button button-primary text-align-left d-block'
              label='showResultsButton'
              onClick={closeMobileMenu}
            />
          </div>
        </div> */}
        <div className='col-lg-12 table-options-block'>
          <TableHeaderSimple
            globalResetKey={globalResetKey}
            filters={filters}
            setItemsPerPage={setItemsPerPage}
            sortDocumentsByPropertyAndOrder={sortDocumentsByPropertyAndOrder}
            sortOptions={sortOptions}
            resetAllFilters={resetAllFilters}
            isQueryStringEmpty={isQueryStringEmpty}
            mobileSideContent={mobileSideContent}
            closeMobileMenu={closeMobileMenu}
          />
        </div>
      </div>
      {isModalNewSequenceVisible && (
        <NewSequenceModalRegistration
          changeModalVisibility={() =>
            setIsModalNewSequenceVisible((prevState) => !prevState)
          }
          isAtCommunicationActive={accountCredentialsPresent}
          documentsTab={props.documentsTab}
          accountId={props.credentialsInformation.accountId}
          getListing={props.getListing}
          isDemoAccount={props.isDemoAccount}
        />
      )}
    </div>
  );
};

export default injectIntl(SearchBox);
