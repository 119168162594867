import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { PluginService } from '../../../../services';
import { SettingService } from '../../../../services/SettingService';
import Button from '../../../atoms/buttons/Button';
import Image from '../../../atoms/images/Image';
import Input from '../../../atoms/inputs/Input';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import Span from '../../../atoms/spans/Span';
import TextArea from '../../../atoms/textareas/TextArea';
import Alert from '../../../molecules/alerts/Alert';
import { GlobalsHelpers } from '../../helper/globals';
import { buildOptionsFromCollection } from '../../util/api/adapterHelper';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { AppContext } from '../../../../contexts/AppContext';
import {
  DEFAULT_NO_TAX,
  DEFAULT_TAX,
  DEFAULT_TAX_REASON,
} from '../../../../constants/defaultValues';
import Modal from '../../../organisms/modals/Modal';
import { FormFields, INPUT_TYPE } from './form/FormFields';
import ConfigureExternalIntegration from './ConfigureExternalIntegration';

const ConfigureIntegration = (props) => {
  const { appState } = useContext(AppContext);
  const history = useHistory();
  let { id } = useParams();
  const { location } = history;
  const [isRequiredFieldsFilled, setIsRequiredFieldsFilled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [formFields, setFormFields] = useState({
    vat_rate: DEFAULT_TAX,
    vat_rate_reason: DEFAULT_TAX_REASON,
    credentials: location.state?.credentials || [],
    configurations: location.state?.configurations || [],
  });

  const IS_EDIT_MODE = !!id;

  /**
   * Handle fields state
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  const handleFormFields = async (key, value) => {
    const updatedForm = {
      ...formFields,
      [key]: value,
    };
    setFormFields(updatedForm);

    const credentialFilled = Object.values(updatedForm.credentials).filter(
      (credential) => credential.value !== ''
    );
    const { credentials, ...restFields } = updatedForm;
    const formFilled = Object.keys(restFields).filter(
      (field) => restFields[field] !== ''
    );

    setIsRequiredFieldsFilled(
      formFilled.includes('vat_rate' && 'vat_rate_reason' && 'name') &&
        credentialFilled.length >= Object.keys(updatedForm.credentials).length
    );
  };

  /**
   * Handle credentials fields state
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  const handleCredentialsFormFields = (key, value) => {
    const credentialsUpdate = formFields.credentials.map((credential) => {
      if (credential.key === key) {
        credential = {
          ...credential,
          value,
        };
      }

      return credential;
    });

    handleFormFields('credentials', credentialsUpdate);
  };

  /**
   * Handle configurations fields state
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  const handleConfigurationsFormFields = (key, value) => {
    const configurationsUpdate = formFields.configurations.map(
      (configuration) => {
        if (configuration.key === key) {
          configuration = {
            ...configuration,
            value,
          };
        }

        return configuration;
      }
    );

    handleFormFields('configurations', configurationsUpdate);
  };

  /**
   * Set initial state in credentials fields
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  let credentialsPopullated = [];
  const setFirstStateToCredentials = (key, value) => {
    formFields.credentials.forEach((credential) => {
      if (credential.key === key) {
        credentialsPopullated.push({ ...credential, key, value });
      }
    });
    handleFormFields('credentials', credentialsPopullated);
  };

  /**
   * Set initial state in configurations fields
   *
   * @param {string} key - field key
   * @param {string} value - field value
   */
  let configurationsPopullated = [];
  const setFirstStateToConfigurations = (key, value) => {
    formFields.configurations.forEach((configuration) => {
      if (configuration.key === key) {
        configurationsPopullated.push({ ...configuration, key, value });
      }
    });
    handleFormFields('configurations', configurationsPopullated);
  };

  /**
   * Mount credentials fields
   */
  const mountCredentialsFields = (fields) => {
    return fields.map((field, index) => {
      return FormFields[(field.type || INPUT_TYPE.PASSWORD).toUpperCase()]({
        defaultValue: fields[index]?.value,
        field,
        handleField: handleCredentialsFormFields,
        initCredentials: setFirstStateToCredentials,
        prevState: fields,
        messages: props.intl.messages,
      });
    });
  };

  /**
   * Mount configurations fields
   */
  const mountConfigurationsFields = (fields) => {
    return fields.map((field, index) => {
      return FormFields[(field.type || INPUT_TYPE.PASSWORD).toUpperCase()]({
        defaultValue: fields[index]?.value,
        field,
        handleField: handleConfigurationsFormFields,
        initCredentials: setFirstStateToConfigurations,
        prevState: fields,
        messages: props.intl.messages,
      });
    });
  };

  /**
   * Mount external webhook
   */
  const mountExternalWebhook = () => {
    return FormFields[INPUT_TYPE.COPY_URL]({
      defaultValue: location?.state.webhookUrl,
      field: { field: 'Webhook URL' },
      handleField: () => {},
      initCredentials: () => {},
    });
  };

  /**
   * Mount header
   * @param {string} key - key to translation
   */
  const mountHeaderOfSection = (key) => {
    return (
      <div className='column m-4'>
        <Paragraph className='text-header h4 mb-0'>
          {props.intl.messages[`${key}Title`]}
        </Paragraph>
        <Span className='color-gray-400'>
          {props.intl.messages[`${key}Subtitle`]}
          {key === 'integrationCredentials' && (
            <span> {location.state?.pluginName}</span>
          )}
        </Span>
        <div className='sep-light' />
      </div>
    );
  };

  /**
   * Handle update or create methods
   */
  const onSubmit = async () => {
    setIsLoading(true);
    if (isRequiredFieldsFilled) {
      const { vat_rate, vat_rate_reason, ...rest } = formFields;
      const payload = {
        ...rest,
        fiscal_information: {
          vat_rate,
          vat_rate_reason:
            vat_rate !== DEFAULT_NO_TAX ? DEFAULT_TAX_REASON : vat_rate_reason,
        },
      };

      const response = IS_EDIT_MODE
        ? await PluginService.updateIntegrationById(id, payload)
        : await PluginService.registerAnIntegration(
            location.state?.pluginId,
            payload
          );

      if (!response.error) {
        if (!IS_EDIT_MODE) {
          id = response?.integration?.id;
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        setTimeout(async () => {
          await fetchIntegration();
          setIsSubmitted(true);
        }, 500);
        return;
      }
      setHasRequestErrors(true);
    }
  };

  /**
   * Fetch integration data
   */
  const fetchIntegration = async () => {
    const response = await PluginService.getIntegrationById(id);

    if (!response.error) {
      const { fiscal_information, ...rest } = response;
      setFormFields({
        ...rest,
        vat_rate: fiscal_information.vat_rate,
        vat_rate_reason: fiscal_information.vat_rate_reason,
      });
    }
  };

  useEffect(() => {
    if (IS_EDIT_MODE) {
      fetchIntegration();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.state?.pluginId]);

  useEffect(() => {
    /**
     * Fetch tax settings
     */
    const fetchTax = async () => {
      const response = await SettingService.getTaxList();
      if (!response.error) {
        setTaxList(buildOptionsFromCollection('name', 'name', response.taxes));
      }
    };

    fetchTax();
  }, []);

  return location.state?.external ? (
    <ConfigureExternalIntegration {...location.state} />
  ) : (
    <div id='main-content' className='container config-integration'>
      <div className='row mx-0 config-integration--header'>
        <div className='image-container ps-0'>
          <Image src={location.state?.pluginLogo} />
        </div>
        <div className='col-8 pe-0 content-block page-header-block'>
          <div className='text-header h4 --secondary'>
            {props.intl.messages['configuration']}
          </div>
          <div className='text-header h3'>{location.state?.pluginName}</div>
        </div>

        <div className='col-12 mt-3 px-0'>
          {hasRequestErrors && (
            <Alert
              id='request_alert_error'
              alertType={`alert-error --icon`}
              iconClassName='fas fa-circle-exclamation'
            >
              {props.intl.messages['uploadError']}
            </Alert>
          )}
          <div className='block-grid white-grid'>
            <div className='row mx-0 grid-block multiple-content-container px-0'>
              <div className='col-12 px-0'>
                <div className='form-container my-0'>
                  {/* Starts description section */}
                  {mountHeaderOfSection('integrationDescription')}
                  <div className='form-row row m-4'>
                    <div className='col-sm-12 px-0'>
                      <label className='text-label'>
                        {props.intl.messages['name']}*
                      </label>
                      <Input
                        id='name'
                        type='text'
                        value={formFields?.name}
                        onChange={(e) =>
                          handleFormFields('name', e.target.value)
                        }
                        maxLength='50'
                      />
                    </div>

                    <div className='col-sm-12 mt-3 px-0'>
                      <label className='text-label'>
                        {props.intl.messages['sortDescription']}
                      </label>

                      <TextArea
                        id='description'
                        type='text'
                        limit='300'
                        value={formFields?.description || ''}
                        onChange={(e) =>
                          handleFormFields('description', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {/* end section */}

                  {/* Start tax section */}
                  {mountHeaderOfSection('integrationTax')}
                  <div className='form-row row m-4 mb-5'>
                    <div className='col-md-6 col-sm-12 mt-3 ps-md-0'>
                      <label className='text-label'>
                        {props.intl.messages['vat']} %*
                      </label>
                      <SelectWithValues
                        id='vat'
                        key='vat'
                        valueName='value'
                        options={taxList}
                        value={formFields?.vat_rate}
                        onChange={(e) =>
                          handleFormFields('vat_rate', e.target.value)
                        }
                      />
                    </div>
                    <div className='col-md-6 col-sm-12 mt-3 pe-md-0'>
                      <label className='text-label'>
                        {props.intl.messages['taxExemptionReason']} *
                      </label>
                      <SelectWithValues
                        id='tax_id_select'
                        key='tax_key_select'
                        valueName='value'
                        options={
                          formFields?.vat_rate === DEFAULT_NO_TAX
                            ? GlobalsHelpers.getTaxExemptionList(
                                props.intl
                              ).slice(1, -1)
                            : GlobalsHelpers.getTaxExemptionList(props.intl)
                        }
                        value={formFields?.vat_rate_reason}
                        onChange={(e) =>
                          handleFormFields('vat_rate_reason', e.target.value)
                        }
                        disabled={formFields?.vat_rate !== DEFAULT_NO_TAX}
                      />
                    </div>
                    <div className='col-12 mt-2 px-0'>
                      <Paragraph className=' text-paragraph color-gray-400 --small bold'>
                        {props.intl.messages['integrationTaxAgreement']}
                      </Paragraph>
                    </div>
                  </div>
                  {/* end tax section */}

                  {/* Starts credentials section */}
                  {formFields?.configurations &&
                    formFields?.configurations.length > 0 && (
                      <>
                        {mountHeaderOfSection(
                          'integrationExternalConfiguration'
                        )}
                        {location?.state.webhookUrl && (
                          <div className='form-row row m-4'>
                            <div className='col-6 px-0'>
                              {mountExternalWebhook()}
                            </div>
                          </div>
                        )}
                        <div className='form-row row m-4 mb-5'>
                          {mountConfigurationsFields(
                            formFields?.configurations
                          )}
                        </div>
                      </>
                    )}
                  {/* end credentials section */}

                  {/* Starts credentials section */}
                  {formFields.credentials.length > 0 && (
                    <>
                      {mountHeaderOfSection('integrationCredentials')}
                      <div className='form-row row m-4 mb-5'>
                        {mountCredentialsFields(formFields?.credentials)}
                      </div>
                    </>
                  )}
                  {/* end credentials section */}

                  <div className='row m-4 config-integration--footer'>
                    {appState.hasApiKey !== 'true' && (
                      <div className='col-12 col-md-7 px-0 ps-md-0 pe-md-3 mb-3 mb-md-0'>
                        <Alert
                          id='request_alert_error'
                          alertType={`d-flex alert-error --icon mb-0`}
                          iconClassName='fas fa-circle-exclamation'
                        >
                          {props.intl.messages['integrationAlertMessageAPI']}
                        </Alert>
                      </div>
                    )}
                    <div className='col-12 col-md-2 px-0 ps-md-0 pe-md-2 ms-auto mb-3 mb-md-0'>
                      <Button
                        className='button button-outline'
                        onClick={() => history.goBack()}
                      >
                        {props.intl.messages['cancel']}
                      </Button>
                    </div>
                    <div className='col-12 col-md-3 px-0 pe-md-0 ps-md-2'>
                      <Button
                        className='button button-primary'
                        disabled={!isRequiredFieldsFilled}
                        onClick={onSubmit}
                      >
                        {props.intl.messages['saveAndContinue']}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isSubmitted && !hasRequestErrors && (
          <SuccessModal
            messageKey='dataSavedSuccess'
            onClose={async () => {
              if (formFields?.configurations) {
                setIsSubmitted(false);
              } else {
                history.goBack();
              }
            }}
          />
        )}

        {isLoading && (
          <Modal
            isLoading
            titleKey={'configuringIntegrationTitle'}
            subtitleKey={
              formFields?.configurations ? 'configuringIntegrationSubtitle' : ''
            }
            alert={{
              show: true,
              type: 'info',
              message: 'configuringIntegrationMessage',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(ConfigureIntegration);
