import {
  API_V3_PATH_BASE,
  FETCH_DOCUMENTS_PATHS,
  SUPPLIERS_PATH,
} from '../../../../constants';
import { lowerCaseFirstLetter } from '../../../../formatters/string';
import { fetchData } from '../../invoices/util/api/request';
import {
  buildDocumentsRequestParams,
  buildSuppliersPerPageBody,
} from '../../invoices/util/api/requestHelper';
import { DEFAULT_DOCUMENTS_RESPONSE } from '../../../../constants/defaultValues';

/**
 * Performs an async request to v3
 * Gets the supplier settings information
 * @function
 * @param {string} supplierToken - supplier's token
 * @returns {Array} JSON API response
 */
export async function fetchSupplierSettings(supplierToken) {
  return await fetchData(
    `${API_V3_PATH_BASE}${SUPPLIERS_PATH}/${supplierToken}/settings`,
    'supplier settings'
  );
}

/**
 * Performs an async request to v3
 * Gets the documents considering the search filters applied
 * @function
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - JSON with all filters.
 * @param {object} windowLocation - window location property.
 * @param {string} supplierToken - Supplier's token.
 * @returns {object} JSON API response
 */
export async function fetchSuppliersDocuments(
  accountId,
  language,
  documentsTab,
  filters,
  windowLocation,
  supplierToken
) {
  let queryString = buildDocumentsRequestParams(
    language,
    filters,
    windowLocation,
    documentsTab
  );

  return await fetchData(
    `${
      FETCH_DOCUMENTS_PATHS[lowerCaseFirstLetter(documentsTab)]
    }/${supplierToken}?${queryString}`,
    'documents',
    {},
    'Suppliers'
  );
}

/**
 * Fetches supplier settings and documents data
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - applied search filters
 * @param {object} windowLocation - location property
 * @param {string} supplierToken - supplier's token
 * @returns {object} JSON API response
 * @function
 */
export async function fetchCriticalData(
  accountId,
  language,
  documentsTab,
  filters,
  windowLocation,
  supplierToken
) {
  let documents = DEFAULT_DOCUMENTS_RESPONSE;

  const supplierSettings = await fetchSupplierSettings(supplierToken);

  documents = await fetchSuppliersDocuments(
    accountId,
    language,
    documentsTab,
    filters,
    windowLocation,
    supplierToken
  );

  if (
    documents.hasOwnProperty('error') ||
    supplierSettings.hasOwnProperty('error')
  ) {
    return {};
  } else {
    return { supplierSettings, documents };
  }
}

/**
 * Performs an async request to v3
 * Gets the new page of documents considering the search filters applied
 *
 * @function
 *
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - JSON with all filters.
 * @param {string} supplierToken - supplier's token.
 *
 * @returns {object} JSON API response
 */
export async function fetchSuppliersPage(
  accountId,
  language,
  documentsTab,
  filters,
  supplierToken
) {
  const options = {
    method: 'POST',
  };

  options.body = buildSuppliersPerPageBody(
    accountId,
    language,
    filters,
    documentsTab
  );

  return await fetchData(
    `${
      FETCH_DOCUMENTS_PATHS[lowerCaseFirstLetter(documentsTab)]
    }/${supplierToken}`,
    'supplier documents',
    options
  );
}
