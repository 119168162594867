import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../atoms/buttons/Button';
import Input from '../../../atoms/inputs/Input';

import { ReactComponent as IXLogo } from '../../../../assets/imgs/ix-logo-horiz.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Anchor from '../../../atoms/anchors/Anchor';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import { REGISTER_PATH } from '../../../../constants';

import { validateEmailFormat } from '../../../../helpers/validateFormatHelper';
import Alert from '../../../molecules/alerts/Alert';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import StorageService from '../../../../services/StorageService';
import { STORAGE_KEYS } from '../../../../constants/storage';

const ForgotPassword = ({ form, setForm, intl }) => {
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleInput = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * Verify if login is valid
   * @param {string} login
   */
  const verifyEmail = (login) => {
    setIsEmailInvalid(!validateEmailFormat(login));
  };

  const isRequiredFieldsFilled = () => {
    return form.email !== '' && !isEmailInvalid;
  };

  const onSubmit = async () => {
    if (isRequiredFieldsFilled()) {
      const response = await AuthenticationService.recoverPassword({
        user: { ...form },
      });
      if (!response.error) {
        const Storage = new StorageService();
        Storage.setItem(
          STORAGE_KEYS.EMAIL,
          JSON.stringify({
            email: form.email,
          })
        );
        setMessage(response.message);
        return;
      }
      setHasError(true);
    }
  };

  useEffect(() => {
    async function submitInfo() {
      if (enterPressed) {
        await onSubmit();

        setEnterPressed(false);
      }
    }
    submitInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed]);

  useEffect(() => {
    const listener = async (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        setEnterPressed(true);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='row --login-wrapper'>
      <div className='col-lg-6 col-xs-12 --login-wrapper-image'>
        <div className='row'>
          <div className='col-12 m-2'>
            <IXLogo className='--logo' />
            <Paragraph className='text-header h2 mt-2'>
              <FormattedMessage
                id='loginDescription'
                values={{
                  r: (...chunks) => (
                    <Span className='font-regular'>
                      {chunks}
                      <br />
                    </Span>
                  ),
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </Paragraph>
          </div>

          <div className='col-12 mt-4 --login-wrapper-links'>
            <Anchor href={buildPath(REGISTER_PATH().LOGIN)}>
              {intl.messages['loginTitle']} |
            </Anchor>
            <Anchor href={buildPath(REGISTER_PATH().CREATE_ACCOUNT)}>
              {' '}
              {intl.messages['createAccountLink']}
            </Anchor>
          </div>
        </div>
      </div>

      <div className='col-lg-6 col-xs-12 pb-4 --login-form'>
        <div className='column form-container'>
          <div className='col-10 mt-4'>
            <Paragraph className='text-header h2 mb-2'>
              {intl.messages['forgotPasswordTitle']}
            </Paragraph>
            <Paragraph className='text-paragraph mb-2'>
              {intl.messages['forgotPasswordDescription']}
            </Paragraph>

            {hasError && (
              <Alert
                id='request_alert_error'
                alertType={`alert-error --icon`}
                iconClassName='fas fa-circle-exclamation'
              >
                {intl.messages['invalidEmail']}
              </Alert>
            )}

            {message !== '' && (
              <Alert
                id='request_alert_success'
                alertType={`alert-information --icon`}
                iconClassName='fas fa-circle-exclamation'
              >
                {message}
              </Alert>
            )}
            <label className='text-label mt-3'>{intl.messages['email']}:</label>
            <Input
              id='email_input'
              type='text'
              value={form.email}
              onChange={(e) => handleInput('email', e.target.value)}
              onBlur={(e) => verifyEmail(e.target.value)}
              hasError={isEmailInvalid}
              placeholder='exemplo@login.com'
            />
          </div>
          <div className='col-10 mt-4'>
            <Button
              id='forgot_password_submit'
              className='button button-primary d-block'
              label='send'
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ForgotPassword);
