import RequestAdapter from '../adapters/RequestAdapter';
import { _formatISODate } from '../components/molecules/forms/util/dateIntervalHelper';
import { API_V3_PATH_BASE, V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Get the list of contacts
 *
 * @function
 *
 * @param {object} params
 *
 * @returns {object} JSON response
 */
const getAll = async (params) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.LIST}?${params}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Get account's details
 *
 * @param {number} clientId
 * @returns
 */
const getById = async (clientId) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.DETAILS(clientId)}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Update account by id
 *
 * @function
 *
 * @param {number} clientId - ID of the client's account
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const updateById = async (clientId, formData) => {
  let url = `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.DETAILS(clientId)}`;

  const requestConfig = {
    method: 'PUT',
    url,
    body: JSON.stringify({
      client: { ...formData, send_options: formData.document_copies },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Delete account by id
 *
 * @function
 *
 * @param {number} clientId - ID of the client's account
 *
 * @returns {object} JSON response
 */
const deleteById = async (clientId) => {
  let url = `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.DETAILS(clientId)}`;

  const config = {
    method: 'DELETE',
    url,
  };

  return await RequestAdapter.request(config);
};

/**
 * Create account
 *
 * @function
 *
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const create = async (formData) => {
  const url = `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.CREATE}`;

  const requestConfig = {
    url,
    method: 'POST',
    body: JSON.stringify({
      client: { ...formData, send_options: formData.document_copies },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Create a financial movement by client
 *
 * @async
 * @param {string} clientId
 * @param {object} formData
 * @returns {Promise}
 */
const createTreasuryMovement = async (clientId, formData) => {
  const formDataFormatted = {
    treasury_movement: {
      ...formData,
      value: Number(formData.value),
      movement_type: formData.movement_type,
      date: _formatISODate(formData.date),
    },
  };

  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.TREASURY_MOVEMENT(clientId)}`,
    method: 'POST',
    body: JSON.stringify(formDataFormatted),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * @async
 * @param {string} clientId
 * @param {object} formData Email content
 */
const sendCheckingAccountByEmail = async (clientId, formData) => {
  const { email, save, ...rest } = formData;
  const formDataFormatted = {
    send_open_account_link_mail: {
      ...rest,
      client: {
        email,
        save,
      },
      notification_type: 'open_account_link',
    },
  };

  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.CHECKING_ACCOUNT_EMAIL(clientId)}`,
    method: 'POST',
    body: JSON.stringify(formDataFormatted),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Get client's bank account
 *
 * @function
 *
 * @param {object} params
 *
 * @returns {object} JSON response
 */
const getClientsBankAccount = async (token) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.BANK_ACCOUNT_INFO(token)}`,
    method: 'GET',
  };

  return await RequestAdapter.externalRequest(requestConfig);
};

/**
 * Update user data by id
 *
 * @function
 *
 * @param {number} clientId - ID of the client's account
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const updateUserById = async (clientId, formData) => {
  let url = `${V2_PATH_BASE}${ENDPOINTS.USER.DATA(clientId)}.json`;

  const requestConfig = {
    method: 'PUT',
    url,
    body: JSON.stringify({
      user: formData,
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Get user data by id
 *
 * @function
 *
 * @param {number} clientId - ID of the client's account
 *
 * @returns {object} JSON response
 */
const getUserById = async (clientId) => {
  let url = `${V2_PATH_BASE}${ENDPOINTS.USER.DATA(clientId)}`;

  const requestConfig = {
    method: 'get',
    url,
  };

  return await RequestAdapter.request(requestConfig);
};

const getAccountSettings = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.FISCAL_CONFIGURATION.ACCOUNT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

const updateAccountSettings = (newSettings) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.FISCAL_CONFIGURATION.ACCOUNT}`,
    method: 'PUT',
    body: JSON.stringify(newSettings),
  };

  return RequestAdapter.request(requestConfig);
};

const vatNumberLookup = (fiscal_id, iso_code) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.FISCAL_CONFIGURATION.NIF_VALIDATION}?country=${iso_code}&tin=${fiscal_id}`,
    method: 'GET',
  };
  return RequestAdapter.request(requestConfig);
};

export const ContactService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,

  createTreasuryMovement,
  sendCheckingAccountByEmail,
  getClientsBankAccount,

  updateUserById,
  getUserById,
  getAccountSettings,
  updateAccountSettings,
  vatNumberLookup,
};
