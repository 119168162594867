import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Input from '../../../atoms/inputs/Input';
import Select from '../../../atoms/selects/Select';
import {
  decodeNumberOfCopies,
  decodePaymentDays,
  documentNumberOfCopies,
  encodeNumberOfCopies,
  fillTaxExemptionReason,
  paymentDays,
} from '../../../templates/helper/documentInformationHelper';
import { computeAccountPaymentDaysOption } from '../util/selectsHelper';

const DocumentInformation = (props) => {
  const { taxExemptionReason, numberOfCopies, accountPaymentDays } =
    props.state;

  const setHandler = (key, value) => {
    props.setUserChangedInput(true);
    props.handler({
      ...props.state,
      [key]: value,
    });
  };

  const [accountPaymentDaysOption, setAccountPaymentDaysOption] = useState(
    computeAccountPaymentDaysOption(accountPaymentDays)
  );

  const shouldShowOtherOption = useCallback(() => {
    return accountPaymentDaysOption === 'other';
  }, [accountPaymentDaysOption]);

  const [showOtherOption, setShowOtherOption] = useState(shouldShowOtherOption);
  const [otherOptionValue, setOtherOptionValue] = useState(accountPaymentDays);

  useEffect(() => {
    setShowOtherOption(shouldShowOtherOption());
    setOtherOptionValue(accountPaymentDays || '0');
    setAccountPaymentDaysOption(
      computeAccountPaymentDaysOption(accountPaymentDays)
    );
  }, [accountPaymentDays, accountPaymentDaysOption, shouldShowOtherOption]);

  /**
   * Taking into consideration the option selected, we should
   * update the account payment days state that is stored on the
   * main component
   *
   * @function
   *
   * @param {string} eventValue - the option selected on the dropdown
   */
  const handlePaymentDaysChange = (eventValue) => {
    if (showOtherOption && eventValue !== 'other') {
      // resets to initial state
      setOtherOptionValue(accountPaymentDays);
      setShowOtherOption(false);
    }

    setAccountPaymentDaysOption(computeAccountPaymentDaysOption(eventValue));
    setHandler('accountPaymentDays', decodePaymentDays(eventValue));
  };

  return (
    <div className='row px-0 mx-0 content-block options-block'>
      <div className='text-header h3 section-title'>
        {props.intl.messages['documentsData']}
      </div>
      <div className='form-container px-0'>
        <div className='form-row row px-0 mx-0'>
          <div className='col-12 px-0'>
            <label className='text-label'>
              {props.intl.messages['taxExemptionReason']}:
            </label>
            <Select
              id='tax_exemption_select'
              value={taxExemptionReason || 'M00'}
              options={fillTaxExemptionReason()}
              onChange={(e) => setHandler('taxExemptionReason', e.target.value)}
            />
          </div>
        </div>
        <div className='form-row row px-0 mx-0'>
          <div className='col-sm-6 ps-0'>
            <label className='text-label'>
              {props.intl.messages['numCopies']}:
            </label>
            <Select
              id='number_of_copies_select'
              value={encodeNumberOfCopies(numberOfCopies)}
              options={Object.values(documentNumberOfCopies())}
              onChange={(e) => {
                setHandler(
                  'numberOfCopies',
                  decodeNumberOfCopies(e.target.value)
                );
              }}
            />
          </div>
          <div className='col-sm-6 pe-0'>
            <label className='text-label'>
              {props.intl.messages['paymentDays']}:
            </label>
            <Select
              id='payment_days_select'
              value={accountPaymentDaysOption}
              options={Object.values(paymentDays()).concat('other')}
              onChange={(e) => handlePaymentDaysChange(e.target.value)}
            />

            {showOtherOption && (
              <div className='other-input d-flex-row align-items-center px-0'>
                <span className='text-paragraph'>
                  {props.intl.messages['numberPaymentDays']}
                </span>
                <Input
                  id='payment_days_other_input'
                  type='number'
                  value={otherOptionValue}
                  onChange={(e) => setOtherOptionValue(e.target.value)}
                  onBlur={() =>
                    setHandler('accountPaymentDays', otherOptionValue)
                  }
                  minValue={0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DocumentInformation);
