import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowSimple = (props) => {
  const addUserEmail = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['email']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.email}
        </div>
      </div>
    );
  };

  const addUserRole = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['profile']}:`}
        />
        <div className='text-description'>
          {props.intl.messages[document.role]}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card --simple --manage-user-cell`}>
        <div className='cell checkbox-cell'>
          <CheckBoxLabel
            className='checkbox'
            checked={props.selectedLines.indexOf(props.document.id) !== -1}
            onChange={() => {
              props.setSelectedLines(props.document.id);
            }}
            disabled={
              props.userId === String(props.document.id) || props.document.owner
            }
          />
        </div>
        <div
          className='mobile-left'
          onClick={
            props.userId === String(props.document.id) || props.document.owner
              ? () => {}
              : () => {
                  props.openModal(props.document);
                }
          }
        >
          {addUserEmail(props.document)}
          {addUserRole(props.document)}
        </div>
      </div>
    </>
  );
};

RowSimple.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
};

export default injectIntl(RowSimple);
