import React, { Component } from 'react';
import RightMenu from '../../organisms/containers/schedules/RightMenu';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import SearchBox from '../../organisms/containers/schedules/SearchBox';
import TableSimple from '../../organisms/tables/TableSimple';
import { parseAndSetSearchFilters } from '../invoices/util/filters/filtersManager';
import { documentsPerPage } from '../util/listing/items-per-page/itemsPerPageManager';
import { schedulesSortOptions } from '../../templates/invoices/util/filters/defaultFilters';
import { fetchSchedulesPage } from '../util/api/schedulesRequest';
import { sortDocuments } from '../util/listing/sort/sortDocumentsManager';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import * as queryStringManager from '../util/api/queryStringManager';
import * as request from '../invoices/util/api/request';
import * as templateInformation from '../helper/templateInformationHelper';
import * as stateManager from '../state-manager/stateManager';
import { FormattedMessage } from 'react-intl';
import TableContainer from '../../organisms/containers/table-container/TableContainer';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import { CREATE_SCHEDULES_PATHS } from '../../../constants';

/**
 * Main component (entry point) for schedules listing
 * @class
 * Renders all the main elements
 * Sets the main app state
 * Holds the main functions to change the state and perform external requests
 */
export default class SchedulesLayout extends Component {
  _isMounted = false;

  /**
   * @constructor
   * Sets all the app state
   * @param {object} props - React props object (account_id, user_id, location object)
   */
  constructor(props) {
    super(props);

    this.state = {
      globalResetKey: 1,
      globalTableKey: 1,
      accountId: props.accountId,
      userId: props.userId,
      language: props.language,
      windowLocation: props.windowLocation,
      isLoading: true,
      isLoadingFirstRequest: true,
      documents: [],
      numberOfPages: null,
      accountSettings: {},
      filters: parseAndSetSearchFilters(props),
      totals: {},
      showMobileMenu: false,
      mobileSideContent: '',
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.setDocumentsAndTotals = this.setDocumentsAndTotals.bind(this);
    this.getDocumentsByTextInput = this.getDocumentsByTextInput.bind(this);
    this.setItemsPerPage = this.setItemsPerPage.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.sortDocumentsByPropertyAndOrder =
      this.sortDocumentsByPropertyAndOrder.bind(this);
    this.getDocumentsPage = this.getDocumentsPage.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
    const { accountId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;

    const { accountSettings, documents } = await request.fetchCriticalData(
      accountId,
      language,
      documentsTab,
      filters,
      windowLocation
    );

    // Critical request went wrong
    if (typeof documents === 'undefined') {
      return;
    }

    if (this._isMounted) {
      this.setState({
        accountSettings: accountSettings,
        documents: documents.schedules,
        totals: document.totals,
        numberOfPages: documents.totals.pages,
        isLoading: false,
        isLoadingFirstRequest: false,
      });
    }
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by text
   * @function
   * @param {string} searchTerm - Page number to be requested.
   */
  async getDocumentsByTextInput(searchTerm) {
    const queryStringParams = { text: searchTerm, page: 1 };
    const { stateCopy, response } = await stateManager.setComponentState(
      queryStringParams,
      this.state,
      this.props.documentsTab
    );

    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;

    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and change items per page
   * @function
   * @param {string} itemsToShow - items per page requested.
   */
  async setItemsPerPage(itemsToShow) {
    const extraInformation = {
      itemsPerPage: itemsToShow,
    };

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          itemsPerPage: itemsToShow,
          page: 1,
        },
        globalTableKey: prevState.globalTableKey + 1,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    await documentsPerPage(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * sort by document property and order
   * @function
   * @param {string} sort - document property.
   * @param {string} sortOrder - sort order applied.
   */
  async sortDocumentsByPropertyAndOrder(sort, sortOrder) {
    const extraInformation = {
      sortArgument: sort,
      sortOrderArgument: sortOrder,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sort: sort,
          sortOrder: sortOrder,
          page: 1,
        },
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await sortDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Check page number boundaries and prepare filters for API request
   * Search specific page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  async getDocumentsPage(nextPage) {
    const stateCopy = this.state;
    stateCopy.filters.page = nextPage;
    stateCopy.globalTableKey += 1;
    stateCopy.isLoading = true;
    this.setState({ ...this.state, stateCopy });

    queryStringManager.updateQueryStringParam(
      'page',
      nextPage,
      this.state.windowLocation
    );

    const response = await fetchSchedulesPage(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Sets the app state taking into account the API response.
   * @function
   * @param {object} response - JSON with search result (documents & summary).
   */
  setDocumentsAndTotals(response) {
    // Totals should be set only when there are no selection
    let totals = response.totals;
    let numberOfDocuments = totals.count;

    if (this._isMounted) {
      this.setState({
        documents: response.schedules,
        totals: totals,
        numberOfDocuments: numberOfDocuments,
        documentsNumber: totals.count,
        numberOfPages: response.totals.pages,
        isLoading: false,
      });
    }
  }

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  resetAllFilters() {
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      this.state.filters.page
    );
    queryStringManager.clearQueryString(
      this.state.windowLocation,
      queryStringWithPage
    );
    this.clearListingData(queryStringWithPage);
  }

  /**
   * Sets the listing data back to default.
   * @function
   */
  async clearListingData(queryStringWithPage) {
    await this.fetchDefaultListingData();
    const newGlobalResetKey = this.state.globalResetKey + 1;
    let queryString = '';

    if (typeof queryStringWithPage === 'string') {
      queryString += queryStringWithPage;
    }

    const newFilters = parseAndSetSearchFilters({
      itemsPerPage: this.props.itemsPerPage,
      windowLocation: { search: queryString },
      documentsTab: this.props.documentsTab,
    });

    this.setState({
      filters: newFilters,
      globalResetKey: newGlobalResetKey,
    });
  }

  /**
   * Gets the default documents and summary.
   * @function
   */
  async fetchDefaultListingData() {
    this.initialState.windowLocation.search = '';
    this.initialState.filters.itemsPerPage = this.props.itemsPerPage;

    // We don't need account settings again
    const documentsResponse = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.initialState.filters,
      this.initialState.windowLocation
    );

    this.setDocumentsAndTotals(documentsResponse);
  }

  /**
   * Opens the mobile side menu.
   * @param {string} sideContent - the side content to display: options and information.
   * @function
   */
  openMobileMenu(sideContent) {
    this.setState({ showMobileMenu: true, mobileSideContent: sideContent });
  }

  /**
   * Closes the mobile side menu.
   * @function
   */
  closeMobileMenu() {
    helpScoutBeaconHelper.changeVisibility(false);
    this.setState({ showMobileMenu: false, mobileSideContent: '' });
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/rendering-elements.html
   * @function
   * @returns {object} React fragment. Read: https://reactjs.org/docs/fragments.html
   */
  render() {
    const {
      totals,
      filters,
      showMobileMenu,
      mobileSideContent,
      globalResetKey,
      globalTableKey,
      isLoadingFirstRequest,
      isLoading,
      documents,
      numberOfPages,
      accountSettings,
    } = this.state;

    return (
      <>
        <TableContainer
          className=''
          message={<FormattedMessage id='schedules' />}
          searchBox={
            <SearchBox
              key={globalResetKey}
              filters={filters}
              getDocumentsByTextInput={this.getDocumentsByTextInput}
              searchByPlugins={() => {}}
              anchorActive
              anchorHref={buildPath(
                CREATE_SCHEDULES_PATHS['createNewSchedule']
              )}
              anchorLabel={'createNewSchedule'}
            />
          }
          table={
            <TableSimple
              globalResetKey={globalResetKey}
              globalTableKey={globalTableKey}
              loadingFirstRequest={isLoadingFirstRequest}
              loading={isLoading}
              documents={documents}
              filters={filters}
              numberOfPages={numberOfPages}
              accountSettings={accountSettings}
              getDocumentsPage={this.getDocumentsPage}
              sortOptions={schedulesSortOptions}
              sortDocumentsByPropertyAndOrder={
                this.sortDocumentsByPropertyAndOrder
              }
              setItemsPerPage={this.setItemsPerPage}
              resetAllFilters={this.resetAllFilters}
              documentsTab={this.props.documentsTab}
              totals={totals}
              openMobileMenu={this.openMobileMenu}
              mobileSideContent={mobileSideContent}
              closeMobileMenu={this.closeMobileMenu}
            />
          }
          rightMenu={
            <RightMenu
              mobileSideContent={mobileSideContent}
              closeMobileMenu={this.closeMobileMenu}
              documentsTab={this.props.documentsTab}
            />
          }
          mobileMenu={
            <MobileMenu
              showMobileMenu={showMobileMenu}
              closeMobileMenu={this.closeMobileMenu}
              sideContent={mobileSideContent}
            />
          }
        />
      </>
    );
  }
}
