import { injectIntl } from 'react-intl';
import React from 'react';
import TextArea from '../../../atoms/textareas/TextArea';

const AdditionalInfo = (props) => {
  const { observations, footer } = props.state;

  const setHandler = (key, value) => {
    props.setUserChangedInput(true);
    props.handler({
      ...props.state,
      [key]: value,
    });
  };

  return (
    <div className='row px-0 mx-0 content-block additional-data-block'>
      <div className='text-header h3'>
        {props.intl.messages['additionalInfo']}
      </div>

      <div className='form-container px-0'>
        {/* NOTE: limits are not final  */}
        {props.allDocsOptionsActive && (
          <>
            <label className='text-label'>
              {props.intl.messages['observations']}:
            </label>
            <TextArea
              id='observations_input'
              limit={'400'}
              className='--large'
              value={observations || ''}
              placeholder='observationsPlaceholder'
              onChange={(e) => setHandler('observations', e.target.value)}
            />
          </>
        )}
        <label className='text-label'>{props.intl.messages['footer']}:</label>
        <TextArea
          id='footer_input'
          limit={'200'}
          placeholder='docFooterPlaceholder'
          value={footer || ''}
          onChange={(e) => setHandler('footer', e.target.value)}
        />
      </div>
    </div>
  );
};

export default injectIntl(AdditionalInfo);
