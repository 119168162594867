import React from 'react';
import { injectIntl } from 'react-intl';
import Image from '../../atoms/images/Image';
import Anchor from '../../atoms/anchors/Anchor';
import { buildPath } from '../../organisms/containers/util/pathHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object (options).
 */

const DocumentPreview = (props) => {
  const {
    organization_name,
    address,
    postal_code,
    city,
    country,
    phone,
    email,
    website,
    share_capital,
    register_office,
    fiscal_id,
  } = props.documentData;

  return (
    <>
      <div className='document-preview px-0'>
        <div className='document-page'>
          <div className='logo'>
            <Image src={props.logo} />
          </div>
          <div className='row'>
            <ul className='col-sm-6 document-preview-data'>
              <li className='bold'>{organization_name}</li>
              <li>{address}</li>
              <li>
                {postal_code} {city}
              </li>
              <li>{country}</li>
              {email && (
                <li>
                  <span className='bold'>E-mail: </span>
                  {email}
                </li>
              )}
              {phone && (
                <li>
                  <span className='bold'>Tel.: </span>
                  {phone}
                </li>
              )}
              {website && (
                <li>
                  <span className='bold'>Web: </span>
                  {website}
                </li>
              )}
              {fiscal_id && (
                <li>
                  <span className='bold'>{props.intl.messages['w/Vat']}: </span>
                  {fiscal_id}
                </li>
              )}
              {share_capital && (
                <li>
                  <span className='bold'>
                    {props.intl.messages['shareCapital']}:{' '}
                  </span>
                  {share_capital}
                </li>
              )}
              {register_office && (
                <li>
                  <span className='bold'>
                    {props.intl.messages['registerOffice']}:{' '}
                  </span>
                  {register_office}
                </li>
              )}
            </ul>
            <ul className='col-sm-6 dummy-data'>
              <li>{props.intl.messages['sampleClient']}</li>
              <li>{props.intl.messages['sampleAddress']}</li>
              <li>{props.intl.messages['samplesPostalCode']}</li>
              <li>Portugal</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='text-align-right px-0'>
        {/* Temporary link */}
        <Anchor
          className='color-brand-primary'
          href={buildPath(`accounts/${props.accountId}/edit`)}
        >
          {props.intl.messages['editFiscalData']}
        </Anchor>
      </div>
    </>
  );
};

export default injectIntl(DocumentPreview);
