import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { TemplateHelpers } from '../helpers';
import Anchor from '../../../../atoms/anchors/Anchor';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import { IX_WEBSITE } from '../../../../../constants';
import { PREVIEW_CONTENT_TYPE } from '../../../../../constants/textEditor';
import {
  buildEmailContent,
  buildEmailVariables,
} from '../../../../templates/util/templates/displayDummyInfoHelper';
import Skeleton from 'react-loading-skeleton';

const EmailAlertsTemplate = (props) => {
  const [previewEmail, setPreviewEmail] = React.useState('');
  const [previewVariables, setPreviewVariables] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (props.emailContent) {
      setPreviewEmail(buildEmailContent(props.emailContent, previewVariables));
    }

    // eslint-disable-next-line
  }, [props.emailContent]);

  useEffect(() => {
    setPreviewVariables(buildEmailVariables(PREVIEW_CONTENT_TYPE.EMAIL_ALERTS));

    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className='template-email'>
      <div className='template-body bg-ix-white'>
        {TemplateHelpers.buildAccountLogo(props)}
        <div className='template-content flex'>
          {!isLoading ? parse(previewEmail) : <Skeleton height={230} />}
        </div>
        {!props.removeButton && (
          <>
            <div className='sep'></div>
            <div className='template-doc-info text-align-center'>
              <Anchor className='button button-primary text-align-center'>
                {props.intl.messages['viewDoc']}
              </Anchor>
            </div>
          </>
        )}
      </div>
      <div className='template-footer px-0 pb-0 text-align-center color-gray-base'>
        <Paragraph>
          Powered by{' '}
          <Anchor href={IX_WEBSITE} className='color-brand-primary'>
            InvoiceXpress
          </Anchor>
        </Paragraph>
        <Paragraph>{props.intl.messages['onlineSoftware']}</Paragraph>
      </div>
    </div>
  );
};

EmailAlertsTemplate.propTypes = {
  emailContent: PropTypes.string,
  hrefDocument: PropTypes.string,
  userAccount: PropTypes.string,
  accountLogo: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(EmailAlertsTemplate);
