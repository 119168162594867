import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Head from '../../molecules/dropdowns/Head';
import {
  calculateNumberOfSelectedFilters,
  changeFilterValue,
  changeAllFilterValues,
  determineSelectAllFilterValue,
  buildLabelForCountableFilters,
  clickOutsideDropdown,
  hasDefaultFiltersApplied,
} from './util/filterSelectionHelper';
import { getDefaultDocumentTypes } from '../../templates/invoices/util/filters/filterManagerHelper';
import { updateSearchByType } from './util/stateManager';

/**
 * React component
 * @class
 * Dropdown for document type search.
 */
class SearchByType extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    const numberOfFiltersApplied = calculateNumberOfSelectedFilters(
      props.types
    );
    const defaultTypes = { ...getDefaultDocumentTypes() };
    const maxFiltersApplied = Object.keys(defaultTypes).length;
    const selectAllValue = determineSelectAllFilterValue(
      numberOfFiltersApplied,
      maxFiltersApplied
    );

    this.state = {
      isVisible: false,
      filterDisabled: true,
      selectAllValue: selectAllValue,
      typesApplied: { ...props.types },
      numberOfFiltersApplied: numberOfFiltersApplied,
      maxFiltersApplied: maxFiltersApplied,
      isResetVisible: false,
      defaultTypes: defaultTypes,
    };

    this.showTypes = this.showTypes.bind(this);
    this.clickOnTypeLabel = this.clickOnTypeLabel.bind(this);
    this.clickOnSelectAll = this.clickOnSelectAll.bind(this);
    this.clickOnFilterButton = this.clickOnFilterButton.bind(this);
  }

  /**
   * Click on dropDown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showTypes = (e) => {
    e.preventDefault();
    if (this.state.isVisible) {
      this.setState({ isVisible: false });
    } else {
      this.setState({ isVisible: true });
    }
  };

  /**
   * Hanlde click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Creates a child component for each type filter.
   * @function
   * @param {object} types - JSON object with each type filter.
   * @returns {object} array object with all child components.
   */
  addDocumentTypes = (types) => {
    let childComponents = [];
    for (let element in types) {
      childComponents.push(
        <li key={element}>
          <CheckBoxLabel
            id={element}
            className='checkbox'
            checked={types[element]}
            onChange={this.clickOnTypeLabel}
          >
            {element}
          </CheckBoxLabel>
        </li>
      );
    }
    return childComponents;
  };

  /**
   * Handles the click over any type filter.
   * @function
   * @param {object} e - event.
   */
  clickOnTypeLabel = (e) => {
    const newFilters = changeFilterValue(e.target.id, this.state.typesApplied);
    const newNumberOfFiltersApplied =
      calculateNumberOfSelectedFilters(newFilters);
    const newSelectAllValue = determineSelectAllFilterValue(
      newNumberOfFiltersApplied,
      this.state.maxFiltersApplied
    );

    this.setState({
      typesApplied: newFilters,
      numberOfFiltersApplied: newNumberOfFiltersApplied,
      selectAllValue: newSelectAllValue,
      filterDisabled: false,
    });
  };

  /**
   * Handles the click over Select All filter.
   * @function
   * @param {object} e - event, not used in this function.
   */
  clickOnSelectAll = (e) => {
    const selectAllNewValue = !this.state.selectAllValue;
    const newFilters = changeAllFilterValues(
      this.state.typesApplied,
      selectAllNewValue
    );

    this.setState({
      typesApplied: newFilters,
      numberOfFiltersApplied: calculateNumberOfSelectedFilters(newFilters),
      selectAllValue: selectAllNewValue,
      filterDisabled: false,
    });
  };

  /**
   * Handles click on the reset button.
   * @function
   */
  resetFilter = () => {
    const numberOfFiltersApplied = calculateNumberOfSelectedFilters(
      this.state.defaultTypes
    );
    const selectAllValue = determineSelectAllFilterValue(
      numberOfFiltersApplied,
      this.state.maxFiltersApplied
    );

    this.setState({
      isVisible: false,
      selectAllValue: selectAllValue,
      typesApplied: { ...this.state.defaultTypes },
      numberOfFiltersApplied: calculateNumberOfSelectedFilters(
        this.state.defaultTypes
      ),
    });

    this.props.filterByType(this.state.defaultTypes);
  };

  /**
   * Handles the click over filter button.
   * @function
   * @param {object} e - event, not used in this function.
   */
  clickOnFilterButton = () => {
    this.setState({
      isVisible: false,
    });

    this.props.filterByType(this.state.typesApplied);
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    const showReset = !hasDefaultFiltersApplied(
      { ...this.state.defaultTypes },
      { ...this.state.typesApplied }
    );

    this.setState({ isResetVisible: showReset });

    const stateToUpdate = updateSearchByType(this.props, prevProps);

    if (stateToUpdate) {
      this.setState(stateToUpdate);
    }
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const {
      isVisible,
      filterDisabled,
      selectAllValue,
      typesApplied,
      numberOfFiltersApplied,
      isResetVisible,
    } = this.state;

    const cssClass = isVisible ? 'expanded' : 'collapsed';
    const headerLabel = buildLabelForCountableFilters(
      numberOfFiltersApplied,
      this.state.maxFiltersApplied
    );

    return (
      <div className={`search-filter js-search-filter ${cssClass}`}>
        <Head
          name='documentTypes'
          result={headerLabel}
          reference={this.headerRef}
          showBody={this.showTypes}
        />
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <div className='title-button'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages['documentTypes']}
              </div>
              <div className='reset-button'>
                {isResetVisible && (
                  <Button
                    id='resetFilterTypes'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={this.resetFilter}
                  >
                    <Icon className='icon fas fa-xmark' />
                  </Button>
                )}
              </div>
            </div>
            <ul>
              <li>
                <CheckBoxLabel
                  id='selectAll'
                  className='checkbox color-gray-base'
                  checked={selectAllValue}
                  onChange={this.clickOnSelectAll}
                >
                  {'selectAll'}
                </CheckBoxLabel>
              </li>
              {this.addDocumentTypes(typesApplied)}
            </ul>
            <div className='sep' />
            <div className='margin-top text-align-center'>
              <Button
                id='filterType'
                className='button button-primary --small'
                type='button'
                label='filter'
                disabled={filterDisabled}
                onClick={this.clickOnFilterButton}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SearchByType);
