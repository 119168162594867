import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import MobileMenu from '../../../../organisms/containers/mobile/MobileMenu';
import MobileButtons from '../../../../organisms/buttons/MobileButtons';
import { errorKeys } from '../../../../../constants/documentCustomization';
import RequestHandelingAlert from '../../../../organisms/alerts/RequestHandelingAlert';
import { formatMoney } from '../../../../../formatters/money';
import { closeMobile } from '../../../helper/documentInformationHelper';
import { useParams } from 'react-router-dom';
import { buildPath } from '../../../../organisms/containers/util/pathHelper';
import ConfirmationModal from '../../../../organisms/modals/ConfirmationModal';
import Button from '../../../../atoms/buttons/Button';
import { SettingService } from '../../../../../services/SettingService';
import RightMenu from '../../../../organisms/containers/right-menu/RightMenu';
import { RightMenuHelper } from '../../../../organisms/containers/right-menu/helper';
import { ItemsLayoutHelper } from '../helper';

/**
 * @function
 */
const ViewItemLayout = (props) => {
  const urlParams = useParams();

  const [showMobileMenu, setOpenMobileMenu] = useState(false);
  const [mobileSideContent, setMobileSideContent] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [item, setItem] = useState({});

  const [requestErrors, setRequestErrors] = useState('');
  const [mobileContent, setMobileContent] = useState('');

  const formatCurrency = (value, currency) => {
    return formatMoney(value, currency, ',');
  };

  const infoCards = [
    {
      key: 'itemsUnitPrice',
      value: formatCurrency(item.unit_price || 0, item.currency?.code || 'EUR'),
    },
    {
      key: 'itemsPVP',
      value: formatCurrency(item.rrp || 0, item.currency?.code || 'EUR'),
    },
    {
      key: 'itemsUnitSold',
      value: item.unit_sold || 0,
    },
    {
      key: 'itemsTotalSold',
      value: formatCurrency(item.total_sold || 0, item.currency?.code || 'EUR'),
    },
  ];

  const renderSmallCard = (item) => {
    return (
      <div key={item.key} className='small-card-wrapper col-12 col-md-3 px-0'>
        <div className='small-card-container container-bg-white'>
          <div className='title'>{props.intl.messages[item.key]}</div>
          <div className='value color-brand-primary'>{item.value}</div>
        </div>
      </div>
    );
  };

  /**
   * Delete contact
   */
  const handleDelete = async () => {
    const response = await SettingService.deleteItems(urlParams.id);

    if (response && !response.errors) {
      window.location.href = buildPath('items');
      return;
    }

    setRequestErrors(errorKeys.deleteError);
  };

  useEffect(() => {
    const fetchItem = async () => {
      const response = await SettingService.getItemById(urlParams.id);
      if (response.error) {
        setRequestErrors(response.error);
        return;
      }

      setItem(response.item);
    };

    fetchItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='main-content' className='container --contacts --view-items'>
      <div className='row mx-0 justify-content-center'>
        <div className='col-12 px-lg-0 content-block page-header-block'>
          <div className='text-header h3 --secondary'>
            {props.intl.messages['itemsTitle']}
          </div>

          <MobileButtons
            hideSummary
            openMobileMenu={() => {
              setMobileContent('mobileButtons');
            }}
          />
        </div>

        <div className='col-lg-9 ps-lg-0'>
          <div className='page-sub-header-block'>
            <div className='text-header h2 mb-auto'>{item.code || ''}</div>
          </div>

          {requestErrors.length > 0 && (
            <RequestHandelingAlert error={requestErrors} />
          )}

          <div className='block-grid'>
            <div className='row mx-0 mt-0 grid-block two-cols-container --view-items-card'>
              {infoCards.map((item) => {
                return renderSmallCard(item);
              })}
            </div>
            <div className='row mx-0 grid-block multiple-content-container'>
              <div className='col-12 text-header h4'>
                {props.intl.messages['details']}
              </div>

              <div className='row mx-0 px-0'>
                <div className='col-6'>
                  <ul className='list-of-data'>
                    <li>
                      <span className='label'>
                        {props.intl.messages['code']}:
                      </span>
                      <span data-testid='name_text'>{item?.code || ''}</span>
                    </li>
                    <li>
                      <span className='label'>
                        {props.intl.messages['description']}:
                      </span>
                      <span data-testid='vat_text'>
                        {item?.description || '---'}
                      </span>
                    </li>
                    <li>
                      <span className='label'>
                        {props.intl.messages['itemsRetention']}:
                      </span>
                      <span data-testid='code_text'>
                        {item?.retention || '0'}%
                      </span>
                    </li>
                  </ul>
                </div>

                <div className='col-6'>
                  <ul className='list-of-data'>
                    <li>
                      <span className='label'>
                        {props.intl.messages['itemsUnit']}:
                      </span>
                      <span data-testid='unit_text'>
                        {props.intl.messages[`${item?.unit}`] || ''}
                      </span>
                    </li>
                    <li>
                      <span className='label'>
                        {props.intl.messages['vat']}:
                      </span>
                      <span data-testid='vat_text'>{`${item?.tax?.value}% - ${item?.tax?.name}`}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 pe-lg-0'>
          <RightMenu
            id='ds-right-menu'
            documentsTab={props.documentsTab}
            mobileContent={mobileContent}
            clearMobileContent={() => setMobileContent('')}
          >
            {/* Mount buttons */}
            {RightMenuHelper.mountButtonList(
              ItemsLayoutHelper.getButtonItems(urlParams.id)
            )}

            <Button
              id='deleteItem'
              className='button button-plain-text d-block'
              label='menuItemDelete'
              onClick={() => setIsDeleted(true)}
            />
          </RightMenu>
        </div>
      </div>

      {isDeleted && (
        <ConfirmationModal
          showModal={isDeleted}
          modalMessageId={'deleteItemsMessage'}
          confirmButtonId={'confirmAction'}
          onConfirmationFunction={handleDelete}
          changeModalVisibility={() => setIsDeleted(false)}
        />
      )}

      <MobileMenu
        showMobileMenu={showMobileMenu}
        closeMobileMenu={() =>
          closeMobile(setOpenMobileMenu, setMobileSideContent)
        }
        sideContent={mobileSideContent}
      />
    </div>
  );
};

export default injectIntl(ViewItemLayout);
