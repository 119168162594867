import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import SummaryTable from '../../tables/SummaryTable';
import { CREATE_DOCUMENT_PATHS } from '../../../../constants/index';
import { buildPath } from '../util/pathHelper';
import { buildChildren } from '../util/buttonsHelper';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const RightMenu = (props) => {
  const summaryMobileDisplay = openMobileSideContent(
    'mobileSummary',
    props.mobileSideContent
  );

  const buttonsMobileDisplay = openMobileSideContent(
    'mobileButtons',
    props.mobileSideContent
  );

  if (summaryMobileDisplay === 'open' || buttonsMobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='container px-0'>
      {/* Mobile Menu: add class 'open' */}
      <div
        className={`buttons-block mobile-side-content ${buttonsMobileDisplay}`}
      >
        <div className='row mx-0 content-block button-primary-block'>
          <Anchor
            className='button button-primary d-block'
            href={buildPath(CREATE_DOCUMENT_PATHS['createNewInvoice'])}
          >
            <FormattedMessage id='createNewInvoice' />
          </Anchor>
        </div>
        <div className='row mx-0 content-block dropdown-button-block'>
          <ButtonDropdown
            label='createOtherDocuments'
            dropdownType='button-secondary'
          >
            {buildChildren(CREATE_DOCUMENT_PATHS, props.accountSettings)}
          </ButtonDropdown>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`row mx-0 content-block summary-table-block mobile-side-content p-0 ${summaryMobileDisplay}`}
      >
        <SummaryTable
          globalSummaryKey={props.globalSummaryKey}
          totals={props.totals}
          documentsNumber={props.documentsNumber}
          accountSettings={props.accountSettings}
          filters={props.filters}
          bulkLoading={props.bulkLoading}
          documentsTab={props.documentsTab}
        />
      </div>
    </div>
  );
};

export default injectIntl(RightMenu);
