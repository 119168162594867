import React from 'react';
import { injectIntl } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import { ReactComponent as LogoIX } from '../../../assets/imgs/ix-visma-white-logo.svg';
import { IX_WEBSITE } from '../../../constants/index';

/**
 * ES6 stateless component
 */
const FooterSimple = (props) => {
  return (
    <div className='footer-simple d-flex '>
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-4 col-xs-12 logo'>
          <Anchor href={IX_WEBSITE}>
            <LogoIX data-testid='logo-ix' width='300px' />
          </Anchor>
        </div>
        <div className='col-lg-4 col-xs-12 text'>
          <Anchor href={IX_WEBSITE} className='font-size-large color-ix-white'>
            {props.intl.messages['softwareCertified']}
          </Anchor>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(FooterSimple);
